import Checkbox from 'components/atoms/Checkbox';
import { Table } from 'components/atoms/Table';
import { Td } from 'components/atoms/Td';
import { Th } from 'components/atoms/Th';
import { Tr } from 'components/atoms/Tr';
import AddIcon from 'components/atoms/icons/AddIcon';
import TrashIcon from 'components/atoms/icons/TrashIcon';
import { BudgetTableTitle } from 'components/molecules/BudgetTableTitle';
import React, { useState, useEffect } from 'react';
import OptionOverlay from 'components/molecules/OptionOverlay';
import Switch from 'components/atoms/Switch';
import { CardContainer, Container, GroupIcons } from './styles';

export const SpendingTable = ({
  header,
  body,
  nameTable,
  totalCategoryValue,
  totalCategoryPercentage,
  showEditIconOnTitle,
  typeCard,
  className,
  setShowEditItemModal,
  setShowEditCategoryModal,
  showDeleteModal,
  setShowDeleteModal,
  setShowAddItemModal,
  selectedCategoryKey,
  categoryId,
  categoryClassification,
  selectedItemKey,
  addItemKey,
  deleteItemKey,
}) => {
  const [selectedAll, setSelectedAll] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);

  const columnOrder = [
    'name',
    'classification',
    'maturity',
    'value',
    'spentOnCard',
    'monthlyValue',
  ];

  useEffect(() => {
    if (!selectedAll) {
      setSelectedItems([]);
    } else {
      const auxArray = [];
      body.forEach(item => {
        auxArray.push(item.spendingId);
      });
      setSelectedItems(auxArray);
    }
  }, [selectedAll, body]);

  useEffect(() => {
    if (body.length === 0) {
      setSelectedAll(false);
    }
  }, [body.length, showDeleteModal]);

  const updateWhetherAmountWasSpentOnTheCard = spendingId => {};

  return (
    <CardContainer>
      <BudgetTableTitle
        setShowModal={setShowEditCategoryModal}
        nameTable={nameTable}
        total={totalCategoryValue}
        percentage={totalCategoryPercentage}
        showEditIcon={showEditIconOnTitle}
        className={typeCard}
        getCategoryInformation={selectedCategoryKey}
        categoryId={categoryId}
        categoryClassification={categoryClassification}
      />
      <Container>
        <Table className="receipt-table">
          <thead>
            <Tr className="receipt-table-title spendings">
              <Th className="budget-table-title checkbox first-table-border">
                <Checkbox
                  checked={selectedAll}
                  onClick={() => setSelectedAll(prev => !prev)}
                />
              </Th>
              {header.map((item, index) => (
                <Th
                  className="budget-table-title"
                  first={index === 0}
                  key={index}
                >
                  {item}
                </Th>
              ))}
              <Th className="budget-table-title spending last-table-border" />
            </Tr>
          </thead>
          <tbody>
            {body.map((column, rowIndex) => (
              <Tr className="receipt-table-body" key={rowIndex}>
                <Td className="receipt-table-body checkbox">
                  <Checkbox
                    checked={selectedItems.includes(column.spendingId)}
                    onClick={() => {
                      const currentId = column.spendingId;
                      let newArray = [];

                      if (selectedItems.includes(currentId)) {
                        newArray = selectedItems.filter(
                          item => item !== currentId
                        );
                      } else {
                        newArray = [...selectedItems, currentId];
                      }

                      const sameItensQuantity = newArray.length === body.length;
                      const nonZeroItemsQuantity = newArray.length > 0;

                      setSelectedAll(sameItensQuantity && nonZeroItemsQuantity);
                      setSelectedItems(newArray);
                    }}
                  />
                </Td>
                {columnOrder.map((item, index) => {
                  const currentItem = column[item];

                  if (item === 'spendingId') return null;

                  if (item === 'spentOnCard') {
                    return (
                      <Td className="receipt-table-body" key={index}>
                        <Switch
                          checked={currentItem}
                          onClick={() =>
                            updateWhetherAmountWasSpentOnTheCard(
                              column.spendingId
                            )
                          }
                          getValue={() => {}}
                        />
                      </Td>
                    );
                  }

                  return (
                    <Td
                      className="receipt-table-body"
                      first={item === 'name'}
                      key={index}
                    >
                      {currentItem}
                    </Td>
                  );
                })}
                <Td className="receipt-table-body options">
                  <OptionOverlay
                    onClick={() => {
                      const currentItem = {
                        spendingId: column.spendingId,
                        name: column.name,
                        classification: column.classification,
                        maturity: column.maturity,
                        value: column.value,
                        spentOnCard: column.spentOnCard,
                        monthlyValue: column.monthlyValue,
                        categoryId,
                      };
                      localStorage.setItem(
                        selectedItemKey,
                        JSON.stringify(currentItem)
                      );
                      setShowEditItemModal(prev => !prev);
                    }}
                    className={className}
                  />
                </Td>
              </Tr>
            ))}
          </tbody>
        </Table>
      </Container>
      <GroupIcons>
        <AddIcon
          className="receipt-table"
          onClick={() => {
            localStorage.setItem(addItemKey, JSON.stringify({ categoryId }));
            setShowAddItemModal(prev => !prev);
          }}
        />
        <TrashIcon
          className="receipt-table"
          onClick={() => {
            localStorage.setItem(deleteItemKey, JSON.stringify(selectedItems));
            setShowDeleteModal(prev => !prev);
          }}
        />
      </GroupIcons>
    </CardContainer>
  );
};
