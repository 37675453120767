import React from 'react';
import { toCurrencyNoCents as formatReal } from 'deprecated/utils/toCurrency';

import {
  Container,
  ContainerColumn,
  SubTitle,
  PurpleBar,
  ContentWrapper,
  LogoAzul,
  Footer,
  ImageColuna1,
  Title
} from './styles';

export default function MoneyFlow({ data }) {

  const { headerTitle, footerTitle, fluxoDinheiro } = data;

  const renderFluxo = () => {
    const fluxos = fluxoDinheiro.map((fluxo, index) => {
      return (
        <div key={fluxo.nome} style={{ marginBottom: index !== fluxoDinheiro.length - 1 ? '76px' : '0' }}>
          <SubTitle style={{ marginTop: '50px' }} className="uppercase">{formatReal(fluxo.valor)}</SubTitle>
          <p className="p-smaller-no-mg">{fluxo.nome}</p>
        </div>
      )
    })

    return fluxos;
  }

  return (
    <ContentWrapper id="printable" minHeight={1682}>
      <Container style={{ height: '100%' }} padding="0 78px 0px 78px">
        <div className="d-40w">
          <PurpleBar />
          <PurpleBar className="full-height">
            <ImageColuna1 />
          </PurpleBar>
        </div>
        <div className="d-60w">
          <ContainerColumn padding="120px 0px 0px 0px">
            <Title dangerouslySetInnerHTML={{ __html: headerTitle }}></Title>
            <p style={{ marginTop: '58px' }} className="p-smaller-no-mg">O caminho que seu dinheiro deve percorrer<br />mensalmente</p>
          </ContainerColumn>
          <ContainerColumn padding="44px 0px 0px 0px">
            {fluxoDinheiro.length > 0 && renderFluxo()}
            <p className="p-smaller-no-mg">Não esqueça de pagá-la.</p>
            <SubTitle style={{ marginTop: '104px' }}>Gastar</SubTitle>
            <p className="p-smaller-no-mg">100% do restante</p>
          </ContainerColumn>
        </div>
      </Container>
      <Footer>
        <Container padding="0 78px">
          <p>{footerTitle}</p>
          <LogoAzul />
        </Container>
      </Footer>
    </ContentWrapper>
  );
}
