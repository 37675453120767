import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;

  &.invisible {
    display: none;
  }

  &.header {
    border-bottom: 1.5px solid ${({ theme }) => theme.grayMedium};
    padding-left: ${props => props.theme.getResponsiveWidth(12)};
    gap: 2%;
    width: ${props => props.theme.getResponsiveWidth(408)};
    height: ${props => props.theme.getResponsiveHeight(32)};
  }
`;
