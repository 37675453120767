import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Sidebar from 'components/organisms/Sidebar';
import styled from 'styled-components';
import { Header } from 'components/organisms/Header';

const MainContainer = styled.div`
  background: ${({ theme }) => theme.background};
  min-height: 100vh;
  width: 100vw;
  max-width: 100vw;
  overflow-x: hidden;
`;

export default function DashboardLayout({ children }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const setIsHome = useState(false)[1];

  useEffect(() => {
    setIsHome(window.location.pathname === '/home');
  }, [setIsHome]);

  return (
    <MainContainer>
      <Sidebar isOpen={isMenuOpen} handleOpen={setIsMenuOpen} />
      <Header />
      {children}
    </MainContainer>
  );
}

DashboardLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
};
