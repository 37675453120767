import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  height: 100vh;
  background: linear-gradient(
    180deg,
    ${({ theme }) => theme.gradientDarkBlueMenu[0]} 0%,
    ${({ theme }) => theme.gradientDarkBlueMenu[1]} 51.51%,
    ${({ theme }) => theme.gradientDarkBlueMenu[2]} 100%
  );
  border-radius: 0px 40px 40px 0px;
  display: flex;
  padding-top: calc((57 / 1080) * 100vh);
  padding-bottom: calc((57 / 1080) * 100vh);
  flex-direction: column;
  justify-content: space-between;
  transition: all 0.5s ease;
  z-index: 1;
  overflow: hidden;
  width: ${props =>
    props.expanded
      ? 'calc((330 / 1920) * 100vw)'
      : 'calc((71 / 1920) * 100vw)'};

  ::-webkit-scrollbar {
    width: 0;
  }
`;

export const Row = styled.div`
  width: calc((310 / 1920) * 100vw);
  display: flex;
  align-items: center;
  gap: 10%;

  &.align-center {
    justify-content: center;
    width: 100%;
  }
`;

export const SidebarIconContainer = styled.div`
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: calc((8 / 1920) * 100vw);
`;

export const SidebarTextContainer = styled.div`
  width: 100px;
  height: calc((42 / 1080) * 100vh);
  transition: ease-in-out 0.5s ease;
  flex: 1;
  cursor: pointer;
  border-radius: calc((8 / 1920) * 100vw);
  margin-top: calc((20 / 1080) * 100vh);
  color: ${({ theme }) => theme.white};
  font-weight: 700;
  white-space: nowrap;
  padding: 0;
  overflow: hidden;
  display: flex;
  align-items: center;

  @media screen and (max-height: 770px) {
    font-size: 0.9rem;
  }

  @media screen and (max-width: 1000px) {
    font-size: 1rem;
  }
`;
