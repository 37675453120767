import styled from 'styled-components';
import { GradientSkeletonAnimation } from 'styles/animations/GradientSkeletonAnimation';

export const GenericLoading = styled.div`
  display: block;
  background: linear-gradient(
    90deg,
    ${({ theme }) => theme.gradientSkeleton[0]} 25%,
    ${({ theme }) => theme.gradientSkeleton[1]} 50%,
    ${({ theme }) => theme.gradientSkeleton[0]} 75%
  );
  background-size: 200% 100%;
  animation: ${GradientSkeletonAnimation} 1.5s ease-in-out infinite;

  &.goal-panel-navigation-card {
    width: ${({ theme }) => theme.getResponsiveWidth(376)};
    height: ${({ theme }) => theme.getResponsiveWidth(256)};
    min-width: 200.53px;
    min-height: 136.53px;
    border-radius: ${({ theme }) => theme.getResponsiveWidth(16)};

    @media (max-width: 1023px) {
      border-radius: 8.54px;
    }
  }

  &.goals-panel-title {
    height: ${({ theme }) => theme.getResponsiveWidth(39)};
    width: ${({ theme }) => theme.getResponsiveWidth(1150)};
    border-radius: ${({ theme }) => theme.getResponsiveWidth(50)};

    @media (max-width: 1023px) {
      height: 20.8px;
      border-radius: 26.67px;
    }
  }

  &.goals-panel-searchbar {
    width: ${({ theme }) => theme.getResponsiveWidth(408)};
    height: ${({ theme }) => theme.getResponsiveWidth(39)};
    border-radius: ${({ theme }) => theme.getResponsiveWidth(8)};

    @media (max-width: 1023px) {
      height: 20.8px;
      /* border-radius: 26.67px; */
    }
  }

  &.goals-panel-dream {
    width: ${({ theme }) => theme.getResponsiveWidth(376)};
    height: ${({ theme }) => theme.getResponsiveWidth(396)};
    margin-top: ${({ theme }) => theme.getResponsiveWidth(32)};
    border-radius: ${({ theme }) => theme.getResponsiveWidth(16)};
    transition: all 0.7s ease-in-out;

    @media (max-width: 1023px) {
      width: 200.53px;
      height: 211.2px;
      margin-top: 17.07px;
      border-radius: 8.53px;
    }

    &:hover {
      transition: transform 0.3s ease;
      transform: scale(1.05) translateX(10px) translateY(10px);
      transform-origin: left end;
    }
  }

  &.empty-suitability-questionnaire {
    width: ${({ theme }) => theme.getResponsiveWidth(1600)};
    height: ${({ theme }) => theme.getResponsiveWidth(532)};
    border-radius: ${({ theme }) => theme.getResponsiveWidth(16)};
    margin-top: ${({ theme }) => theme.getResponsiveWidth(34)};
    margin-bottom: ${({ theme }) => theme.getResponsiveWidth(64)};
  }

  &.investment-panel-title {
    width: ${({ theme }) => theme.getResponsiveWidth(279)};
    height: ${({ theme }) => theme.getResponsiveWidth(25)};
    margin-left: ${({ theme }) => theme.getResponsiveWidth(22)};
    border-radius: ${({ theme }) => theme.getResponsiveWidth(16)};
    min-width: 148.8px;
    min-height: 13.3px;

    @media (max-width: 1023px) {
      margin-left: 11.7px;
      border-radius: 8.5px;
    }
  }

  &.investment-panel-go-icon {
    width: ${({ theme }) => theme.getResponsiveWidth(25)};
    height: ${({ theme }) => theme.getResponsiveWidth(25)};
    margin-left: ${({ theme }) => theme.getResponsiveWidth(20)};
    border-radius: ${({ theme }) => theme.getResponsiveWidth(8)};

    @media (max-width: 1023px) {
      width: 13.3px;
      height: 13.3px;
      margin-left: 10.7px;
      border-radius: 4.3px;
    }
  }

  &.investment-panel-table {
    width: 100%;
    height: ${({ theme }) => theme.getResponsiveWidth(444)};
    border-radius: ${({ theme }) => theme.getResponsiveWidth(8)};
    min-width: 826.7px;
    min-height: 237px;

    @media (max-width: 1023px) {
      border-radius: 4.3px;
    }
  }

  &.allocation-card {
    width: ${({ theme }) => theme.getResponsiveWidth(784)};
    height: ${({ theme }) => theme.getResponsiveWidth(512)};
    border-radius: ${({ theme }) => theme.getResponsiveWidth(16)};
    margin-bottom: ${({ theme }) => theme.getResponsiveWidth(35)};
    min-width: 418.1px;
    min-height: 273.1px;

    @media (max-width: 1023px) {
      border-radius: 8.5px;
      margin-bottom: 18.7px;
    }
  }

  &.immediate-liquidity {
    width: 100%;
    height: ${({ theme }) => theme.getResponsiveWidth(823)};
    border-radius: ${({ theme }) => theme.getResponsiveWidth(16)};
    margin-top: ${({ theme }) => theme.getResponsiveWidth(34)};
    margin-bottom: ${({ theme }) => theme.getResponsiveWidth(64)};
    margin-bottom: ${({ theme }) => theme.getResponsiveWidth(43)};
    min-width: 853px;

    @media (max-width: 1023px) {
      width: 853px;
      height: 438.9px;
      border-radius: 8.5px;
      margin-top: 18.1px;
      margin-bottom: 22.9px;
    }
  }
  &.detail-pot {
    width: ${({ theme }) => theme.getResponsiveWidth(1600)};
    height: ${({ theme }) => theme.getResponsiveHeight(256)};
    margin-top: ${({ theme }) => theme.getResponsiveHeight(100)};
  }
`;
