import styled from "styled-components";

export const DownArrowContainer = styled.div`
    &.default {
        width: ${props => props.theme.getResponsiveWidth(12)};
        height: ${props => props.theme.getResponsiveWidth(11)};
        border-radius: ${props => props.theme.getResponsiveWidth(3)};
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        background: ${props => props.theme.blueRoyal};

        @media (max-width: 1023px){
            width: 6.4px;
            height: 5.87px;
            border-radius: 1.6px;
        }
    }
`;

export const StyledDownArrow = styled.svg`
    &.default {
        width: ${props => props.theme.getResponsiveWidth(6)};
        height: ${props => props.theme.getResponsiveWidth(4)};

        path {
            stroke: ${props => props.theme.white};
        }

        @media (max-width: 1024px){
            width: 3.2px;
            height: 2.13px;
        }
    }
`;