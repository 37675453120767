import styled from "styled-components";

export const SVG = styled.svg`
         &.icon-color-gray-4 {
           path {
             fill: ${({ theme }) => theme.graySilver};
           }
         }

         &.invisible {
           display: none;
         }

         &.header {
           width: ${props => props.theme.getResponsiveWidth(23)};
           height: ${props => props.theme.getResponsiveHeight(24)};
         }
       `;