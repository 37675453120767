import React, { useState } from 'react';
import { SpendingTable } from 'components/templates/Table/SpendingTable';
import { ButtonContainer } from './styles';
import { ButtonCategory } from 'components/molecules/ButtonCategory';
import { EditSpendingItemModal } from '../../molecules/BudgetModal/EditSpendingItemModal';
import { GenericEditCategoryTableModal } from 'components/molecules/BudgetModal/GenericEditCategoryTableModal';
import { SimpleDeleteModal } from 'components/molecules/BudgetModal/SimpleDeleteModal';
import { SimpleAddModal } from 'components/molecules/BudgetModal/SimpleAddModal';

export const MapSpendingPage = ({ spendings }) => {
  const [showEditItemModal, setShowEditItemModal] = useState(false);
  const [showEditCategoryModal, setShowEditCategoryModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showSimpleAddModal, setShowSimpleAddModal] = useState(false);
  const [showAddItemModal, setShowAddItemModal] = useState(false);
  const [tableData, setTableData] = useState(spendings.body);

  const selectedCategoryKey = 'selectedSpendingCategory';
  const selectedItemKey = 'selectedSpendingItem';
  const addItemKey = 'addedSpendingItem';
  const deleteItemKey = 'deletedSpendingItem';
  const addNewCategoryKey = 'addedSpendingCategory';

  const editCategory = () => {
    const editedCategory = JSON.parse(localStorage.getItem(selectedCategoryKey));

    const newArray = tableData.map(category => {
      if (category.categoryId === editedCategory.categoryId) {
        return {
          ...category,
          name: editedCategory.name,
          classification: editedCategory.classification
        };
      }
      return category;
    });

    setTableData(newArray);
    localStorage.removeItem(selectedCategoryKey);
  }

  const deleteCategory = () => {
    const editedCategory = JSON.parse(localStorage.getItem(selectedCategoryKey));
    const objWithoutSelectedCategory = tableData.filter(category => category.categoryId !== editedCategory.categoryId);
    setTableData(objWithoutSelectedCategory);
  }

  const addNewItem = () => {
    let createdItem = JSON.parse(localStorage.getItem(addItemKey));

    let newItem = {
      spendingId: new Date().getTime(),
      name: createdItem.name,
      classification: createdItem.classification,
      maturity: createdItem.maturity,
      value: createdItem.value,
      spentOnCard: createdItem.spentOnCard,
      monthlyValue: createdItem.monthlyValue,
    };

    const currentItensWithSameCateogry = tableData.filter(item => item.categoryId === createdItem.categoryId);
    const currentList = currentItensWithSameCateogry[0].list.filter(item => item.spendingId);
    const newList = [...currentList, newItem];

    const newTableData = tableData.map(item => {
      if (item.categoryId === createdItem.categoryId) {
        return { ...item, list: newList };
      }
      return item;
    });

    setTableData(newTableData);
    localStorage.removeItem(addItemKey);
  }

  const editItem = () => {
    const editedItem = JSON.parse(localStorage.getItem(selectedItemKey));

    const reworkedItem = {
      spendingId: editedItem.spendingId,
      name: editedItem.name,
      classification: editedItem.classification,
      maturity: editedItem.maturity,
      value: editedItem.value,
      spentOnCard: editedItem.spentOnCard,
      monthlyValue: editedItem.monthlyValue,
    };

    const currentBody = tableData;
    const filteredArray = currentBody.map(item => ({
      ...item,
      list: item.list.map(subItem => {
        if (subItem.spendingId === editedItem.spendingId) {
          return reworkedItem;
        }

        return subItem;
      })
    }));

    setTableData(filteredArray);
    localStorage.removeItem(selectedItemKey);
  }

  const deleteOneItem = () => {
    const editedItem = JSON.parse(localStorage.getItem(selectedItemKey));

    const currentBody = tableData;
    const filteredArray = currentBody.map(item => ({
      ...item,
      list: item.list.filter(subItem => subItem.spendingId !== editedItem.spendingId)
    }));

    setTableData(filteredArray);
    localStorage.removeItem(selectedItemKey);
  }

  const handleDelete = () => {
    const deletedItems = JSON.parse(localStorage.getItem(deleteItemKey));
    const currentBody = tableData;

    const filteredArray = currentBody.map(item => ({
      ...item,
      list: item.list.filter(subItem => !deletedItems.includes(subItem.spendingId))
    }));

    setTableData(filteredArray);
    setShowDeleteModal(false);
    localStorage.removeItem(deleteItemKey);
  }

  const addNewCategory = () => {
    const createdCategory = JSON.parse(localStorage.getItem(addNewCategoryKey));
    let category = {
      categoryId: new Date().getTime(),
      name: createdCategory.name,
      total: 'R$ 0',
      percentage: '0%',
      classification: createdCategory.classification,
      list: []
    };

    setTableData([...tableData, category]);
  }

  return (
    <>
      {showEditItemModal && (
        <EditSpendingItemModal
          showModal={showEditItemModal}
          setShowModal={setShowEditItemModal}
          selectedItem={JSON.parse(localStorage.getItem(selectedItemKey))}
          title='Editar item'
          onCancel={editItem}
          onConfirm={deleteOneItem}
          selectedItemKey={selectedItemKey}
          icon='edit'
          theme='spendings'
          classificationOptions={spendings.itemClassification}
        />
      )}
      {showAddItemModal && (
        <EditSpendingItemModal
          showModal={showAddItemModal}
          setShowModal={setShowAddItemModal}
          title='Adicionar item'
          icon='add'
          addItemKey={addItemKey}
          onCancel={addNewItem}
          theme='spendings'
          classificationOptions={spendings.itemClassification}
        />
      )}
      {showEditCategoryModal && (
        <GenericEditCategoryTableModal
          showModal={showEditCategoryModal}
          setShowModal={setShowEditCategoryModal}
          selectedItem={JSON.parse(localStorage.getItem(selectedCategoryKey))}
          selectedCategoryKey={selectedCategoryKey}
          onCancel={editCategory}
          onConfirm={deleteCategory}
          theme='spendings'
          classificationOptions={spendings.categoryClassification}
        />
      )}
      {showDeleteModal && (
        <SimpleDeleteModal
          showModal={showDeleteModal}
          setShowModal={setShowDeleteModal}
          text='Excluir item?'
          highlightedText='Esta ação é permanente e não poderá ser desfeita'
          deleteItemKey={deleteItemKey}
          onConfirm={handleDelete}
          confirmButtonText='Excluir item'
          theme='spendings'
        />
      )}
      {showSimpleAddModal && (
        <SimpleAddModal
          showModal={showSimpleAddModal}
          setShowModal={setShowSimpleAddModal}
          classificationOptions={spendings.categoryClassification}
          cancelButtonText='Concluído'
          confirmButtonText='Adicionar categoria'
          dropdownLabel='Tipo'
          addNewCategoryKey={addNewCategoryKey}
          onConfirm={addNewCategory}
          theme='spendings'
        />
      )}
      {tableData.map((spending, index) => (
        <SpendingTable
          header={spendings.header}
          body={spending.list}
          nameTable={spending.name}
          categoryId={spending.categoryId}
          categoryClassification={spending.classification}
          totalCategoryValue={spending.total}
          totalCategoryPercentage={spending.percentage}
          key={index}
          className="color-spending"
          showEditItemModal={showEditItemModal}
          setShowEditItemModal={setShowEditItemModal}
          setShowEditCategoryModal={setShowEditCategoryModal}
          showDeleteModal={showDeleteModal}
          setShowDeleteModal={setShowDeleteModal}
          setShowAddItemModal={setShowAddItemModal}
          selectedCategoryKey={selectedCategoryKey}
          selectedItemKey={selectedItemKey}
          addItemKey={addItemKey}
          deleteItemKey={deleteItemKey}
        />
      ))}
      <ButtonContainer>
        <ButtonCategory
          setShowModal={setShowSimpleAddModal}
        />
      </ButtonContainer>
    </>
  );
};
