import React from "react";
import { Page } from "components/templates/Page";
import { GoalsPanelContainer } from "./styles";
import { GoalPanelHeader } from "components/molecules/GoalPanelHeader";
import { Goal } from "components/molecules/Goal";
import { getGoals } from "development/goals";
import { GeneralContext } from "utils/contexts/GeneralContext";
import { GenericLoading } from "components/atoms/GenericLoading";

export default function GoalPanel() {

    const { loading } = React.useContext(GeneralContext);
    const [goals, setGoals] = React.useState(getGoals() || []);

    const updateDreamStatus = (id, newStatus) => {
        setGoals(goals.map(goal => goal.id === id ? { ...goal, realized: newStatus } : goal));
    }

    const deleteDream = (id) => {
        setGoals(goals.filter(goal => goal.id !== id));
    }

    const addNewDream = (goal) => {
        let newGoal = {
            ...goal,
            id: Math.floor(Math.random() * 1000),
            type: "generic-dream",
            currentTotalValue: "R$ 15.000",
            monthlyInvestment: "R$ 500",
            mimValue: "R$ 0",
            initialValueInvested: "R$ 0",
            percentageCompleted: "10%",
            totalInvestedValue: "R$ 3.000",
            realized: false,
        }
        setGoals([...goals, newGoal]);
    }

    const updateGoal = (modifiedGoal) => {
        setGoals(prev => prev.map(goal => goal.id === modifiedGoal.id ? { ...modifiedGoal } : goal));
    }

    const page = {
        loading: (
            <Page.RootContainer>
                <GoalPanelHeader />
                <GoalsPanelContainer>
                    {Array(4).fill(0).map((_, index) => (
                        <GenericLoading className="goals-panel-dream" key={index} />
                    ))}
                </GoalsPanelContainer>
            </Page.RootContainer>
        ),
        content: (
            <Page.RootContainer>
                <GoalPanelHeader />
                <GoalsPanelContainer>
                    {goals.map((goal, index) => (
                        <Goal
                            key={index}
                            type={goal.type}
                            updateDreamStatus={updateDreamStatus}
                            backgroundImage={goal.backgroundImage}
                            dreamProps={goal}
                            onConfirm={updateGoal}
                            onDelete={deleteDream}
                        />
                    ))}
                    <Goal type="empty-goal" onConfirm={addNewDream} />
                </GoalsPanelContainer>
            </Page.RootContainer>
        ),
    }

    return page[loading ? "loading" : "content"];
}