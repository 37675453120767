import React, { useContext } from 'react';
import { GoIconContainer, LoadingIcon } from './styles';
import { GeneralContext } from 'utils/contexts/GeneralContext';

const GoIcon = ({ className, onClick }) => {

  const { loading, width, height } = useContext(GeneralContext);

  return loading ? (
    <LoadingIcon className={className} width={width} height={height} />
  ) : (
    <GoIconContainer
      className={className}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      onClick={onClick}
    >
      <path d="M12.6063 9.34664V14.7735H1.22767V3.39396H6.72326C7.06162 3.39396 7.33709 3.11972 7.33709 2.78007C7.33709 2.44168 7.06288 2.16619 6.72326 2.16619H0.979872C0.438993 2.16619 0 2.60522 0 3.14614V15.02C0 15.561 0.438993 16 0.979872 16H12.8528C13.3937 16 13.8327 15.561 13.8327 15.02V9.34538C13.8327 9.00699 13.5585 8.73149 13.2188 8.73149C12.8805 8.73149 12.605 9.00573 12.605 9.34538L12.6063 9.34664Z" />
      <path d="M15.9887 0.480542C15.9849 0.464188 15.9786 0.447835 15.9736 0.431481C15.9673 0.410096 15.9623 0.38871 15.9535 0.368583C15.9459 0.349714 15.9346 0.332102 15.9245 0.314491C15.9157 0.298137 15.9082 0.281784 15.8981 0.26543C15.878 0.235239 15.8553 0.207564 15.8302 0.182405C15.8277 0.179889 15.8264 0.177373 15.8239 0.174857C15.8214 0.172341 15.8189 0.171083 15.8164 0.168567C15.7899 0.143408 15.7623 0.120764 15.7333 0.100637C15.7182 0.0905733 15.7019 0.0842835 15.6868 0.0754777C15.6679 0.065414 15.6503 0.0540924 15.6302 0.0452866C15.6113 0.0377389 15.5912 0.032707 15.5711 0.0264172C15.5535 0.0213854 15.5371 0.0150955 15.5182 0.0113217C15.4818 0.00377389 15.4453 0.00125796 15.4088 0C15.4063 0 15.4038 0 15.4013 0H8.92706C8.59624 0 8.32832 0.267946 8.32832 0.59879C8.32832 0.929634 8.59624 1.19758 8.92706 1.19758H13.9572L6.75599 8.39941C6.52203 8.63339 6.52203 9.01204 6.75599 9.24602C6.87298 9.36301 7.02643 9.42088 7.17864 9.42088C7.33084 9.42088 7.48555 9.36301 7.60128 9.24602L14.8025 2.04419V7.07478C14.8025 7.40562 15.0704 7.67357 15.4013 7.67357C15.7321 7.67357 16 7.40562 16 7.07478V0.59879C16 0.559793 15.9962 0.519538 15.9887 0.4818V0.480542Z" />
    </GoIconContainer>
  );
};

export default GoIcon;
