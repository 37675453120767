import React, { useMemo } from 'react';

import PropTypes from 'prop-types';

import { AiOutlineDelete } from 'react-icons/ai';

import { MdEdit } from 'react-icons/md';

import { TdStyled } from './styles';

import TotalTable from 'components/Tables/TotalTable';

import toCurrency from 'deprecated/utils/toCurrency';

export default function Table({
  body,
  setShowModalDelete,
  setSelectedAsset,
  setShowModalUpdate,
}) {
  const tableBody = useMemo(() => {
    return body.map(dt => [
      dt.symbol,
      <TdStyled color={dt.type === 'buy' ? 'receipts' : 'investments'}>
        {dt.type === 'buy' ? 'Compra' : 'Venda'}
      </TdStyled>,
      '-',
      '-',
      dt.quantity,
      toCurrency(dt.previous_close),
      toCurrency(dt.previous_close),
      toCurrency(dt.previous_close),
      <div className="icons">
        <button
          onClick={() => {
            setSelectedAsset(dt);
            setShowModalDelete(true);
          }}
        >
          <AiOutlineDelete />
        </button>
        <button
          onClick={() => {
            setSelectedAsset(dt);
            setShowModalUpdate(true);
          }}
        >
          <MdEdit />
        </button>
      </div>,
    ]);
  }, [body, setSelectedAsset, setShowModalDelete, setShowModalUpdate]);

  const tableHead = [
    'Ativo',
    'Compra/Venda',
    'Distribuidor',
    'Vencimento',
    'Quantidade',
    'Preço Atual',
    'Preço Atualizado',
    'Valor Total',
    '',
  ];

  return <TotalTable head={tableHead} body={tableBody} dontTitle={false} />;
}

Table.propTypes = {
  setSelectedAsset: PropTypes.func.isRequired,
  setShowModalUpdate: PropTypes.func.isRequired,
  setShowModalDelete: PropTypes.func.isRequired,
  body: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};
