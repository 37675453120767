import styled from 'styled-components';
import { GradientSkeletonAnimation } from 'styles/animations/GradientSkeletonAnimation';

export const Wrapper = styled.div`
  margin: 0 auto;
  width: max-content;

  @media (min-width: 2161px) {
    margin-top: 36px;
  }

  @media (min-width: 1921px) and (max-width: 2160px) {
    margin-top: 32px;
  }

  @media (max-width: 1920px) {
    margin-top: 24px;
  }

  &.budget-table {
    margin-bottom: 30px;
  }
`;

export const Container = styled.div`
  margin: calc((24 / 1080) * 100vh) auto 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc((1600 / 1920) * 100vw);
`;

export const Group = styled.div`
  display: flex;
  align-items: center;
  height: 4.5vh;
  width: 100%;

  &.map-loading {
    margin-right: 10%;
  }

  &.loading {
    width: 408px;
    height: 39px;
    color: transparent;
    background: linear-gradient(
      90deg,
      ${({ theme }) => theme.gradientSkeleton[0]} 25%,
      ${({ theme }) => theme.gradientSkeleton[1]} 50%,
      ${({ theme }) => theme.gradientSkeleton[0]} 75%
    );
    background-size: 200% 100%;
    animation: ${GradientSkeletonAnimation} 1.5s ease-in-out infinite;
    border-radius: 4px;

    @media (min-width: 1651px) and (max-width: 1920px) {
      width: 408px;
      height: 39px;
    }

    @media (min-width: 1501px) and (max-width: 1650px) {
      width: 402px;
      height: 36px;
    }

    @media (min-width: 1281px) and (max-width: 1500px) {
      width: 394px;
      height: 33px;
    }

    @media (max-width: 1280px) {
      width: 380px;
      height: 30px;
    }
  }

  &.client-side {
    justify-content: ${props => props.justifyContent};
    gap: 18px;
  }

  &.budget-table-client-side {
    justify-content: space-between;
    width: 80%;
  }

  &.budget-table-list {
    justify-content: space-between;
  }

  &.input-side {
    gap: 2vw;
    justify-content: flex-end;

    @media (max-width: 1650px) {
      gap: 1.8vw;
    }

    @media (max-width: 1500px) {
      gap: 1.5vw;
    }

    @media (max-width: 1280px) {
      gap: 1.5vw;
    }
  }
`;

export const Dropdown = styled.div`
  position: absolute;
  left: 0;
  background-color: ${({ theme }) => theme.white};
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 10;
`;

export const Line = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;
