import styled from 'styled-components';

export const Input = styled.input`
  &.header {
    width: 100%;
    height: min-content;
    border: none;
    border-radius: ${props => props.theme.getResponsiveWidth(5)};
    color: ${({ theme }) => theme.inputText};
    outline: none;
    background: transparent;
    vertical-align: middle;
    font-family: ${props => props.theme.fonts.AperturaRegular};

    ::placeholder {
      color: ${({ theme }) => theme.inputText};
      font-style: italic;
    }

    @media (min-width: 2260px) {
      ::placeholder {
        font-size: 1.2rem;
      }
    }

    @media (max-width: 1920px) {
      ::placeholder {
        font-size: 1rem;
        padding: 1% 0 0 0;
      }
    }

    @media (max-width: 1650px) {
      ::placeholder {
        font-size: 0.9rem;
      }
    }

    @media (max-width: 1280px) {
      ::placeholder {
        font-size: 0.8rem;
      }
    }

    @media (max-width: 1024px) {
      ::placeholder {
        font-size: 0.6rem;
      }
    }
  }

  &.budget-table-title {
    width: ${props => props.theme.getResponsiveHeight(20)};
    height: ${props => props.theme.getResponsiveHeight(20)};
    background-color: ${({ theme }) => theme.grayPale};
    border-radius: ${props => props.theme.getResponsiveWidth(5)};
    padding: 0;
    margin: 0;

    @media (max-width: 1280px) {
      width: 14px;
      height: 14px;
    }
  }

  &.simple-modal {
    width: 100%;
    height: min-content;
    border: none;
    border-bottom: 1px solid ${({ theme }) => theme.grayShadow};
    border-radius: ${props => props.theme.getResponsiveWidth(5)};
    font-family: ${props => props.theme.fonts.AperturaRegular};
    color: ${({ theme }) => theme.grayInputSelect};
    outline: none;
    background: transparent;
    vertical-align: middle;
    font-size: ${({ theme }) => theme.getResponsiveHeight(14)};
    margin-top: ${({ theme }) => theme.getResponsiveHeight(8)};
    padding-bottom: ${({ theme }) => theme.getResponsiveHeight(2)};
    padding-left: ${props => props.theme.getResponsiveHeight(8)};

    ::placeholder {
      color: ${({ theme }) => theme.grayShadow};
      font-style: italic;
      font-size: ${({ theme }) => theme.getResponsiveHeight(12)};
    }

    &.lowest-input {
      width: ${({ theme }) => theme.getResponsiveHeight(35)};
    }

    &.eventual-committed-maturity {
      width: ${({ theme }) => theme.getResponsiveHeight(102)};
    }

    &.eventual-committed-annual-value {
      width: ${({ theme }) => theme.getResponsiveHeight(153)};
    }
  }

  &.generic-dream-modal {
    width: 100%;
    height: min-content;
    border: none;
    border-bottom: ${({ theme }) => theme.getResponsiveWidth(1)} solid ${({ theme }) => theme.grayShadow};
    font-size:  ${props => props.theme.getResponsiveWidth(16)};
    font-family: ${props => props.theme.fonts.AperturaRegular};
    line-height:  ${props => props.theme.getResponsiveWidth(16)};
    color: ${({ theme }) => theme.grayInputSelect};
    outline: none;
    background: transparent;
    vertical-align: middle;
    margin-top: ${props => props.theme.getResponsiveWidth(16)};
    padding-bottom: ${({ theme }) => theme.getResponsiveWidth(2)};
    padding-left: ${props => props.theme.getResponsiveWidth(8)};

    ::placeholder {
      color: ${({ theme }) => theme.grayShadow};
      font-size:  ${props => props.theme.getResponsiveWidth(16)};
      line-height:  ${props => props.theme.getResponsiveWidth(16)};
    }

    &.second-section {
      margin-top: ${({ theme }) => theme.getResponsiveWidth(16)};

      @media (max-width: 1023px){
        margin-top: 8.53px;
      }
    }

    @media (max-width: 1023px){
      margin-top: 8.53px;
      padding-bottom: 1.37px;
      padding-left: 5.47px;
      font-size: 8.53px;
      line-height: 8.53px;
      border-bottom: 0.67px solid ${({ theme }) => theme.grayShadow};

      &::placeholder {
        font-size: 8.53px;
        line-height: 8.53px;
      }
    }
  }

  &.disabled {
    cursor: not-allowed;
  }

  &.generic-dream-modal-datepicker {
    border: none;
    outline: 0;
    width: 90%;
    height: ${props => props.theme.getResponsiveWidth(16)};
    font-size:  ${props => props.theme.getResponsiveWidth(16)};
    font-weight: 400;
    line-height:  ${props => props.theme.getResponsiveWidth(16)};
    color: ${({ theme }) => theme.grayInputSelect};
    padding-left: ${props => props.theme.getResponsiveWidth(8)};

    &::placeholder {
      color: ${({ theme }) => theme.grayInputSelect};
      font-size:  ${props => props.theme.getResponsiveWidth(16)};
      line-height:  ${props => props.theme.getResponsiveWidth(16)};
    }

    @media (max-width: 1023px){
      height: 8.53px;
      font-size: 8.53px;
      line-height: 8.53px;
      padding-left: 4.27px;

      &::placeholder {
        font-size: 8.53px;
        line-height: 8.53px;
      }
    }
  }
`;
