import React from 'react';
import { Legend } from 'components/molecules/Legend';
import { StyledContainer } from './styles';

export const LegendTable = ({
  getCategoryInformation,
  categoryId,
  categoryClassification,
  nameTable,
  setShowModal,
  valuePEC,
  valueCommitted,
}) => {
  const edit = () => {
    localStorage.setItem(
      getCategoryInformation,
      JSON.stringify({
        categoryId,
        name: nameTable,
        classification: categoryClassification,
      })
    );
    setShowModal(prev => !prev);
  };
  return (
    <StyledContainer>
      <Legend
        title="Eventual Comprometido"
        subtitle="Total"
        value={`R$ ${valueCommitted}`}
        className="legend-table"
      />
      <Legend
        title="Quanto você tem"
        subtitle="Total"
        value={`R$ ${valuePEC}`}
        type="edit"
        className="legend-table category-title spending-color"
        onClick={edit}
      />
      <Legend
        title="Provisão para Eventuais 
        Comprometidos (PEC)"
        subtitle="Total"
        value={`R$ ${valuePEC}`}
        className="legend-table"
      />
    </StyledContainer>
  );
};
