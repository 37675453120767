import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;

  &.first-column {
    margin-top: calc((43 / 1080) * 100vh);
  }

  &.second-column {
    margin-top: calc((25 / 1080) * 100vh);
  }

  &.third-column {
    margin-top: calc((24 / 1080) * 100vh);
  }

  &.second-template-height {
    height: 312px;

    @media (max-width: 1920px) {
      height: 312px;
    }

    @media (max-width: 1650px) {
      height: 290px;
    }

    @media (max-width: 1500px) {
      height: 280px;
    }

    @media (max-width: 1280px) {
      height: 270px;
    }
  }
`;
