import React from 'react';
import { Card, Title, ProgressBarContainer, GroupItens } from './styles';
import { P } from 'components/atoms/P';
import ProgressBar from 'components/atoms/ProgressBar';
import { Link } from 'components/atoms/Link';
import { H5 } from 'components/atoms/Headings/H5';
import { Span } from 'components/atoms/Span';
import ArrowIcon from 'components/atoms/icons/ArrowIcon';

export const DreamCard = ({
  title,
  timeToAchieve,
  value,
  investedValue,
  percentage,
  dreamImage,
  className,
  width,
  height,
}) => {
  return (
    <Card
      className={className}
      backgroundImage={dreamImage}
      width={width}
      height={height}
    >
      <Title className={className} width={width} height={height}>
        <H5 className={className} width={width} height={height}>
          {title}
        </H5>
        <Link className="no-decoration" href="#">
          <ArrowIcon className={className} width={width} height={height} />
        </Link>
      </Title>

      <P className={`${className} time-to-achieve-dream`}>{timeToAchieve}</P>

      <ProgressBarContainer className={className} height={height}>
        <Span className="dream-investment">{investedValue}</Span>
        <Span className="dream-investment">{value}</Span>
      </ProgressBarContainer>

      <ProgressBar progress={percentage.scale} className={className} />

      <GroupItens>
        <P className={`${className} fully-invested-dream`} height={height}>
          Total investido
        </P>
        <P className={`${className} fully-invested-dream`} height={height}>
          {percentage.percent}
        </P>
      </GroupItens>
    </Card>
  );
};
