import React, { useState } from 'react';
import { Label } from 'components/atoms/Label';
import { Input } from 'components/atoms/Input';
import { Span } from 'components/atoms/Span';

export const ModalInputTextAdd = ({
  className,
  getInputValue = () => {},
  placeholder,
  label,
  defaultValue = '',
  disabled = false,
}) => {
  const [inputValue, setInputValue] = useState(defaultValue);

  const handleChange = e => {
    const { value } = e.target;
    setInputValue(value);
    getInputValue(value);
  };

  return (
    <Label className={className}>
      <Span className={className}>{label}</Span>
      <Input
        className={`${className} ${disabled ? 'disabled' : ''}`}
        value={inputValue}
        onChange={handleChange}
        placeholder={placeholder}
        disabled={disabled}
      />
    </Label>
  );
};
