import styled from 'styled-components';
import { GradientSkeletonAnimation } from 'styles/animations/GradientSkeletonAnimation';

export const TolltipIconContainer = styled.svg`
  &.home {
    cursor: pointer;
    align-self: center;
    width: ${props => props.theme.getResponsiveWidth(14)};
    height: ${props => props.theme.getResponsiveHeight(14)};
    margin-top: ${props => props.theme.getResponsiveHeight(2)};
    margin-left: ${props => props.theme.getResponsiveWidth(14)};

    path:first-of-type {
      fill: ${({ theme }) => theme.bege};
    }

    path:nth-of-type(2) {
      fill: ${({ theme }) => theme.pale};
    }
  }

  &.investments {
    width: ${props => props.theme.getResponsiveWidth(14)};
    height: ${props => props.theme.getResponsiveHeight(14)};

    path:first-of-type {
      fill: ${({ theme }) => theme.bege};
    }

    path:nth-of-type(2) {
      fill: ${({ theme }) => theme.pale};
    }
  }
`;

export const LoadingIcon = styled.div`
  &.home {
    width: ${props => props.theme.getResponsiveWidth(14)};
    height: ${props => props.theme.getResponsiveHeight(14)};
    background: linear-gradient(
      90deg,
      ${({ theme }) => theme.gradientSkeleton[0]} 25%,
      ${({ theme }) => theme.gradientSkeleton[1]} 50%,
      ${({ theme }) => theme.gradientSkeleton[0]} 75%
    );
    background-size: 200% 100%;
    animation: ${GradientSkeletonAnimation} 1.5s ease-in-out infinite;
    border-radius: 50%;
    margin-left: ${props => props.theme.getResponsiveWidth(8)};
  }
`;
