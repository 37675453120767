import styled from 'styled-components';

export const Overlay = styled.div`
  &.default {
    z-index: 1000;
    position: relative;
  }
`;

export const CalendarContainer = styled.div`
  &.default {
    width: ${props => props.theme.getResponsiveWidth(192)};
    height: min-content;
    border-radius: ${props => props.theme.getResponsiveWidth(8)};
    padding: ${props => props.theme.getResponsiveWidth(10)};
    background-color: ${props => props.theme.white};
    box-shadow: 0px ${props => props.theme.getResponsiveWidth(4)}
      ${props => props.theme.getResponsiveWidth(15)} 0px
      ${props => props.theme.blackWithSlowOpacity};

    @media (max-width: 1023px) {
      width: 102.4px;
      border-radius: 4.27px;
      padding: 5.33px;
      box-shadow: 0px 2.13px 8px 0px
        ${props => props.theme.blackWithSlowOpacity};
    }
  }
`;

export const CalendarTitle = styled.div`
  &.default {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const Title = styled.div`
  &.default {
    height: ${props => props.theme.getResponsiveWidth(18)};
    padding-top: ${props => props.theme.getResponsiveWidth(4)};
    padding-right: ${props => props.theme.getResponsiveWidth(10)};
    padding-bottom: ${props => props.theme.getResponsiveWidth(4)};
    padding-left: ${props => props.theme.getResponsiveWidth(6)};
    font-weight: 400;
    font-family: ${props => props.theme.fonts.AperturaRegular};
    font-size: ${props => props.theme.getResponsiveWidth(10)};
    line-height: ${props => props.theme.getResponsiveWidth(10)};
    color: ${props => props.theme.grayShadow};

    @media (max-width: 1023px) {
      height: 9.6px;
      padding-top: 2.13px;
      padding-right: 5.33px;
      padding-bottom: 2.13px;
      padding-left: 3.2px;
      font-weight: 400;
      font-size: 5.33px;
      line-height: 5.33px;
    }
  }
`;

export const ArrowsContainer = styled.div`
  &.default {
    width: ${props => props.theme.getResponsiveWidth(30)};
    height: ${props => props.theme.getResponsiveWidth(11)};
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 1023px) {
      width: 16px;
      height: 5.87px;
    }
  }
`;

export const Day = styled.div`
    &.default {
        width: ${props => props.theme.getResponsiveWidth(15)};
        height: ${props => props.theme.getResponsiveWidth(15)};
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: ${props => props.theme.fonts.AperturaRegular};
        font-size: ${props => props.theme.getResponsiveWidth(8)};
        line-height: ${props => props.theme.getResponsiveWidth(8)};
        font-weight: 400;
        color: ${props => props.theme.blueNavy};
        border-radius: ${props => props.theme.getResponsiveWidth(4)};
        cursor: pointer;

        ${props =>
          props.selected === 'currentMonth' &&
          `
            background: ${props.theme.blueRoyal};
            color: ${props.theme.white} !important;
        `}

        ${props =>
          props.type !== 'currentMonth' &&
          `
            color: ${props.theme.graySilver};
        `}

        ${props =>
          props.type === 'title' &&
          `
            color: ${props.theme.white};
            cursor: default;
        `}

        @media (max-width: 1023px) {
            width: 8px;
            height: 8px;
            font-size: 4.27px;
            line-height: 4.27px;
            border-radius: 2.13px;
        }
    }
`;

export const DaysGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: ${props => props.theme.getResponsiveWidth(6)};
  margin-top: ${props => props.theme.getResponsiveWidth(12)};
  align-items: center;
  align-items: stretch;
  padding: 0px ${props => props.theme.getResponsiveWidth(2)};

  ${props =>
    props.type === 'title' &&
    `
    background-color: ${props.theme.blueRoyal};
    border-radius: ${props.theme.getResponsiveWidth(24)};
    margin-top: ${props.theme.getResponsiveWidth(5)};

    @media (max-width: 1023px) {
        border-radius: 12.8px;
        margin-top: 2.7px;
    }
  `}

  @media (max-width: 1023px) {
    gap: 3.2px;
    margin-top: 6.4px;
    padding: 0px 1.07px;
  }
`;

export const ButtonContainer = styled.div`
  &.default {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: min-content;
    margin-top: ${props => props.theme.getResponsiveWidth(12)};

    @media (max-width: 1023px) {
      margin-top: 6.4px;
    }
  }
`;
