import styled from 'styled-components';
import { GradientSkeletonAnimation } from 'styles/animations/GradientSkeletonAnimation';
import { HorizontalScrollbarStyles } from 'styles/components/HorizontalScrollbarStyles';

export const Container = styled.div`
  width: calc((1056 / 1920) * 100vw);
  height: calc((312 / 1080) * 100vh);
  padding: calc((20 / 1920) * 100vw);
  border-radius: calc((16 / 1920) * 100vw);
  background-color: ${({ theme }) => theme.white};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border: ${props => props.border};
  border-radius: ${props => props.borderRadius};
  box-shadow: 4px 4px 15px 0px ${({ theme }) => theme.boxShadowColor};
`;

export const CardContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  display: flex;
  gap: 1.2%;
  background: transparent;
  padding-right: 0.1%;

  & > * {
    flex: 0 0 auto;
  }

  &.default {
    ${HorizontalScrollbarStyles};
  }

  &.loading {
    overflow-x: hidden;
    background: linear-gradient(
      90deg,
      ${({ theme }) => theme.gradientSkeleton[0]} 25%,
      ${({ theme }) => theme.gradientSkeleton[1]} 50%,
      ${({ theme }) => theme.gradientSkeleton[0]} 75%
    );
    background-size: 200% 100%;
    animation: ${GradientSkeletonAnimation} 1.5s ease-in-out infinite;
    border-radius: calc((5 / 1080) * 100vh);
    margin-top: calc((17 / 1080) * 100vh);
  }
`;
