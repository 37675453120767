import React, { useState, useEffect } from 'react';

import { toast } from 'react-toastify';

import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';

import { Container, PerfilInvestidor, Table } from './styles';

import newApi from 'services/newApi';

import Modal from 'components/Modal';
import Loading from 'components/Loading';

export default function ModalProfileStrategy({ close }) {
  const [loading, setLoading] = useState(true);

  const { id: user_id } = useSelector(state => state.user.profile);

  const [ perfil, setPerfil ] = useState('');
  const [ estrategia, setEstrategia ] = useState([])

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await newApi.get(`estrategia/${user_id}/usuario`);

        if(response.status !== 200) {
          // toast.info('Nenhum retorno de Estratégia foi encontrado para este usuário.');
          setPerfil("Ainda não respondido");
          setLoading(false);
        } else {
          setPerfil(response.data.perfil);
          setEstrategia(response.data.estrategia);
          setLoading(false);
        }

      } catch (error) {
          toast.error('Erro ao carregar dados.');
          console.log(error)
      }
    }
    fetchData();
  }, [ user_id ]);

  const renderTable = () => {
    console.log(estrategia)
    const contentTable = estrategia.map((linha, index) => {
      return (
        <tr key={`linha-${index}`}>
          <td>{linha.fundo}</td>
          <td className="center">{linha.valor}%</td>
        </tr>
      )
    })

    return contentTable;
  }

  return (
    <Modal title="Estratégia a longo prazo" color="pgec" setShowModal={close} width={'600px'} height={'auto'}>
      {loading ? (
        <Loading />
      ) : (
        <Container>
          <PerfilInvestidor>Perfil do investidor: <span>{perfil}</span></PerfilInvestidor>
          <Table>
            <thead>
              <tr>
                <th>Fundos de investimentos</th>
                <th className="center">Estratégia</th>
              </tr>
            </thead>
            <tbody>
              {renderTable()}
            </tbody>
          </Table>
        </Container>
      )}
    </Modal>
  );
}

ModalProfileStrategy.propTypes = {
  close: PropTypes.func.isRequired,
};
