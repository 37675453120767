import React, { useContext } from 'react';
import { GeneralContext } from 'utils/contexts/GeneralContext';
import { LoadingTable } from 'components/templates/Table/LoadingTable';
import { DebtMappingPage } from '../DebtMappingPage';
import { RecipeMapPage } from '../RecipeMapPage';
import { MapInvestmentsPage } from '../MapInvestmentsPage';
import { MapSpendingPage } from '../MapSpendingPage';

export const BudgetTable = ({
  currentPage,
  receiptList,
  investments,
  debts,
  spendings,
}) => {
  const { loading } = useContext(GeneralContext);

  if (loading) {
    return <LoadingTable />;
  }
  const pages = {
    receipts: <RecipeMapPage receipts={receiptList} />,
    debts: <DebtMappingPage debts={debts} />,
    investments: <MapInvestmentsPage investments={investments} />,
    spendings: <MapSpendingPage spendings={spendings} />,
  };
  return pages[currentPage] || <></>;
};
