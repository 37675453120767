import { useState } from 'react';
import api from 'services/api';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

const useBudget = () => {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const { id: userId } = useSelector(state => state.user.profile);


  const postData = async (data) => {

    const user_id = userId

    try {
      const url = `/budget/${user_id}`;
      const axiosResponse = await api.post(url, data);

      setResponse(axiosResponse.data);
      toast.success('Pote salvo com sucesso!');

    } catch (error) {
      setError(error);
      toast.error('Ocorreu um erro ao salvar o pote.');
    }
  };
  return { response, error, postData };
};

export default useBudget;
