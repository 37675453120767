import styled from "styled-components";

export const GroupItensTitle = styled.div`
  display: flex;
  align-items: center;
  gap: ${props => props.theme.getResponsiveWidth(12)};

  &.generic-dream {
    gap: 0;
  }
`;