import React from 'react';
import { Span } from 'components/atoms/Span';
import TooltipIconWithOverlay from 'components/atoms/IconInformation';
import { ButtonDate } from 'components/molecules/ButtonDate';
import { GroupItens, TextContainer, TitleWrapper } from './styles';

export const TableTitleDate = ({ nameTable, tooltipText, type, className }) => {
  return (
    <TextContainer className={className}>
      <GroupItens>
        <TitleWrapper>
          <Span className="table-title">{nameTable}</Span>
          <TooltipIconWithOverlay
            className="home spending"
            tooltipText={tooltipText}
          />
        </TitleWrapper>
        <ButtonDate type={type} className={className} />
      </GroupItens>
    </TextContainer>
  );
};
