import styled from 'styled-components';
import { HorizontalScrollbarStyles } from 'styles/components/HorizontalScrollbarStyles';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: calc((475 / 1920) * 100vw);
  height: calc((270 / 1080) * 100vh);
  overflow-x: auto;
  overflow-y: hidden;

  &.default {
    ${HorizontalScrollbarStyles};
  }
`;

export const FlexContainer = styled.div`
  &.home {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: auto;
  }
`;
export const DivContainer = styled.div`
  &.home {
    display: flex;
    height: 90%;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 2%;
    gap: 8%;
  }
`;
