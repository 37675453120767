import React from 'react';
import ProgressBar from 'components/atoms/ProgressBar';
import { H6 } from 'components/atoms/Headings/H6';

export const TableProgress = ({ className, progressPercentage }) => {
  return (
    <>
      <H6 className="table-progress">{`${progressPercentage}%`}</H6>
      <ProgressBar className={className} progress={`${progressPercentage}px`} />
    </>
  );
};
