import styled from 'styled-components';

export const ModalBody = styled.div`
  font-size: ${props => props.theme.getResponsiveWidth(18)};
  text-align: center;
  margin: ${({ theme }) => theme.getResponsiveHeight(38)} 0;

  &.modal {
    display: flex;
  }

  &.generic-dream {
    margin: 0;
    display: flex;
    gap: ${props => props.theme.getResponsiveHeight(48)};

    @media (max-width: 1024px) {
      gap: 32.78px;
    }
  }

  &.realease {
    margin: 0;

    @media (max-height: 800px) {
      max-height: 500px;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        height: ${({ theme }) => theme.getResponsiveHeight(3)};
        width: 5px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: ${({ theme }) => theme.graySilver};
        border-radius: 50px;
      }
      &::-webkit-scrollbar-track {
        background-color: ${({ theme }) => theme.grayPale};
        border-radius: 50px;
      }
      &::-webkit-scrollbar-button {
        display: none;
      }
    }
  }

  &.gallery-modal {
    display: flex;
    gap: ${props => props.theme.getResponsiveWidth(8)};
    flex-wrap: wrap;
    width: ${props => props.theme.getResponsiveWidth(349)};
    height: ${props => props.theme.getResponsiveWidth(172)};
    overflow: auto;
    margin: ${({ theme }) => theme.getResponsiveWidth(18)} 0 0 0;

    @media (max-width: 1023px) {
      gap: 4.27px;
      width: 186.13px;
      height: 91.73px;
      margin: 9.6px 0 0 0;
    }
  }

  @media (max-width: 1023px) {
    font-size: 9.6px;
    margin: 25.9px 0;
  }
`;
