import React from 'react';
import {
  Container,
  ContainerWrapper,
  TitleWrapper,
  GroupItens,
} from './styles';
import { Span } from 'components/atoms/Span';
import GoIcon from 'components/atoms/icons/GoIcon';
import TooltipIconWithOverlay from 'components/atoms/IconInformation';

export const InvestmentsTableTItle = ({ nameTable, tooltipText = '' }) => {
  return (
    <Container>
      <ContainerWrapper>
        <Span className="category-title">{nameTable}</Span>
        <GoIcon className="budget-table" />
      </ContainerWrapper>
      <ContainerWrapper>
        <GroupItens>
          <TitleWrapper>
            <Span className="category-title">M.I.M.</Span>
            <TooltipIconWithOverlay
              className="home investments"
              tooltipText={tooltipText}
            />
          </TitleWrapper>
          <Span className="category-title">R$ 0</Span>
        </GroupItens>
        <GroupItens>
          <Span className={`category-title-two investments`}>Percentual</Span>
          <Span className={`category-title-two investments`}>0%</Span>
        </GroupItens>
      </ContainerWrapper>
    </Container>
  );
};
