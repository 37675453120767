import React from "react";
import { IMG } from "components/atoms/IMG";
import { SelectImageCard } from "components/molecules/SelectImageCard";
import { Input } from "components/atoms/Input";

export const ModalGalleryImage = ({
    images,
    className = '',
    getImageValue = () => { },
    setShowModal = () => { },
    showModal = false,
}) => {
    const fileInputRef = React.useRef(null);

    const handleContainerClick = (img) => {
        setShowModal(false);
        getImageValue(img);
    };

    const selectAnotherImage = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            getImageValue(imageUrl);
            setShowModal(false);
        }
    };

    return (
        <>
            {images.map((image, index) => (
                <IMG
                    key={index}
                    src={image}
                    onClick={() => handleContainerClick(image)}
                    className={className}
                />
            ))}
            <SelectImageCard
                className={className}
                onClick={selectAnotherImage}
                text="Escolher outra imagem"
            />
            <Input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleFileChange}
                accept="image/*"
            />
        </>
    )
}