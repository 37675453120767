import styled from 'styled-components';
import { GradientSkeletonAnimation } from 'styles/animations/GradientSkeletonAnimation';

export const Container = styled.div`
  width: 100%;
  padding-bottom: 0;

  &.responsive {
    @media (max-width: 1202px) {
      overflow-x: scroll;
    }
    &::-webkit-scrollbar {
      height: ${({ theme }) => theme.getResponsiveHeight(3)};
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${({ theme }) => theme.graySilver};
      border-radius: 50px;
    }
    &::-webkit-scrollbar-track {
      background-color: ${({ theme }) => theme.grayPale};
      border-radius: 50px;
    }
    &::-webkit-scrollbar-button {
      display: none;
    }
  }
`;

export const GroupIcons = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  height: min-content;
  gap: ${({ theme }) => theme.getResponsiveWidth(13)};
  margin-top: ${({ theme }) => theme.getResponsiveHeight(23)};
  padding-bottom: ${({ theme }) => theme.getResponsiveHeight(24)};
`;

export const TextContainer = styled.div`
  &.th {
    display: flex;
    justify-content: center;
  }
  &.budget {
    width: ${({ theme }) => theme.getResponsiveWidth(1556)};
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: ${({ theme }) => theme.getResponsiveHeight(30)};
  }
  &.patrimony {
    width: ${({ theme }) => theme.getResponsiveWidth(1556)};
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: ${({ theme }) => theme.getResponsiveHeight(10)};
  }

  &.pot-table {
    width: ${({ theme }) => theme.getResponsiveWidth(1015)};
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: ${({ theme }) => theme.getResponsiveHeight(34)};

    @media (max-width: 1023px) {
      width: ${({ theme }) => theme.getResponsiveWidth(1556)};
      position: absolute;
    }
  }
`;

export const GroupItens = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.total {
    width: ${({ theme }) => theme.getResponsiveWidth(350)};
    min-width: 150px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: ${({ theme }) => theme.getResponsiveHeight(6)};
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.getResponsiveWidth(5)};

  &.set {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
`;
export const TitleCardWrapper = styled.div`
  width: ${({ theme }) => theme.getResponsiveWidth(261)};
  min-width: 100px;
  display: flex;
  justify-content: space-between;
`;
export const Tr = styled.tr`
  &.receipt-table-title {
    width: 100%;
    height: ${props => props.theme.getResponsiveHeight(58)};

    &.receipts {
      background-color: ${({ theme }) => theme.blueHeaderWithSlowOpacity};
    }

    &.debts {
      background-color: ${({ theme }) => theme.redBloodWithSlowOpacity};
    }

    &.investments {
      background-color: ${({ theme }) => theme.greenDark};
      opacity: 0.55;
    }

    &.spendings {
      background-color: ${({ theme }) => theme.opacitySpending};
    }
  }

  &.receipt-table-body {
    height: ${props => props.theme.getResponsiveHeight(44)};
    border-bottom: ${props => props.theme.getResponsiveHeight(1)} solid
      ${({ theme }) => theme.graySilver};
  }

  &.loading {
    border-bottom: none;
    height: ${props => props.theme.getResponsiveHeight(44)};
  }

  &.investment-panel-title {
    background-color: ${({ theme }) => theme.investmentPanelBackground};
    height: ${props => props.theme.getResponsiveWidth(58)};
    padding: 0 ${props => props.theme.getResponsiveWidth(18)};
    border-radius: ${props => props.theme.getResponsiveWidth(8)};

    @media (max-width: 1023px) {
      height: 30.9px;
      padding: 0 9.6px;
      border-radius: 2.1px;
    }
  }

  &.investment-panel-body {
    height: ${props => props.theme.getResponsiveHeight(44)};
    border-bottom: ${props => props.theme.getResponsiveHeight(1)} solid
      ${({ theme }) => theme.graySilver};
  }

  &.button {
    height: auto;
  }
`;

export const Th = styled.th`
  &.budget-table-title {
    color: ${({ theme }) => theme.white};
    height: ${props => props.theme.getResponsiveHeight(58)};
    padding: ${props => props.theme.getResponsiveHeight(16)},
      ${props => props.theme.getResponsiveHeight(24)};
    border: none;
    font-size: ${props => props.theme.getResponsiveHeight(16)};
    text-align: ${props => (props.first ? 'left' : 'center')};

    &.checkbox {
      width: min-content;
      height: min-content;
      text-align: left;
      padding-left: 5px;
    }

    &.empyt {
      width: 1px;
    }
  }

  &.first-table-border {
    border-top-left-radius: ${props => props.theme.getResponsiveWidth(4)};
    border-bottom-left-radius: ${props => props.theme.getResponsiveWidth(4)};
  }

  &.last-table-border {
    border-top-right-radius: ${props => props.theme.getResponsiveWidth(4)};
    border-bottom-right-radius: ${props => props.theme.getResponsiveWidth(4)};
    width: 5px;
  }
  &.debts {
    width: min-content;
    height: min-content;
    text-align: center;
  }

  &.loading {
    height: ${props => props.theme.getResponsiveHeight(58)};
    background: linear-gradient(
      90deg,
      ${({ theme }) => theme.gradientSkeleton[0]} 25%,
      ${({ theme }) => theme.gradientSkeleton[1]} 50%,
      ${({ theme }) => theme.gradientSkeleton[0]} 75%
    );
    background-size: 200% 100%;
    animation: ${GradientSkeletonAnimation} 1.5s ease-in-out infinite;
    border-radius: 4px;
  }

  &.investment-panel {
    font-size: ${props => props.theme.getResponsiveWidth(16)};
    line-height: ${props => props.theme.getResponsiveWidth(16)};
    font-weight: 700;
    color: ${({ theme }) => theme.white};
    height: min-content;

    &.first {
      border-top-left-radius: ${props => props.theme.getResponsiveWidth(4)};
      border-bottom-left-radius: ${props => props.theme.getResponsiveWidth(4)};
      text-align: left;
      padding-left: ${props => props.theme.getResponsiveWidth(18)};
    }

    &.last {
      border-top-right-radius: ${props => props.theme.getResponsiveWidth(4)};
      border-bottom-right-radius: ${props => props.theme.getResponsiveWidth(4)};
      padding-left: ${props => props.theme.getResponsiveWidth(80)};
      padding-right: ${props => props.theme.getResponsiveWidth(16)};
    }

    &.generic-class {
      padding-left: ${props => props.theme.getResponsiveWidth(96)};
    }

    @media (max-width: 1023px) {
      font-size: 8.5px;
      line-height: 8.5px;

      &.first {
        border-top-left-radius: 2.1px;
        border-bottom-left-radius: 2.1px;
        padding-left: 9.6px;
      }

      &.last {
        border-top-right-radius: 2.1px;
        border-bottom-right-radius: 2.1px;
        padding-left: 42.7px;
        padding-right: 8.5px;
      }

      &.generic-class {
        padding-left: 51.2px;
      }
    }
  }
`;

export const Table = styled.table`
  &.receipt-table {
    width: ${props => props.theme.getResponsiveWidth(1556)};
    margin-top: ${props => props.theme.getResponsiveHeight(18)};
    height: min-content;
    min-width: 974px;
    overflow: auto;
    border-collapse: collapse;
    @media (max-width: 1023px) {
      min-width: 1500px;
    }

    td,
    th {
      padding: ${props => props.theme.getResponsiveHeight(10)};
    }
  }

  &.pot-table {
    width: ${props => props.theme.getResponsiveWidth(1015)};
    margin-top: ${props => props.theme.getResponsiveHeight(18)};
    height: min-content;
    min-width: 700px;
    overflow: auto;

    @media (max-width: 1023px) {
      margin-top: ${props => props.theme.getResponsiveHeight(60)};
    }
  }
  &.grafic-table {
    margin-top: ${props => props.theme.getResponsiveHeight(18)};
    width: ${props => props.theme.getResponsiveWidth(719)};
    height: ${props => props.theme.getResponsiveHeight(350)};
    min-width: 600px;

    td,
    th {
      padding: ${props => props.theme.getResponsiveHeight(10)};
    }

    @media (max-width: 1023px) {
      margin-top: ${props => props.theme.getResponsiveHeight(60)};
    }
  }
  &.first-table {
    width: ${props => props.theme.getResponsiveWidth(431)};
    height: min-content;
    min-width: 200px;
  }
  &.second-table {
    width: ${props => props.theme.getResponsiveWidth(902)};
    height: min-content;
    min-width: 400px;
  }
  &.third-table {
    width: ${props => props.theme.getResponsiveWidth(209)};
    height: min-content;
    min-width: 100px;
  }

  &.min-table {
    width: ${props => props.theme.getResponsiveWidth(545)};
    height: min-content;

    @media (max-width: 1023px) {
      width: 100%;
    }
  }
  &.recom-table {
    width: ${props => props.theme.getResponsiveWidth(366)};
    height: min-content;
    @media (max-width: 1023px) {
      width: 100%;
    }
  }
  &.plan-table {
    width: ${props => props.theme.getResponsiveWidth(629)};
    height: min-content;
    @media (max-width: 1023px) {
      width: 100%;
    }
  }

  &.investment-panel {
    width: ${props => props.theme.getResponsiveWidth(1556)};
    text-align: center;
    border-radius: ${props => props.theme.getResponsiveWidth(4)};
    table-layout: auto;

    @media (max-width: 1023px) {
      width: 829.9px;
      border-radius: 2.1px;
    }
  }
`;

export const Td = styled.td`
  &.receipt-table-body {
    height: ${props => props.theme.getResponsiveHeight(55)};
    padding: ${props => props.theme.getResponsiveHeight(16)},
      ${props => props.theme.getResponsiveHeight(24)};
    text-align: ${props => (props.first ? 'left' : 'center')};
    font-size: ${props => props.theme.getResponsiveHeight(14)};
    line-height: ${props => props.theme.getResponsiveHeight(16)};
    color: ${({ theme }) => theme.grayShadow};
    vertical-align: middle;
    font-family: ${props => props.theme.fonts.AperturaRegular};
    font-weight: 400;

    &.red {
      color: ${({ theme }) => theme.redLight};
      font-family: ${props => props.theme.fonts.AperturaBold};
    }
    &.blue-lilac {
      color: ${({ theme }) => theme.blueLilac};
      font-family: ${props => props.theme.fonts.AperturaBold};
    }
    &.blue-midnight {
      color: ${({ theme }) => theme.blueMidnight};
      font-family: ${props => props.theme.fonts.AperturaBold};
    }
    &.blue-opacity {
      color: ${({ theme }) => theme.blueMidnight};
      font-family: ${props => props.theme.fonts.AperturaBold};
    }

    &.checkbox {
      text-align: left;
      width: min-content;
    }

    &.options {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    &.right {
      width: min-content;
      height: min-content;
      text-align: right;
    }
  }

  &.debts {
    width: min-content;
    height: min-content;
    text-align: right;
  }
  &.table-recom {
    display: flex;
    justify-content: center;
    width: ${props => props.theme.getResponsiveWidth(266)};
    height: ${props => props.theme.getResponsiveHeight(366)};
    overflow: auto;
  }

  &.spending {
    text-align: right;
    width: 230px;
  }

  &.total-report {
    height: ${props => props.theme.getResponsiveHeight(75)};
    padding: ${props => props.theme.getResponsiveHeight(16)},
      ${props => props.theme.getResponsiveHeight(24)};
    font-size: ${props => props.theme.getResponsiveHeight(24)};
    color: ${({ theme }) => theme.grayShadow};
    font-family: ${props => props.theme.fonts.AperturaRegular};
    &.first {
      text-align: left;
    }
    &.center {
      text-align: center;
    }
    &.right {
      text-align: right;
    }

    &.blue-opacity {
      color: ${({ theme }) => theme.blueMidnight};
      font-family: ${props => props.theme.fonts.AperturaBold};
    }
  }

  &.investment-panel {
    font-size: ${props => props.theme.getResponsiveWidth(16)};
    line-height: ${props => props.theme.getResponsiveWidth(16)};
    color: ${({ theme }) => theme.grayShadow};
    height: ${props => props.theme.getResponsiveHeight(44)};
    text-align: center;
    font-family: ${props => props.theme.fonts.AperturaRegular};

    &.first {
      text-align: left;
      padding-left: ${props => props.theme.getResponsiveWidth(18)};
    }

    &.last {
      padding-left: ${props => props.theme.getResponsiveWidth(80)};
      padding-right: ${props => props.theme.getResponsiveWidth(16)};
    }

    &.generic-class {
      padding-left: ${props => props.theme.getResponsiveWidth(96)};
    }

    @media (max-width: 1023px) {
      font-size: 8.5px;
      line-height: 8.5px;
      height: 30.1px !important;

      &.first {
        padding-left: 9.6px;
      }

      &.last {
        padding-left: 42.7px;
        padding-right: 8.5px;
      }

      &.generic-class {
        padding-left: 51.2px;
      }
    }
  }
`;

export const ContainerProgress = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const DropdownWrapper = styled.div`
  display: flex;
  width: ${props => props.theme.getResponsiveWidth(420)};
  gap: ${props => props.theme.getResponsiveWidth(36)};
  min-width: 250px;
`;
