import Checkbox from 'components/atoms/Checkbox';
import { Table } from 'components/atoms/Table';
import { Td } from 'components/atoms/Td';
import { Th } from 'components/atoms/Th';
import { Tr } from 'components/atoms/Tr';
import { BudgetTableTitle } from 'components/molecules/BudgetTableTitle';
import React, { useState, useEffect } from 'react';
import { CardContainer, Container } from './styles';

export const SimpleTableWithCheckboxAtTheEnd = ({
  header,
  body,
  nameTable,
  totalCategoryValue,
  totalCategoryPercentage,
  showEditIconOnTitle,
  typeCard,
}) => {
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    setTableData(body);
  }, [body]);

  return (
    <CardContainer className="no-footer">
      <BudgetTableTitle
        nameTable={nameTable}
        total={totalCategoryValue}
        percentage={totalCategoryPercentage}
        showEditIcon={showEditIconOnTitle}
        showGoldIcon
        className={typeCard}
      />
      <Container>
        <Table className="receipt-table">
          <thead>
            <Tr className="receipt-table-title debts">
              {header.map((item, index) => (
                <Th
                  className={`budget-table-title  ${index === 0 &&
                    'first-table-border '} ${index === 4 && 'debts'} `}
                  first={index === 0}
                  key={index}
                >
                  {item}
                </Th>
              ))}
              <Th className="budget-table-title empty last-table-border final" />
            </Tr>
          </thead>
          <tbody>
            {tableData.map((row, rowIndex) => (
              <Tr className="receipt-table-body" key={rowIndex}>
                {Object.keys(row.body).map((item, index) => {
                  const props = row.body[item];

                  if (item === 'settled') {
                    return (
                      <Td className="receipt-table-body " key={index}>
                        <Checkbox checked={props} className="circular" />
                      </Td>
                    );
                  }

                  return (
                    <Td
                      className="receipt-table-body "
                      key={index}
                      first={index === 0}
                    >
                      {props}
                    </Td>
                  );
                })}
              </Tr>
            ))}
          </tbody>
        </Table>
      </Container>
    </CardContainer>
  );
};
