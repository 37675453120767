import styled from 'styled-components';

export const DropdownContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const DropdownTrigger = styled.div`
  height: ${({ theme }) => theme.getResponsiveHeight(30)};
  margin-top: ${({ theme }) => theme.getResponsiveHeight(11)};
  background-color: transparent;
  padding: ${props => props.theme.getResponsiveHeight(8)};
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.grayShadow};

  &.simple-modal {
    &.min-size {
      margin-top: 0;
    }
  }

  &.hide-border {
    border: none;
  }

  &.generic-dream-modal {
    height: ${props => props.theme.getResponsiveWidth(30)};
    margin-top: ${props => props.theme.getResponsiveWidth(11)};
    padding: ${props => props.theme.getResponsiveWidth(8)};
    border-bottom: ${props => props.theme.getResponsiveWidth(1)} solid
      ${props => props.theme.grayShadow};

    @media (max-width: 1023px) {
      height: 16px;
      margin-top: 5.87px;
      padding: 4.27px;
      border-bottom: 0.53px solid ${props => props.theme.grayShadow};
    }
  }
`;

export const DropdownTriggerLabel = styled.span`
  font-family: ${props => props.theme.fonts.AperturaRegular};
  font-size: ${props => props.theme.getResponsiveHeight(14)};
  line-height: ${props => props.theme.getResponsiveHeight(14)};
  color: ${({ theme }) => theme.graySelectPlaceholder};
  font-weight: 400;

  &.generic-dream-modal {
    font-size: ${props => props.theme.getResponsiveWidth(14)};
    line-height: ${props => props.theme.getResponsiveWidth(14)};

    @media (max-width: 1023px) {
      font-size: 7.47px;
      line-height: 7.47px;
    }
  }
`;

export const DropdownTriggerIcon = styled.span`
  transition: transform 0.2s ease-in-out;
  transform: ${props => (props.isOpen ? 'rotate(180deg)' : 'none')};
`;

export const DropdownOptionsList = styled.ul`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: ${({ theme }) => theme.grayPale};
  border-radius: ${({ theme }) => theme.getResponsiveHeight(4)};
  list-style: none;
  padding: 0;
  margin: 0;
  display: none;
  max-height: ${props => props.theme.getResponsiveHeight(262)};
  overflow: auto;
  display: ${props => (props.isOpen ? 'block' : 'none')};
  z-index: 1000;

  &.generic-dream-modal {
    border-radius: ${({ theme }) => theme.getResponsiveWidth(4)};
    max-height: ${props => props.theme.getResponsiveWidth(262)};

    @media (max-width: 1023px) {
      border-radius: 2.1px;
      max-height: 139.7px;
    }
  }
`;

export const DropdownOptionItem = styled.li`
  padding: ${props => props.theme.getResponsiveHeight(10)},
    ${props => props.theme.getResponsiveWidth(16)};
  cursor: pointer;

  &:hover {
    background-color: ${props => props.theme.selectHover};

    span {
      color: ${props => props.theme.white};
    }
  }

  &.margin {
    padding-left: ${({ theme }) => theme.getResponsiveWidth(10)};
  }

  &.generic-dream-modal {
    padding: ${props => props.theme.getResponsiveWidth(10)},
      ${props => props.theme.getResponsiveWidth(16)};
    width: 100%;

    @media (max-width: 1023px) {
      padding: 5.33px 8.53px;
    }
  }
`;

export const DropdownOptionItemLabel = styled.span`
  font-family: ${props => props.theme.fonts.AperturaRegular};
  font-size: ${props => props.theme.getResponsiveHeight(14)};
  line-height: ${props => props.theme.getResponsiveHeight(14)};
  font-weight: 400;
  color: ${props => props.theme.graySilver};
`;
