import styled from 'styled-components';

export const ArrowContainer = styled.svg`
  width: ${props => props.theme.getResponsiveHeight(15)};
  height: ${props => props.theme.getResponsiveHeight(8)};

  &.loading {
    display: none;
  }
`;
