import React from 'react';
import { BsOctagon } from 'react-icons/bs'
import { Container,
         Octagon,
         PurpleBar,
         ContentWrapper,
         Labels,
         Label,
         LogoAzul,
         Footer,
         Title } from './styles';



export default function ManagementPdfComponent({data}) {

  const { headerTitle, footerTitle, labels } = data;

  // console.log(headerTitle, footerTitle, labels)

  const renderLabels = () => {
    const allLabels = labels.map((label, index) => {
      return (
        <Label key={label} rotateSvg={index % 2 === 0}>
          <BsOctagon /> <span>{label}</span>
        </Label>
      );
    })
    return allLabels;
  }

  return (
    <ContentWrapper id="printable" minHeight={1684}>
      <Container padding="0 78px 96px 78px">
        <PurpleBar />
      </Container>
      <Container padding="0 78px">
        <Octagon />
        <Title dangerouslySetInnerHTML={{__html: headerTitle}} />
      </Container>
      <Container padding="312px 0 136px 0" height="calc(100% - 136px)">
        <Labels>
          {renderLabels()}
        </Labels>
      </Container>
      <Footer>
        <Container padding="0 78px">
            <p>{ footerTitle }</p>
            <LogoAzul />
        </Container>
      </Footer>
    </ContentWrapper>
  );
}
