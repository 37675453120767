import dateUTC from 'deprecated/utils/dateUTC';

const { differenceInCalendarMonths } = require('date-fns');

export function monthlyPayments(item, startFilter) {
  if (item.payment === 'À vista') {
    const currentInstallment = 1;
    const totalInstallments = 1;
    return {
      currentInstallment,
      totalInstallments,
    };
  }

  const currentInstallment =
    differenceInCalendarMonths(startFilter, dateUTC(item.start)) + 1;
  const totalInstallments =
    differenceInCalendarMonths(dateUTC(item.end), dateUTC(item.start)) + 1;
  // const remainingValue =
  //   (differenceInCalendarMonths(dateUTC(item.end), new Date()) + 1) *
  //   item.value;

  return {
    currentInstallment,
    totalInstallments,
    // remainingValue,
  };
}
