import React, { useContext, useState, useEffect } from 'react';
import { Card } from 'components/templates/Card';
import { GeneralContext } from 'utils/contexts/GeneralContext';

export const FlexibleEventual = ({
  title = 'Eventual Flexível',
  icon = 'flexibleEventual',
  titleContentLeft = 'Estimado',
  titleContentRight = 'Pode gastar',
  estimated,
  realized,
  titleType = 'Anual',
  currentPage,
  setCurrentPage,
  progressValue,
}) => {
  const { loading } = useContext(GeneralContext);

  const [selected, setSelected] = useState(currentPage === 'flexibleEventual');
  const [hovered, setHovered] = useState(currentPage === 'flexibleEventual');

  const select = selected || hovered ? 'selected' : 'spending';
  const selectedClass = selected || hovered ? 'selected-spending' : '';
  const loadingClass = loading ? 'loading' : '';

  const handleClick = () => {
    setSelected(!selected);
    setCurrentPage('flexibleEventual');
  };

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };
  useEffect(() => {
    setSelected(currentPage === 'flexibleEventual');
    setHovered(currentPage === 'flexibleEventual');
  }, [currentPage]);

  return (
    <Card.Container
      selected={selected}
      className={`budget ${loadingClass} ${selectedClass}`}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Card.BodyContainer className={`budget ${loadingClass}`}>
        <Card.Title
          title={title}
          type={icon}
          className={`${select} spending-card`}
        />
      </Card.BodyContainer>
      <Card.Content className={`budget ${loadingClass}`}>
        <Card.ContentSpending
          className={`${select} font-spending margin-max`}
          title={titleContentLeft}
          subTitle={`R$ ${estimated}`}
        />
        <Card.ContentSpending
          className={`${select} font-spending margin-max right`}
          title={titleContentRight}
          subTitle={`R$ ${realized}`}
        />
      </Card.Content>
      <Card.Progress
        className={`${select} ${loadingClass}`}
        progress={progressValue}
      />
      <Card.ContentH5
        className={`right margin-min ${loadingClass} ${select}`}
        title={titleType}
      />
    </Card.Container>
  );
};
