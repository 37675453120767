import React, { useMemo } from 'react';
import { toCurrencyNoCents as formatReal } from 'deprecated/utils/toCurrency';
import {
  Container,
  ContainerColumn,
  SubTitle,
  PurpleBar,
  ContentWrapper,
  LogoAzul,
  Table,
  Footer,
  Title,
} from './styles';

export default function EquitySealing({ data }) {
  const {
    headerTitle,
    footerTitle,
    sealableGoalsPatrimony,
    longTermStrategy,
  } = data;

  const goalsTable = useMemo(() => {
    if (!sealableGoalsPatrimony?.financialIndependenceGoalsDetail.length) {
      return null;
    }

    const dataTable = [
      ...sealableGoalsPatrimony.financialIndependenceGoalsDetail,
      sealableGoalsPatrimony.defaultOverviewGoals,
      ...sealableGoalsPatrimony.defaultGoals,
    ];

    const _total = {
      name: 'Total',
      value: 0,
      patrimonialActiveValue: 0,
      financialActiveValue: 0,
      finalFinancialPatrimony: 0,
      when: '',
      allocationStrategy: '',
      monthlyInvestmentGoal: 0,
    };

    const contentTable = dataTable.map((row, index) => {
      const _class = index === 0 || index === 3 ? 'blue' : '';
      const _style =
        index === 0 || index === 3 ? { backgroundColor: '#5960ff' } : {};

      if (index === 0 || index === 3) {
        _total.value += row.value;
        _total.patrimonialActiveValue += row.patrimonialActiveValue;
        _total.financialActiveValue += row.financialActiveValue;
        _total.finalFinancialPatrimony += row.finalFinancialPatrimony;
        _total.monthlyInvestmentGoal += row.monthlyInvestmentGoal;
      }

      if (index < dataTable.length - 1) {
        return (
          <tr className={_class} key={`tr-j-${index}`}>
            <td className="title">{row.name}</td>
            <td className="center">{formatReal(row.value)}</td>
            <td className="center">{formatReal(row.patrimonialActiveValue)}</td>
            <td className="center">{formatReal(row.financialActiveValue)}</td>
            <td className="center">
              {formatReal(row.finalFinancialPatrimony)}
            </td>
            <td className="center">{row.when}</td>
            <td className="center">{row.allocationStrategy}</td>
            <td style={_style} className="center">
              {formatReal(row.monthlyInvestmentGoal)}
            </td>
          </tr>
        );
      }
      return (
        <React.Fragment key={`tr-${index}`}>
          <tr className={_class}>
            <td className="title">{row.name}</td>
            <td className="center">{formatReal(row.value)}</td>
            <td className="center">
              {formatReal(
                row.currentFixedPatrimony || row.patrimonialActiveValue
              )}
            </td>
            <td className="center">{formatReal(row.financialActiveValue)}</td>
            <td className="center">
              {formatReal(row.finalFinancialPatrimony)}
            </td>
            <td className="center">{row.when}</td>
            <td className="center">{row.allocationStrategy}</td>
            <td style={_style} className="center">
              {formatReal(row.monthlyInvestmentGoal)}
            </td>
          </tr>
          <tr className="blue">
            <td className="center">{_total.name}</td>
            <td className="center">{formatReal(_total.value)}</td>
            <td className="center">
              {formatReal(_total.patrimonialActiveValue)}
            </td>
            <td className="center">
              {formatReal(_total.financialActiveValue)}
            </td>
            <td className="center">
              {formatReal(_total.finalFinancialPatrimony)}
            </td>
            <td className="center">{_total.when}</td>
            <td className="center">{_total.allocationStrategy}</td>
            <td style={{ backgroundColor: '#5960ff' }} className="center">
              {formatReal(_total.monthlyInvestmentGoal)}
            </td>
          </tr>
        </React.Fragment>
      );
    });

    return contentTable;
  }, [sealableGoalsPatrimony]);

  const strategiesTable = useMemo(() => {
    if (!longTermStrategy?.strategies) return null;
    const strategies = [
      ...longTermStrategy?.strategies.calculatedFunds,
      longTermStrategy?.strategies.overviewPostion,
    ];

    const contentTable = strategies.map((row, index) => {
      if (index < strategies.length - 1) {
        return (
          <tr key={`tr-j-${index}`}>
            <td className="title h-0">{row.fund}</td>
            <td className="center h-0">{`${row.longTermStrategy}%`}</td>
            <td className="center h-0">{formatReal(row.value)}</td>
          </tr>
        );
      }
      return (
        <React.Fragment key={`tr-${index}`}>
          <tr className="blue">
            <td className="center h-0">{row.fund}</td>
            <td className="center h-0">{`${row.longTermStrategy}%`}</td>
            <td className="center h-0">{formatReal(row.value)}</td>
          </tr>
        </React.Fragment>
      );
    });

    return contentTable;
  }, [longTermStrategy]);

  return (
    <ContentWrapper id="printable" style={{ minHeight: '1682px' }}>
      <Container padding="0 78px 82px 78px">
        <PurpleBar />
      </Container>
      <Container padding="0 78px">
        <ContainerColumn>
          <Title dangerouslySetInnerHTML={{ __html: headerTitle }} />
          <p className="p-smaller">
            Entenda como seu patrimônio está alocado hoje <br />
            com relação aos seus objetivos
          </p>
        </ContainerColumn>
      </Container>
      <Container padding="0px 78px 19px 78px">
        <ContainerColumn className="full-content">
          <Table>
            <thead>
              <tr>
                <th className="center" style={{ width: '195px' }}>
                  Sonhos
                </th>
                <th className="center">Valor do Sonho</th>
                <th className="center">
                  Patrimônio
                  <br /> Imobilizado Atual
                </th>
                <th className="center">
                  Patrimônio
                  <br /> Financeiro Atual
                </th>
                <th className="center">
                  Patrimônio
                  <br /> Financeiro Final
                </th>
                <th className="center">
                  Prazo
                  <br />
                  (meses)
                </th>
                <th className="center">
                  Estratégia de
                  <br />
                  Alocação
                </th>
                <th style={{ backgroundColor: '#5960ff' }} className="center">
                  MIM
                </th>
              </tr>
            </thead>
            <tbody>{goalsTable}</tbody>
          </Table>
        </ContainerColumn>
      </Container>
      <Container padding="79px 78px 0px 78px" className="split">
        <div
          className="d-40w"
          style={{ paddingBottom: '2px', minHeight: '350px' }}
        >
          <div className="header-title">
            <SubTitle className="bigger uppercase">
              Estratégia de
              <br />
              Longo Prazo - ELP
            </SubTitle>
            <p className="p-smaller-no-mg">
              Todos os seus projetos de longo Prazo podem seguir uma mesma
              estratégia baseado em uma alocação de portifólio: uma distribuição
              diversificada que garanta maior rentabilidade com menor
              volatividade
            </p>
          </div>
          <Container style={{ marginTop: 'auto' }} className="split-2">
            <div className="d-purple">Perfil do Investidor</div>
            <div className="d-gray">{longTermStrategy?.profile}</div>
          </Container>
        </div>
        <div className="d-60w">
          <div className="half-content">
            <Table>
              <thead>
                <tr>
                  <th className="center" style={{ width: '50%' }}>
                    Estratégia
                  </th>
                  <th className="center">ELP*</th>
                  <th className="center">Valores Finais</th>
                </tr>
              </thead>
              <tbody>{strategiesTable}</tbody>
            </Table>
          </div>
        </div>
      </Container>
      <Footer>
        <Container padding="0 78px">
          <p>{footerTitle}</p>
          <LogoAzul />
        </Container>
      </Footer>
    </ContentWrapper>
  );
}
