import React, { useContext } from 'react';
import { GeneralContext } from 'utils/contexts/GeneralContext';
import { LoadingTable } from 'components/templates/Table/LoadingTable';
import { CommittedMonthlyTable } from './CommittedMonthlyTable';
import { FlexibleMonthlyTable } from './FlexibleMonthlyTable';
import { FlexibleEventualTable } from './FlexibleEventualTable';
import { CommittedEventualTable } from './CommittedEventualTable';

export const SpendingTable = ({
  currentPage,
  committedMonthly,
  flexibleMonthly,
  committedEventual,
}) => {
  const { loading, width } = useContext(GeneralContext);

  const pages = {
    committedMonthly: (
      <CommittedMonthlyTable committedMonthly={committedMonthly} />
    ),
    flexibleEventual: (
      <FlexibleEventualTable flexibleMonthly={flexibleMonthly} />
    ),
    committedEventual: (
      <CommittedEventualTable
        committedEventual={committedEventual}
        width={width}
      />
    ),
    flexibleMonthly: <FlexibleMonthlyTable flexibleMonthly={flexibleMonthly} />,
  };

  if (loading) {
    return <LoadingTable />;
  }
  return pages[currentPage] || <></>;
};
