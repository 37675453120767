import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import newApi from 'services/newApi';
import { toast } from 'react-toastify';
import {
  FinancialPlanningMapPdfComponent,
  ManagementPdfComponent,
  DirectorCustomerSuccessPdfComponent,
  ManagementEventualPdfComponent,
  MyBudgetPotPdfComponent,
  MoneyFlowPdfComponent,
  OAPIndicatorsPdfComponent,
  BudgetDistributionPdfComponent,
} from '../PDF';

const DataGestaoOrcamentaria = {
  headerTitle: 'Gestão <br />Orçamentária',
  footerTitle: 'O Mapa do seu Planejamento Financeiro',
  labels: [
    'Meu Pote Orçamentário',
    'Fluxo do Dinheiro',
    'Distribuição Orçamentária',
    'Gestão de Eventuais',
    'Indicadores do OAP',
  ],
};

const ManagementEventual = {
  headerTitle: 'Gestão<br />de Eventuais',
  footerTitle: 'Gestão Orçamentária',
};

const MyBudgetPot = {
  headerTitle: 'Meu Pote<br />Orçamentário',
  footerTitle: 'Gestão Orçamentária',
};

const DirectorCustomerSuccess = {
  footerTitle: 'O Mapa do seu Planejamento Financeiro',
};

const MoneyFlow = {
  headerTitle: 'Fluxo<br />do Dinheiro',
  footerTitle: 'Gestão Orçamentário',
};

const OAPIndicators = {
  headerTitle: 'Indicadores<br />do OAP',
  footerTitle: 'Gestão Orçamentária',
};

const BudgetDistribution = {
  headerTitle: 'Distribuição<br />Orçamentária',
  footerTitle: 'Gestão Orçamentária',
};

const initialState = {
  cliente: '',
  distribuicaoOrcamentaria: [],
  fluxoDinheiro: [],
  gestaoEventuais: {
    lec: 0,
    pec: 0,
    eventuaisFlexiveis: [],
    eventuaisComprometidos: [],
  },
  indicadoresOAP: [],
  poteOrcamentario: [],
};

export default function ActingR1() {
  const { id } = useSelector(state => state.user.profile);

  const [dataRelatorioOrcamentario, setDataRelatorioOrcamentario] = useState(
    initialState
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        // NÃO COLOCA A URL FIXA DE TESTES AQUI
        // SE QUISER ALTERAR, ALTERE DENTRO DO services/newApi
        const response = await newApi.get(`Relatorio/orcamentario/${id}`);

        const { status, data } = response;
        if (status !== 200) {
          toast.error('Não há dados para serem exibidos');
        } else {
          setDataRelatorioOrcamentario(data);
        }
      } catch (error) {
        toast.error('Erro ao fazer a requisição');
      }
    };
    fetchData();
  }, [id]);

  return (
    <>
      <FinancialPlanningMapPdfComponent data={dataRelatorioOrcamentario} />
      <DirectorCustomerSuccessPdfComponent data={DirectorCustomerSuccess} />
      <ManagementPdfComponent data={DataGestaoOrcamentaria} />
      <MyBudgetPotPdfComponent
        data={{ ...MyBudgetPot, ...dataRelatorioOrcamentario }}
      />
      <MoneyFlowPdfComponent
        data={{ ...MoneyFlow, ...dataRelatorioOrcamentario }}
      />
      <BudgetDistributionPdfComponent
        data={{ ...BudgetDistribution, ...dataRelatorioOrcamentario }}
      />
      <ManagementEventualPdfComponent
        data={{ ...ManagementEventual, ...dataRelatorioOrcamentario }}
      />
      <OAPIndicatorsPdfComponent
        data={{ ...OAPIndicators, ...dataRelatorioOrcamentario }}
      />
    </>
  );
}
