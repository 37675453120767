import styled from 'styled-components';

export const ShoppingCartIconContainer = styled.svg`
  &.summary-card {
    width: ${props => props.theme.getResponsiveWidth(19.18)};
    height: ${props => props.theme.getResponsiveHeight(15.63)};
    border-radius: ${props => props.theme.getResponsiveWidth(4)};

    path {
      fill: ${({ theme }) => theme.pale};
    }
  }
`;
