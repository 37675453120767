import React, { useState } from 'react';
import { Modal } from 'components/templates/Modal';

export const EditDebtItemModal = ({
  showModal = false,
  setShowModal = () => { },
  selectedItem = null,
  categoriesClassification = [],
  itensClassification = [],
  title = 'Editar item',
  icon,
  addItemKey,
  editItemKey,
  onConfirm = () => { },
  onCancel = () => { },
  confirmButtonText = 'Confirmar',
  cancelButtonText = 'Cancelar',
  theme,
}) => {
  const [name, setName] = useState(selectedItem?.name);
  const [item, setItem] = useState(selectedItem?.item);
  const [classification, setClassification] = useState(selectedItem?.category);
  const [installmentValue, setInstallmentValue] = useState(
    selectedItem?.installmentValue
  );
  const [remainingValue, setRemainingValue] = useState(
    selectedItem?.remainingValue
  );
  const [totalInstallments, setTotalInstallments] = useState(
    selectedItem?.totalInstallments
  );

  const clickConfirm = () => {
    onConfirm();
    setShowModal(false);
  };

  const clickCancel = () => {
    if (icon === 'add') {
      localStorage.setItem(
        addItemKey,
        JSON.stringify({
          name,
          item,
          classification,
          installmentValue,
          remainingValue,
          totalInstallments,
        })
      );
    } else {
      localStorage.setItem(
        editItemKey,
        JSON.stringify({
          id: selectedItem?.id,
          name: name,
          item: item,
          classification: classification,
          installmentValue: installmentValue,
          remainingValue: remainingValue,
          totalInstallments: totalInstallments,
        })
      );
    }

    onCancel();
    setShowModal(false);
  }

  return (
    <Modal.Container
      showModal={showModal}
      setShowModal={setShowModal}
      className="simple-modal delete"
    >
      <Modal.TitleContainer>
        <Modal.SimpleTitleContent
          title={title}
          onClose={() => setShowModal(false)}
          type={icon}
          className="simple-modal delete"
          theme={theme}
        />
      </Modal.TitleContainer>
      <Modal.BodyContainer>
        <Modal.Dropdown
          className="simple-modal"
          selectOptions={categoriesClassification}
          getDropdownValue={setClassification}
          label="Categoria"
          placeholderSelect={classification}
        />
        <Modal.Dropdown
          className="simple-modal after"
          getDropdownValue={setItem}
          label="Item"
          placeholderSelect={item}
          selectOptions={itensClassification}
        />
        <Modal.InputText
          className="simple-modal after"
          label="Nome"
          getInputValue={setName}
          defaultValue={name}
        />
        <Modal.InputMoney
          className="simple-modal after"
          label="Valor da parcela"
          getInputValue={setInstallmentValue}
          defaultValue={installmentValue}
        />
        <Modal.InputMoney
          className="simple-modal after"
          label="Valor restante"
          getInputValue={setRemainingValue}
          defaultValue={remainingValue}
        />
        <Modal.InputText
          className="simple-modal after"
          label="Total de parcelas"
          getInputValue={setTotalInstallments}
          defaultValue={totalInstallments}
        />
      </Modal.BodyContainer>
      <Modal.SimpleFooter
        onCancel={() => clickCancel()}
        onConfirm={() => clickConfirm()}
        className={`simple-modal ${theme}`}
        confirmButtonText={confirmButtonText}
        cancelButtonText={cancelButtonText}
      />
    </Modal.Container>
  );
};
