import styled from 'styled-components';
import { GradientSkeletonAnimation } from 'styles/animations/GradientSkeletonAnimation';

export const GoIconContainer = styled.svg`
  &.hide-go-icon {
    display:none;

    path:first-of-type {
      fill: none;
    }

    path:nth-of-type(2) {
      fill: none;
    }
  }

  &.title-card {
    display: flex;
    align-items: flex-start;
    width: ${props => props.theme.getResponsiveWidth(16)};
    height: ${props => props.theme.getResponsiveHeight(16)};

    path:first-of-type {
      fill: ${({ theme }) => theme.grayMedium};
    }

    path:nth-of-type(2) {
      fill: ${({ theme }) => theme.grayMedium};
    }
  }

  &.budget-table {
    width: ${props => props.theme.getResponsiveWidth(16)};
    height: ${props => props.theme.getResponsiveHeight(16)};
    margin-left: ${({ theme }) => theme.getResponsiveWidth(20)};
    align-self: flex-start;
    cursor: pointer;
    fill: ${({ theme }) => theme.grayMedium};
  }

  &.goal-panel {
    display: block;
    width: ${props => props.theme.getResponsiveWidth(16)};
    height: ${props => props.theme.getResponsiveWidth(16)};
    min-height: 9px;
    min-width: 9px;

    &.at-top {
      align-self: start;
    }

    path:first-of-type {
      fill: ${({ theme }) => theme.grayMedium};
    }

    path:nth-of-type(2) {
      fill: ${({ theme }) => theme.grayMedium};
    }
  }

  &.investment-panel-title {
    width: ${props => props.theme.getResponsiveWidth(16)};
    height: ${props => props.theme.getResponsiveHeight(16)};
    fill: ${({ theme }) => theme.grayMedium};
    margin-left: ${props => props.theme.getResponsiveWidth(20)};
    align-self: flex-start;
    cursor: pointer;

    @media (max-width: 1023px){
      width: 8.5px;
      height: 10.9px;
      margin-left: 10.7px;
    }
  }
`;

export const LoadingIcon = styled.div`
  width: ${props => props.theme.getResponsiveWidth(32)};
  height: ${props => props.theme.getResponsiveHeight(32)};
  background: linear-gradient(
    90deg,
    ${({ theme }) => theme.gradientSkeleton[0]} 25%,
    ${({ theme }) => theme.gradientSkeleton[1]} 50%,
    ${({ theme }) => theme.gradientSkeleton[0]} 75%
  );
  background-size: 200% 100%;
  animation: ${GradientSkeletonAnimation} 1.5s ease-in-out infinite;
  border-radius: ${props => props.theme.getResponsiveWidth(5)};

  &.hide-go-icon {
    display: none;
  }
`;
