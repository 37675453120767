import { css } from 'styled-components';

export const HorizontalScrollbarStyles = css`
  /* Estilo para a barra de rolagem */
  &::-webkit-scrollbar {
    width: 3px; /* Tamanho da barra de rolagem menor */
    height: 5px;
    background-color: red; /* Cor vermelha */
  }

  /* Estilo para o rastreador (a barra de rolagem propriamente dita) */
  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) =>
    theme.graySilver};
    border-radius: 50px;
  }

  /* Estilo para o fundo da barra de rolagem */
  &::-webkit-scrollbar-track {
    background-color: ${({ theme }) => theme.grayPale};
  }

  /* Estilo para os botões de rolagem (setas) */
  &::-webkit-scrollbar-button {
    display: none;
  }
`;
