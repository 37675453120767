import React, { useState } from 'react';
import { CardContainer, Home } from './styles';
import DirecionalButton from 'components/organisms/Direcional';
import { CommittedMonthly } from 'components/organisms/SpendingCards/CommittedMonthly';
import { CommittedEventual } from 'components/organisms/SpendingCards/CommittedEventuals';
import { FlexibleEventual } from 'components/organisms/SpendingCards/FlexibleEventual';
import { FlexibleMonthly } from 'components/organisms/SpendingCards/FlexibleMonthly';
import { SpendingTable } from 'components/organisms/SpendingTable';
import {
  getCommittedEventual,
  getCommittedMonthly,
  getFlexibleMonthly,
} from 'development';

export default function SpendingManagement() {
  const [currentPage, setCurrentPage] = useState('committedMonthly');

  const commitedM = { estimated: 10, realized: 10, progressValue: 10 };
  const commitedE = { estimated: 10, realized: 10, progressValue: 10 };
  const flexibleM = { estimated: 10, realized: 10, progressValue: 10 };
  const flexibleE = { estimated: 10, realized: 10, progressValue: 10 };

  return (
    <>
      <Home>
        <CardContainer>
          <CommittedMonthly
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            estimated={commitedM.estimated}
            realized={commitedM.realized}
            progressValue={commitedM.progressValue}
          />
          <FlexibleMonthly
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            estimated={flexibleM.estimated}
            realized={flexibleM.realized}
            progressValue={flexibleM.progressValue}
          />
          <CommittedEventual
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            estimated={commitedE.estimated}
            realized={commitedE.realized}
            progressValue={commitedE.progressValue}
          />
          <FlexibleEventual
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            estimated={flexibleE.estimated}
            realized={flexibleE.realized}
            progressValue={flexibleE.progressValue}
          />
        </CardContainer>
      </Home>
      <SpendingTable
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        committedMonthly={getCommittedMonthly()}
        flexibleMonthly={getFlexibleMonthly()}
        committedEventual={getCommittedEventual()}
      />
      <DirecionalButton />
    </>
  );
}
