import styled from 'styled-components';
import { GradientSkeletonAnimation } from 'styles/animations/GradientSkeletonAnimation';

export const StyledAvatarContainer = styled.div`
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  &.background-blue-d2 {
    background-color: ${({ theme }) => theme.blueMidnight};
  }

  &.color-white {
    color: ${({ theme }) => theme.white};
  }

  &.loading {
    color: transparent;
    background: linear-gradient(
      90deg,
      ${({ theme }) => theme.gradientSkeleton[0]} 25%,
      ${({ theme }) => theme.gradientSkeleton[1]} 50%,
      ${({ theme }) => theme.gradientSkeleton[0]} 75%
    );
    background-size: 200% 100%;
    animation: ${GradientSkeletonAnimation} 1.5s ease-in-out infinite;
  }

  &.avatar {
    width: 41px;
    height: 41px;
    font-size: 24px;

    @media (min-width: 2161px) {
      width: 50px;
      height: 50px;
    }

    @media (min-width: 1921px) and (max-width: 2160px) {
      width: 45px;
      height: 45px;
    }

    @media (min-width: 1651px) and (max-width: 1920px) {
      width: 41px;
      height: 41px;
      font-size: 24px;
    }

    @media (min-width: 1501px) and (max-width: 1650px) {
      width: 38px;
      height: 38px;
    }

    @media (min-width: 1281px) and (max-width: 1500px) {
      width: 32px;
      height: 32px;
    }

    @media (max-width: 1280px) {
      width: 30px;
      height: 30px;
    }
  }
`;
