import React from "react";
import { FinancialIndependenceGoal } from "./FinancialIndependenceGoal";
import { GenericDream } from "./GenericDream";
import { EmptyGoal } from "./EmptyGoal";

export const Goal = ({
    type = '',
    ...props
}) => {

    const pages = {
        'financial-independence': <FinancialIndependenceGoal {...props} />,
        'generic-dream': <GenericDream {...props} />,
        'empty-goal': <EmptyGoal {...props} />
    };

    return pages[type] || <></>;
}