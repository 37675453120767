export function formatInputMoney(value) {
  let formattedValue = getOnlyNumbers(value);
  return formatOutputMoney(formattedValue / 100);
}

export const formatOutputMoney = value => {
  return Number(value).toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });
};

export function getOnlyNumbers(value) {
  return Number(String(value).replace(/[^\d]/g, ''));
}

export function getAnualValue(value) {
  let formattedValue = getOnlyNumbers(value);
  formattedValue = formattedValue * 12;
  formattedValue = formatInputMoney(formattedValue);
  return formattedValue;
}

export const getMonthValue = value => {
  let formattedValue = getOnlyNumbers(value);
  formattedValue = formattedValue / 12;
  formattedValue = formatInputMoney(formattedValue);
  return formattedValue;
};

export const getArrayNumbers = (quantity, firstIsZero = true) => {
  let array = [];
  for (let i = 0; i < quantity; i++) {
    array.push(firstIsZero ? i : i + 1);
  }
  return array;
};
export const formatOutputMoneyNoDecimals = value => {
  return Number(value).toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
};
