import React from 'react';
import { Label } from 'components/atoms/Label';
import { Input } from 'components/atoms/Input';
import { Span } from 'components/atoms/Span';
import { formatInputMoney } from 'utils/numbers';

export const ModalInputMoney = ({
  className,
  getInputValue,
  placeholder,
  label,
  defaultValue = '',
  disabled = false,
}) => {
  const [inputValue, setInputValue] = React.useState(
    formatInputMoney(defaultValue)
  );

  React.useEffect(() => {
    getInputValue(inputValue);
  }, [getInputValue, inputValue]);

  const handleChange = e => {
    const { value } = e.target;
    setInputValue(formatInputMoney(value));
  };

  return (
    <Label className={className}>
      <Span className={className}>{label}</Span>
      <Input
        className={`${className} ${disabled ? 'disabled' : ''}`}
        value={inputValue}
        onChange={handleChange}
        placeholder={placeholder}
        disabled={disabled}
      />
    </Label>
  );
};
