import React, { useState } from 'react';
import ReceiptCard from 'components/organisms/ReceiptCard';
import InvestmentCard from 'components/organisms/InvestmentCard';
import { BudgetTable } from 'components/organisms/BudgetTable';
import SpendingsCard from 'components/organisms/SpendingCard';
import DebtsCard from 'components/organisms/DebtsCard';
import DirecionalButton from 'components/organisms/Direcional';
import {
  getDebts,
  getInvestments,
  getReceipts,
  getSpendings,
} from 'development';
import { Page } from 'components/templates/Page';
import { CardContainer } from './styles';

export default function BudgetManagement() {
  const [currentPage, setCurrentPage] = useState('receipts');

  return (
    <Page.RootContainer>
      <CardContainer>
        <ReceiptCard
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
        <InvestmentCard
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
        <SpendingsCard
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
        <DebtsCard currentPage={currentPage} setCurrentPage={setCurrentPage} />
      </CardContainer>
      <BudgetTable
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        spendings={getSpendings()}
        receiptList={getReceipts()}
        investments={getInvestments()}
        debts={getDebts()}
      />
      <DirecionalButton />
    </Page.RootContainer>
  );
}
