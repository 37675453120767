import * as Yup from 'yup';

export const receiptSchema = Yup.object().shape({
  name: Yup.string().required('Nome é obrigatório'),
  classification: Yup.string().required('Classificação é obrigatória'),
  value: Yup.number()
    .transform((value, originalValue) =>
      originalValue.trim() === '' ? null : value
    )
    .typeError('O valor deve ser um número')
    .min(1, 'O valor deve ser maior que zero')
    .required('Valor é obrigatório'),
  inicialDate: Yup.date()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Data de início é obrigatória'),
  description: Yup.string(),
  recurring: Yup.boolean(),
});

export const spendingSchema = Yup.object().shape({
  name: Yup.string().required('Nome é obrigatório'),
  classification: Yup.string().required('Classificação é obrigatória'),
  value: Yup.number()
    .transform((value, originalValue) =>
      originalValue.trim() === '' ? null : value
    )
    .typeError('O valor deve ser um número')
    .min(1, 'O valor deve ser maior que zero')
    .required('Valor é obrigatório'),
  inicialDate: Yup.date()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Data de início é obrigatória'),
  description: Yup.string(),
  recurring: Yup.boolean(),
});

export const debtsSchema = Yup.object().shape({
  name: Yup.string(),
  classification: Yup.string().required('Classificação é obrigatória'),
  value: Yup.number()
    .transform((value, originalValue) =>
      originalValue.trim() === '' ? null : value
    )
    .typeError('O valor deve ser um número')
    .min(1, 'O valor deve ser maior que zero')
    .required('Valor é obrigatório'),
  inicialDate: Yup.date()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Data de início é obrigatória'),
  description: Yup.string(),
  installment: Yup.string().required('Parcelamento é obrigatório'),
});

export const investmentSchema = Yup.object().shape({
  value: Yup.number()
    .transform((value, originalValue) =>
      originalValue.trim() === '' ? null : value
    )
    .typeError('O valor deve ser um número')
    .min(1, 'O valor deve ser maior que zero')
    .required('Valor é obrigatório'),
  inicialDate: Yup.date()
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .required('Data é obrigatória'),
});
