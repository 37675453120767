import styled from 'styled-components';

export const Container = styled.div`
  &.dream-panel {
    display: flex;
    padding-top: ${props => props.theme.getResponsiveHeight(54)};
    padding-right: ${props => props.theme.getResponsiveWidth(38)};
    padding-bottom: ${props => props.theme.getResponsiveHeight(55)};
    padding-left: ${props => props.theme.getResponsiveWidth(38)};
    background: transparent;
    border: ${props => props.theme.getResponsiveWidth(2)} dashed
      ${({ theme }) => theme.grayPale};
    align-items: center;
    justify-content: center;
    border-radius: ${props => props.theme.getResponsiveHeight(16)};
    width: ${props => props.theme.getResponsiveHeight(323)};
    height: ${props => props.theme.getResponsiveHeight(231)};
  }
`;
