import React, { useState } from "react";
import { Modal } from "components/templates/Modal";

export const SimpleAddModal = ({
    showModal = false,
    setShowModal = () => { },
    icon = 'add',
    title = "Adicionar categoria",
    inputPlaceholder = '',
    inputLabel = 'Nome do item',
    dropdownPlaceholder = '',
    classificationOptions = [],
    dropdownLabel = 'Classificação',
    confirmButtonText = 'Adicionar categoria',
    cancelButtonText = 'Concluído',
    onConfirm = () => { },
    onCancel = () => { },
    addNewCategoryKey,
    theme,
}) => {

    const [name, setName] = useState('');
    const [classification, setClassification] = useState('');

    return (
        <Modal.Container
            showModal={showModal}
            setShowModal={setShowModal}
            className="simple-modal edit"
        >
            <Modal.TitleContainer>
                <Modal.SimpleTitleContent
                    title={title}
                    onClose={() => { }}
                    type={icon}
                    className="simple-modal"
                    theme={theme}
                />
            </Modal.TitleContainer>
            <Modal.BodyContainer>
                <Modal.InputText
                    className="simple-modal"
                    placeholder={""}
                    label={inputLabel}
                    getInputValue={setName}
                />
                <Modal.Dropdown
                    className="simple-modal after"
                    placeholderSelect={""}
                    selectOptions={classificationOptions}
                    getDropdownValue={setClassification}
                    label={dropdownLabel}
                />
            </Modal.BodyContainer>
            <Modal.SimpleFooter
                onConfirm={() => {
                    localStorage.setItem(addNewCategoryKey, JSON.stringify({
                        name: name,
                        classification: classification
                    }));
                    onConfirm();
                    setShowModal(false);
                }}
                onCancel={() => {
                    setShowModal(false);
                }}
                className={`simple-modal ${theme}`}
                confirmButtonText={confirmButtonText}
                cancelButtonText={cancelButtonText}
            />
        </Modal.Container>
    );
}