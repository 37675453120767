import React, { useState } from 'react';
import { SimpleTableWithCheckboxAtTheEnd } from 'components/templates/Table/SimpleTableWithCheckboxAtTheEnd';
import { InstallmentPurchaseTable } from 'components/templates/Table/InstallmentPurchaseTable';
import { EditDebtItemModal } from 'components/molecules/BudgetModal/EditDebtItemModal';
import { SimpleDeleteModal } from 'components/molecules/BudgetModal/SimpleDeleteModal';

export const DebtMappingPage = ({ debts }) => {
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [cashPurchases, setCashPurchases] = useState(debts.cashPurchases);

  const selectedItemKey = 'selectedDebtItem';
  const deleteItemKey = 'deletedDebtItem';
  const addItemKey = 'addedDebtItem';
  const editItemKey = 'editedDebtItem';

  const handleDelete = () => {
    const deletedItems = JSON.parse(localStorage.getItem(deleteItemKey));
    const currentItens = cashPurchases.list;
    const newArray = currentItens.filter(item => !deletedItems.includes(item.id));
    setCashPurchases(prev => ({ ...prev, list: newArray }));
    setShowDeleteModal(false);
    localStorage.removeItem(deleteItemKey);
  }

  const deleteUniqueItem = () => {
    const deletedItem = JSON.parse(localStorage.getItem(selectedItemKey));
    const currentItens = cashPurchases.list;
    const newArray = currentItens.filter(item => item.id !== deletedItem.id);
    setCashPurchases(prev => ({ ...prev, list: newArray }));
    setShowDeleteModal(false);
    localStorage.removeItem(selectedItemKey);
  }

  const addNewItem = () => {
    let createdItem = JSON.parse(localStorage.getItem(addItemKey));
    let newItem = {
      id: cashPurchases.list.length + 1,
      body: {
        name: createdItem.name,
        category: createdItem.classification,
        item: createdItem.item,
        totalInstallments: createdItem.totalInstallments,
        installmentValue: createdItem.installmentValue,
        remainingValue: createdItem.remainingValue,
      },
    };
    const currentItens = cashPurchases.list;
    const newArray = [...currentItens, newItem];
    setCashPurchases(prev => ({ ...prev, list: newArray }));
    localStorage.removeItem(addItemKey);
  }

  const editItem = () => {
    const item = JSON.parse(localStorage.getItem(editItemKey));
    const editedItem = {
      id: item.id,
      body: {
        name: item.name,
        category: item.classification,
        item: item.item,
        totalInstallments: item.totalInstallments,
        installmentValue: item.installmentValue,
        remainingValue: item.remainingValue,
      }
    };
    const currentItens = cashPurchases.list;
    const newArray = currentItens.map(item => {
      if (item.id === editedItem.id) {
        return editedItem;
      }
      return item;
    });
    setCashPurchases(prev => ({ ...prev, list: newArray }));
    setShowModal(false);
    localStorage.removeItem(editItemKey);
  }

  return (
    <>
      {showModal && (
        <EditDebtItemModal
          showModal={showModal}
          setShowModal={setShowModal}
          selectedItem={JSON.parse(localStorage.getItem(selectedItemKey))}
          categoriesClassification={cashPurchases.categoriesClassification}
          itensClassification={cashPurchases.itensClassification}
          title="Editar item"
          icon="edit"
          editItemKey={editItemKey}
          onCancel={editItem}
          cancelButtonText='Concluído'
          confirmButtonText='Excluir item'
          onConfirm={deleteUniqueItem}
          theme='debts'
        />
      )}
      {showAddModal && (
        <EditDebtItemModal
          showModal={showAddModal}
          setShowModal={setShowAddModal}
          categoriesClassification={cashPurchases.itemCategories}
          itensClassification={cashPurchases.dropdownItens}
          title="Adicionar item"
          icon="add"
          addItemKey={addItemKey}
          onCancel={addNewItem}
          cancelButtonText='Concluído'
          confirmButtonText='Excluir item'
          theme='debts'
        />
      )}
      {showDeleteModal && (
        <SimpleDeleteModal
          showModal={showDeleteModal}
          setShowModal={setShowDeleteModal}
          text='Excluir item?'
          highlightedText='Esta ação é permanente e não poderá ser desfeita'
          onConfirm={handleDelete}
          cancelButtonText='Cancelar'
          confirmButtonText='Excluir item'
          theme='debts'
        />
      )}
      <InstallmentPurchaseTable
        header={cashPurchases.header}
        body={cashPurchases.list}
        categoryId={cashPurchases.categoryId}
        setShowModal={setShowModal}
        showDeleteModal={showDeleteModal}
        setShowDeleteModal={setShowDeleteModal}
        nameTable={cashPurchases.name}
        totalCategoryPercentage={cashPurchases.totalCategoryPercentage}
        totalCategoryValue={cashPurchases.totalCategoryValue}
        showEditIconOnTitle={false}
        typeCard="debts"
        className="color-debts"
        selectedItemKey={selectedItemKey}
        deleteItemKey={deleteItemKey}
        showAddModal={showAddModal}
        setShowAddModal={setShowAddModal}
      />
      <SimpleTableWithCheckboxAtTheEnd
        header={debts.equityLiabilities.header}
        body={debts.equityLiabilities.list}
        nameTable={debts.equityLiabilities.name}
        totalCategoryPercentage={
          debts.equityLiabilities.totalCategoryPercentage
        }
        totalCategoryValue={debts.equityLiabilities.totalCategoryValue}
        showEditIconOnTitle={false}
        typeCard="debts"
      />
      <SimpleTableWithCheckboxAtTheEnd
        header={debts.financialLiability.header}
        body={debts.financialLiability.list}
        nameTable={debts.financialLiability.name}
        totalCategoryPercentage={
          debts.financialLiability.totalCategoryPercentage
        }
        totalCategoryValue={debts.financialLiability.totalCategoryValue}
        showEditIconOnTitle={false}
        typeCard="debts"
      />
    </>
  );
};
