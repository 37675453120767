import React, { useState } from 'react';
import { Modal } from 'components/templates/Modal';

export const EditPEC = ({
  showModal = false,
  setShowModal = () => {},
  selectedItem = null,
  selectedCategoryKey,
  onConfirm = () => {},
  onCancel = () => {},
  theme,
}) => {
  const [name] = useState(selectedItem?.name);
  const [classification] = useState(selectedItem?.category);
  const [installmentValue, setInstallmentValue] = useState(
    selectedItem?.installmentValue
  );

  const confirm = () => {
    setShowModal(false);
    onConfirm();
  };

  const cancel = () => {
    const currentCategory = JSON.parse(
      localStorage.getItem(selectedCategoryKey)
    );
    currentCategory.name = name;
    currentCategory.classification = classification;
    localStorage.setItem(selectedCategoryKey, JSON.stringify(currentCategory));
    setShowModal(false);
    onCancel();
  };

  return (
    <Modal.Container
      showModal={showModal}
      setShowModal={setShowModal}
      className="simple-modal edit"
    >
      <Modal.TitleContainer>
        <Modal.SimpleTitleContent
          title="Editar PEC"
          onClose={() => setShowModal(false)}
          type="edit"
          className="simple-modal"
          theme={theme}
        />
      </Modal.TitleContainer>
      <Modal.BodyContainer>
        <Modal.InputMoney
          className="simple-modal "
          label="Valor atual da provisão de eventuais comprometidos"
          getInputValue={setInstallmentValue}
          defaultValue={installmentValue}
        />
      </Modal.BodyContainer>
      <Modal.SimpleFooter
        onConfirm={confirm}
        onCancel={cancel}
        className={`simple-modal ${theme}`}
        confirmButtonText="Concluído"
        cancelButtonText="Cancelar"
      />
    </Modal.Container>
  );
};
