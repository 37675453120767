import styled from 'styled-components';

export const P = styled.p`
  &.start-center {
    justify-content: center;
  }

  &.summary-card {
    width: 100%;
    color: ${({ theme }) => theme.grayShadow};
    display: flex;
    font-size: 1rem;
    align-items: center;
    height: 100%;
    text-align: center;
    margin: 0;

    @media (min-width: 2161px) {
      font-size: 0.9rem;
    }

    @media (min-width: 1921px) and (max-width: 2160px) {
      font-size: 0.8rem;
    }

    @media (max-width: 1920px) {
      font-size: 0.75rem;
      line-height: 0.75rem;
    }

    @media (max-width: 1650px) {
      font-size: 0.7rem;
      line-height: 0.7rem;
    }

    @media (max-width: 1500px) {
      font-size: 0.6rem;
      line-height: 0.6rem;
    }

    @media (max-width: 1280px) {
      font-size: 0.4rem;
      line-height: 0.4rem;
    }
  }

  &.dream-panel {
    color: ${({ theme }) => theme.white};
    font-family: ${({ theme }) => theme.fonts.AperturaMedium};
  }

  &.time-to-achieve-dream {
    padding: 0;
    margin: 0;
    margin-bottom: ${props => props.theme.getResponsiveHeight(9)};
    font-size: 1rem;

    @media (max-width: 1650px) {
      font-size: 0.9rem;
    }

    @media (max-width: 1194px) {
      font-size: 0.8rem;
    }
  }

  &.fully-invested-dream {
    font-size: 0.75rem;
    margin: ${props => props.theme.getResponsiveHeight(12)} 0 0 0;

    @media (max-width: 1650px) {
      font-size: 0.7rem;
    }

    @media (max-width: 1500px) {
      font-size: 0.65rem;
    }

    @media (max-width: 1194px) {
      font-size: 0.6rem;
    }
  }

  &.simple-modal {
    height: min-content;
    margin: 0;
    font-size: ${props => props.theme.getResponsiveHeight(22)};
    line-height: ${props => props.theme.getResponsiveHeight(22)};

    &.debts {
      color: ${({ theme }) => theme.redMedium};
    }

    &.receipt {
      color: ${({ theme }) => theme.blueHeaderWithSlowOpacity};
    }

    &.edit {
      color: ${({ theme }) => theme.blueMidnight};
    }

    &.spendings {
      color: ${({ theme }) => theme.blueSky};
    }

    @media (max-width: 1023px){
      font-size: 15.02px;
      line-height: 15.02px;
    }
  }

  &.generic-dream {
    font-size: ${props => props.theme.getResponsiveWidth(22)};
    line-height: ${props => props.theme.getResponsiveWidth(22)};
    color: ${({ theme }) => theme.blueMidnight};
    font-weight: 400;
    margin-bottom: 0;
    margin-left: ${props => props.theme.getResponsiveWidth(14)};
    align-self: center;

    @media (max-width: 1023px){
      font-size: 11.73px;
      line-height: 11.73px;
      margin-left: 7.47px;
    }
  }

  &.filled-suitability-questionnaire {
    font-size: ${props => props.theme.getResponsiveWidth(14)};
    line-height: ${props => props.theme.getResponsiveWidth(18)};
    color: ${({ theme }) => theme.grayShadow};
    width: ${props => props.theme.getResponsiveWidth(564)};
    text-align: justify;

    b {
      font-weight: 700;
      color: ${({ theme }) => theme.grayDark};
    }

    @media (max-width: 1023px){
      font-size: 7.5px;
      line-height: 9.6px;
      width: 300.8px;
    }
  }
`;
