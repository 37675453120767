import { ModalOverlay, ModalContainer } from './styles';
import React from 'react';

export const RootModalContainer = ({
  children,
  showModal,
  setShowModal = () => {},
  className,
  ...rest
}) => {
  const editModal = () => {
    setShowModal(prev => !prev);
  };

  return (
    <>
      <ModalOverlay
        style={{ display: showModal ? 'block' : 'none' }}
        onClick={editModal}
      />
      <ModalContainer showModal={showModal} className={className} {...rest}>
        {children}
      </ModalContainer>
    </>
  );
};
