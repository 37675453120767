import { Card } from 'components/templates/Card';
import { Table } from 'components/templates/Tables';
import React from 'react';

export const FlexibleEventualTable = ({ flexibleMonthly }) => {
  const columnOrder = [
    'item',
    'category',
    'maturity',
    'estimated',
    'realized',
    '  ',
    'balance',
  ];

  return (
    <Card.Container className="spending-table">
      <Table.Title
        nameTable="Eventual Flexível"
        type="years"
        className=" budget year"
      />
      <Table.Container className="responsive">
        <Table.TableBasic className="receipt-table">
          <Table.Thead>
            <Table.Tr className="receipt-table-title spendings">
              {flexibleMonthly.header.map((item, index) => (
                <Table.Th
                  className={`budget-table-title ${index === 0 &&
                    'first-table-border checkbox'}`}
                  first={index === 0}
                  last={index === 6}
                  key={index}
                >
                  {item}
                </Table.Th>
              ))}
              <Table.Th className="budget-table-title empty last-table-border" />
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            {flexibleMonthly.body.map((column, rowIndex) => (
              <Table.Tr className="receipt-table-body" key={rowIndex}>
                {columnOrder.map((item, index) => {
                  let currentItem = column[item];

                  if (item === '  ') {
                    return (
                      <Table.Td
                        className="receipt-table-body spending "
                        key={index}
                      >
                        <Table.Progress
                          className="table-spending"
                          progressPercentage={flexibleMonthly.progress}
                        />
                      </Table.Td>
                    );
                  }

                  if (item === 'balance') {
                    return (
                      <Table.Td
                        className={`receipt-table-body blue-midnight `}
                        key={index}
                      >
                        {currentItem}
                      </Table.Td>
                    );
                  }

                  return (
                    <Table.Td
                      className={`receipt-table-body ${item === 'item' &&
                        'first-table-border checkbox'}`}
                      first={item === 'item'}
                      key={index}
                    >
                      {currentItem}
                    </Table.Td>
                  );
                })}
                <Table.Td></Table.Td>
              </Table.Tr>
            ))}
          </Table.Tbody>
        </Table.TableBasic>
      </Table.Container>
    </Card.Container>
  );
};
