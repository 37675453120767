import styled from 'styled-components';

export const StyledCloseModalIcon = styled.svg`
  &.simple-modal {
    width: ${props => props.theme.getResponsiveWidth(11)};
    height: ${props => props.theme.getResponsiveWidth(11)};
    cursor: pointer;

    &.debts {
      path {
        stroke: ${({ theme }) => theme.redMedium};
      }
    }

    &.receipt {
      path {
        fill: ${({ theme }) => theme.blueHeaderWithSlowOpacity};
        stroke: ${({ theme }) => theme.blueHeaderWithSlowOpacity};
      }
    }

    &.spendings {
      path {
        fill: ${({ theme }) => theme.blueSky};
        stroke: ${({ theme }) => theme.blueSky};
      }
    }

    &.edit {
      fill: ${({ theme }) => theme.blueHeaderWithSlowOpacity};
      path {
        stroke: ${({ theme }) => theme.blueMidnight};
      }
    }
    &.patrimony {
      fill: ${({ theme }) => theme.blueHeaderWithSlowOpacity};
      path {
        stroke: ${({ theme }) => theme.blueMidnight};
      }
    }
    @media (max-width: 1023px) {
      width: 5.87px;
      height: 5.87px;
    }
  }

  &.generic-dream {
    width: ${props => props.theme.getResponsiveWidth(11)};
    height: ${props => props.theme.getResponsiveWidth(11)};
    cursor: pointer;

    path {
      stroke: ${({ theme }) => theme.blueMidnight};
    }

    @media (max-width: 1280px) {
      width: 5.87px;
      height: 5.87px;
    }
  }
`;
