import Checkbox from 'components/atoms/Checkbox';
import { Table } from 'components/atoms/Table';
import { Td } from 'components/atoms/Td';
import { Th } from 'components/atoms/Th';
import { Tr } from 'components/atoms/Tr';
import AddIcon from 'components/atoms/icons/AddIcon';
import TrashIcon from 'components/atoms/icons/TrashIcon';
import { BudgetTableTitle } from 'components/molecules/BudgetTableTitle';
import React, { useState, useEffect } from 'react';
import OptionOverlay from 'components/molecules/OptionOverlay';
import { CardContainer, Container, GroupIcons } from './styles';

export const InstallmentPurchaseTable = ({
  header,
  body,
  setShowModal,
  showDeleteModal,
  setShowDeleteModal,
  nameTable,
  totalCategoryValue,
  totalCategoryPercentage,
  showEditIconOnTitle,
  typeCard,
  className,
  selectedItemKey,
  deleteItemKey,
  setShowAddModal,
}) => {
  const [selectedAll, setSelectedAll] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);

  const columnOrder = [
    'name',
    'category',
    'item',
    'totalInstallments',
    'installmentValue',
    'remainingValue',
  ];

  useEffect(() => {
    if (!selectedAll) {
      setSelectedItems([]);
    } else {
      const auxArray = [];
      body.forEach(item => {
        auxArray.push(item.id);
      });
      setSelectedItems(auxArray);
    }
  }, [selectedAll, body]);

  useEffect(() => {
    const sameItensQuantity = selectedItems.length === body.length;
    const nonZeroItemsQuantity = selectedItems.length > 0;
    setSelectedAll(sameItensQuantity && nonZeroItemsQuantity);
  }, [body.length, selectedItems]);

  useEffect(() => {
    if (body.length === 0) {
      setSelectedAll(false);
    }
  }, [body.length, showDeleteModal]);

  return (
    <CardContainer>
      <BudgetTableTitle
        setShowModal={setShowModal}
        nameTable={nameTable}
        total={totalCategoryValue}
        percentage={totalCategoryPercentage}
        showEditIcon={showEditIconOnTitle}
        className={typeCard}
      />
      <Container>
        <Table className="receipt-table">
          <thead>
            <Tr className="receipt-table-title debts">
              <Th className="budget-table-title checkbox first-table-border">
                <Checkbox
                  checked={selectedAll}
                  onClick={() => setSelectedAll(!selectedAll)}
                />
              </Th>
              {header.map((item, index) => (
                <Th className="budget-table-title" key={index}>
                  {item}
                </Th>
              ))}
              <Th className="budget-table-title empty last-table-border" />
            </Tr>
          </thead>
          <tbody>
            {body.map((column, rowIndex) => (
              <Tr className="receipt-table-body" key={rowIndex}>
                <Td className="receipt-table-body checkbox">
                  <Checkbox
                    checked={selectedItems.includes(column.id)}
                    onClick={() => {
                      if (selectedItems.includes(column.id)) {
                        setSelectedItems(
                          selectedItems.filter(item => item !== column.id)
                        );
                        return;
                      }

                      const auxArray = [...selectedItems];
                      auxArray.push(column.id);
                      setSelectedItems(auxArray);
                    }}
                  />
                </Td>
                {columnOrder.map(item => (
                  <Td className="receipt-table-body" key={item}>
                    {column.body[item]}
                  </Td>
                ))}
                <Td className="receipt-table-body options">
                  <OptionOverlay
                    onClick={() => {
                      setShowModal(prev => !prev);
                      const currentItem = {
                        id: column.id,
                        name: column.body.name,
                        category: column.body.category,
                        item: column.body.item,
                        totalInstallments: column.body.totalInstallments,
                        installmentValue: column.body.installmentValue,
                        remainingValue: column.body.remainingValue,
                      };
                      localStorage.setItem(
                        selectedItemKey,
                        JSON.stringify(currentItem)
                      );
                    }}
                    className={className}
                  />
                </Td>
              </Tr>
            ))}
          </tbody>
        </Table>
      </Container>
      <GroupIcons>
        <AddIcon
          className="receipt-table"
          onClick={() => {
            setShowAddModal(prev => !prev);
          }}
        />
        <TrashIcon
          className="receipt-table"
          onClick={() => {
            localStorage.setItem(deleteItemKey, JSON.stringify(selectedItems));
            setShowDeleteModal(prev => !prev);
          }}
        />
      </GroupIcons>
    </CardContainer>
  );
};
