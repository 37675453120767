import React from "react";
import { DreamCard } from "components/templates/DreamCard";
import { FinancialIndependenceModal } from "components/molecules/FinancialIndependenceModal";

export const FinancialIndependenceGoal = ({
    dreamProps,
    updateDreamStatus = () => { },
    onConfirm = () => { },
}) => {

    const [showModal, setShowModal] = React.useState(false);
    const realized = dreamProps?.realized;

    const editModal = () => {
        setShowModal(prev => !prev);
    }

    const body = {
        realized: (
            <>
                <DreamCard.RootContainer
                    className="goal-panel realized-generic-dream"
                    backgroundImage={dreamProps?.backgroundImage}
                >
                    <DreamCard.Icon icon="round-verified" className="realized-generic-dream" />
                    <DreamCard.Text
                        text={dreamProps?.name}
                        className="goals-panel generic-dream realized"
                    />
                    <DreamCard.GroupItens className="on-center">
                        <DreamCard.Icon
                            icon="pencil"
                            className="financial-independence"
                            onClick={editModal}
                        />
                        <DreamCard.Icon
                            icon="verified"
                            className="realized-dream"
                            onClick={() => updateDreamStatus(dreamProps?.id, !realized)}
                        />
                    </DreamCard.GroupItens>
                </DreamCard.RootContainer>
            </>
        ),
        unrealized: (
            <DreamCard.RootContainer
                className="goal-panel financial-independence"
                backgroundImage={dreamProps?.backgroundImage}
            >
                <DreamCard.GroupItens className="spaced">
                    <DreamCard.Text
                        text={dreamProps?.name}
                        className="goals-panel financial-independence title"
                    />
                    <DreamCard.GroupItens>
                        <DreamCard.Icon
                            icon="pencil"
                            className="financial-independence"
                            onClick={editModal}
                        />
                        <DreamCard.Icon
                            icon="verified"
                            className="financial-independence"
                            onClick={() => updateDreamStatus(dreamProps?.id, !realized)}
                        />
                    </DreamCard.GroupItens>
                </DreamCard.GroupItens>
                <DreamCard.Text
                    text={dreamProps?.timeToAchieve}
                    className="goals-panel financial-independence time-to-achieve"
                />
                <DreamCard.Text
                    text="Base de Plena Liquidez (B.P.L.)"
                    className="goals-panel financial-independence section-title bpl"
                />
                <DreamCard.GroupItens className="spaced">
                    <DreamCard.Text
                        text={dreamProps?.currentBplValue}
                        className="goals-panel financial-independence progress-information money"
                    />
                    <DreamCard.Text
                        text={dreamProps?.finalBplValue}
                        className="goals-panel financial-independence progress-information money"
                    />
                </DreamCard.GroupItens>
                <DreamCard.ProgressBar
                    className="goals-panel"
                    progress={dreamProps?.completeBplPercentage}
                />
                <DreamCard.GroupItens className="spaced">
                    <DreamCard.Text
                        text="Total investido"
                        className="goals-panel financial-independence progress-information percentage"
                    />
                    <DreamCard.Text
                        text={dreamProps?.totalValueOfBplInvested}
                        className="goals-panel financial-independence progress-information percentage"
                    />
                </DreamCard.GroupItens>
                <DreamCard.Text
                    text="Independência Financeira Total"
                    className="goals-panel financial-independence section-title total-financial-independence"
                />
                <DreamCard.GroupItens className="spaced">
                    <DreamCard.Text
                        text={dreamProps?.initialTotalFinancialIndependence}
                        className="goals-panel financial-independence progress-information money"
                    />
                    <DreamCard.Text
                        text={dreamProps?.finalTotalFinancialIndependence}
                        className="goals-panel financial-independence progress-information money"
                    />
                </DreamCard.GroupItens>
                <DreamCard.ProgressBar
                    className="goals-panel"
                    progress={dreamProps?.totalFinancialIndependencePercentagem}
                />
                <DreamCard.GroupItens className="spaced">
                    <DreamCard.Text
                        text="Total investido"
                        className="goals-panel financial-independence progress-information percentage"
                    />
                    <DreamCard.Text
                        text={dreamProps?.financialIndependenceTotalInvested}
                        className="goals-panel financial-independence progress-information percentage"
                    />
                </DreamCard.GroupItens>
            </DreamCard.RootContainer>
        )
    }

    return (
        <>
            {showModal && (
                <FinancialIndependenceModal
                    setShowModal={setShowModal}
                    showModal={showModal}
                    dreamProps={dreamProps}
                    onConfirm={onConfirm}
                />
            )}
            {body[realized ? 'realized' : 'unrealized']}
        </>
    );
}