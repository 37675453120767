import styled from 'styled-components';

export const InvestmentIconContainer = styled.svg`
  width: ${props => props.theme.getResponsiveWidth(41)};
  height: ${props => props.theme.getResponsiveHeight(27)};

  &.report {
    width: ${props => props.theme.getResponsiveWidth(23)};
    height: ${props => props.theme.getResponsiveHeight(15)};
    min-width: 23px;
    min-height: 15px;
  }

  &.selected path {
    fill: ${({ theme }) => theme.white};
  }
`;
