import styled from 'styled-components';

export const EmptyCardRow = styled.div`
  &.summary-card {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: ${({ theme }) => theme.fonts.AperturaMedium};
    font-weight: 450;
    letter-spacing: 0em;
    text-align: center;
    color: ${({ theme }) => theme.grayPale};

    @media (min-width: 2160px) {
      font-size: 1.5rem;
    }

    @media (min-width: 1921px) {
      font-size: 1.3rem;
    }

    @media (max-width: 1920px) {
      font-size: 1.25rem;
    }

    @media (max-width: 1650px) {
      font-size: 0.9rem;
    }

    @media (max-width: 1280px) {
      font-size: 0.8rem;
      padding: 5%;
    }
  }
`;
