import styled from 'styled-components';

export const Card = styled.div`
  &.dream-panel {
    display: flex;
    flex-direction: column;
    background-image: linear-gradient(
        180deg,
        rgba(12, 5, 63, 0) -2.75%,
        rgba(12, 5, 63, 0.5525) 50.46%,
        rgba(12, 5, 63, 0.85) 99.89%
      ),
      url(${props => props.backgroundImage});
    background-size: cover;
    background-position: left;
    justify-content: end;
    border-radius: calc((16 / 1080) * 100vh);
    width: calc((323 / 1920) * 100vw);
    height: calc((231 / 1080) * 100vh);
    padding-left: calc((13 / 1920) * 100vw);
    padding-right: calc((12 / 1920) * 100vw);
    padding-bottom: calc((18 / 1080) * 100vh);
    overflow: hidden;
  }
`;

export const Title = styled.div`
  &.dream-panel {
    display: flex;
    align-items: center;
    gap: calc((16 / 1080) * 100vh);
    overflow: hidden;
  }
`;

export const ProgressBarContainer = styled.div`
  &.dream-panel {
    display: flex;
    justify-content: space-between;
    width: 100%;
    color: ${({ theme }) => theme.white};
    font-size: 0.75rem;
    font-family: ${({ theme }) => theme.fonts.AperturaMedium};
  }
`;

export const GroupItens = styled.div`
  display: flex;
  justify-content: space-between;
`;
