import styled from 'styled-components';

export const SpendingIconContainer = styled.svg`
  width: ${props => props.theme.getResponsiveHeight(38)};
  height: ${props => props.theme.getResponsiveHeight(32)};

  &.report {
    width: ${props => props.theme.getResponsiveWidth(23)};
    height: ${props => props.theme.getResponsiveHeight(18)};
    min-width: 23px;
    min-height: 18px;
  }

  &.selected path {
    fill: ${({ theme }) => theme.white};
  }
`;
