import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import api from 'services/api';
import { toast } from 'react-toastify';
import Loading from 'components/Loading';
import {
  FinancialPlanningMapPdfComponent,
  DirectorCustomerSuccessPdfComponent,
  ManagementPdfComponent,
  EquityCompositionPdfComponent,
  EquitySealingPdfComponent,
  AllocationRecommendationPdfComponent,
  PortfolioLiquidityPdfComponent,
  IncomeTaxPdfComponent,
} from '../PDF';

const PATRIMONIAL_COMPOSITION_TITLES = {
  headerTitle: 'Gestão <br />Patrimonial',
  footerTitle: 'O Mapa do seu Planejamento Financeiro',
  labels: [
    'Composição Patrimonial',
    'Selamento Patrimonial',
    'Recomendação de Alocação',
    'Liquidez da Carteira',
    'Imposto de Renda',
  ],
};

const EQUITY_COMPOSITION = {
  headerTitle: 'Composição <br />Patrimonial',
  footerTitle: 'Gestão Patrimonial',
};

const SEALING = {
  headerTitle: 'Selamento <br />Patrimonial',
  footerTitle: 'Gestão Patrimonial',
};

const ALLOCATION_RECOMMENDATION_COMPOSITION = {
  headerTitle: 'Recomendação<br />de Alocação',
  footerTitle: 'Gestão Patrimonial',
};

const PORTIFOLIO_LIQUIDITY_COMPOSITION = {
  headerTitle: 'Liquidez<br />da Carteira',
  footerTitle: 'Gestão Patrimonial',
};

const DIRECTOR_CUSTOMER_SUCCESS = {
  footerTitle: 'Gestão Patrimonial',
};

const INCOME_TAX_COMPOSITION = {
  headerTitle: 'Imposto<br />de Renda',
  footerTitle: 'Gestão Patrimonial',
};

export default function ActingR1() {
  const [patrimonialReportData, setPatrimonialReportData] = useState({});
  const { id } = useSelector(state => state.user.profile);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await api.get(`/patrimonial_report/${id}`);

        const { status, data } = response;
        if (status !== 200) {
          toast.error('Não há dados para serem exibidos');
        } else {
          setPatrimonialReportData(data);

          setLoading(false);
        }
      } catch (error) {
        console.log(error);
        toast.error('Erro ao carregar os dados, tente novamente mais tarde.');
      }
    };
    fetchData();
  }, [id]);

  if (loading) {
    return <Loading />;
  }
  return (
    <>
      <FinancialPlanningMapPdfComponent data={patrimonialReportData} />
      <DirectorCustomerSuccessPdfComponent data={DIRECTOR_CUSTOMER_SUCCESS} />
      <ManagementPdfComponent data={PATRIMONIAL_COMPOSITION_TITLES} />
      <EquityCompositionPdfComponent
        data={{ ...EQUITY_COMPOSITION, ...patrimonialReportData }}
      />
      <EquitySealingPdfComponent
        data={{ ...SEALING, ...patrimonialReportData }}
      />
      <AllocationRecommendationPdfComponent
        data={{
          ...ALLOCATION_RECOMMENDATION_COMPOSITION,
          ...patrimonialReportData?.longTermStrategy?.allocationRecommendation,
        }}
      />
      <PortfolioLiquidityPdfComponent
        data={{ ...PORTIFOLIO_LIQUIDITY_COMPOSITION, ...patrimonialReportData }}
      />
      <IncomeTaxPdfComponent data={INCOME_TAX_COMPOSITION} />
    </>
  );
}
