import React from 'react';
import { P } from '../../atoms/P';
import {
  StyledSummaryCardRow,
  ContainerSummaryCardRow,
  ContainerShoppingCartIcon,
} from './styles';
import ShoppingCartIcon from 'components/atoms/icons/ShoppingCartIcon';

const SummaryCardRow = ({
  name,
  category,
  value,
  spendingDate,
  loading,
  width,
  height,
}) => {

  if (loading) {
    return (
      <StyledSummaryCardRow>
        <ContainerSummaryCardRow
          className="loading first-part"
          width={width}
          height={height}
        />
        <ContainerSummaryCardRow
          className="loading second-part"
          width={width}
          height={height}
        />
      </StyledSummaryCardRow>
    );
  }

  return (
    <StyledSummaryCardRow>
      <ContainerSummaryCardRow className="first-part">
        <ContainerShoppingCartIcon
          className="summary-card"
          width={width}
          height={height}
        >
          <ShoppingCartIcon className="summary-card" />
        </ContainerShoppingCartIcon>

        <P className="summary-card">
          {`${name} (${category}): `} {value}
        </P>
      </ContainerSummaryCardRow>

      <ContainerSummaryCardRow className="second-part">
        <P className="summary-card start-center">
          {spendingDate}
        </P>
      </ContainerSummaryCardRow>
    </StyledSummaryCardRow>
  );
};

export default SummaryCardRow;
