import styled from 'styled-components';

export const DropdownContainer = styled.div`
  position: relative;
  width: ${props => props.theme.getResponsiveWidth(220)};
`;

export const DropdownTrigger = styled.div`
  gap: ${props => props.theme.getResponsiveWidth(10)};
  background-color: transparent;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.grayShadow};
  border: none;
`;

export const DropdownTriggerLabel = styled.span`
  font-family: ${props => props.theme.fonts.AperturaRegular};
  font-size: ${props => props.theme.getResponsiveHeight(14)};
  line-height: ${props => props.theme.getResponsiveHeight(14)};
  color: ${({ theme }) => theme.graySelectPlaceholder};
  font-weight: 400;
`;

export const DropdownTriggerIcon = styled.span`
  transition: transform 0.2s ease-in-out;
  transform: ${props => (props.isOpen ? 'rotate(180deg)' : 'none')};

  &.map-header-loading {
    display: none;
  }
`;

export const DropdownOptionsList = styled.ul`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: ${({ theme }) => theme.blueRoyal};
  border-radius: ${({ theme }) => theme.getResponsiveHeight(4)};
  list-style: none;
  padding: 0;
  margin: 0;
  display: none;
  max-height: ${props => props.theme.getResponsiveHeight(262)};
  overflow: auto;
  display: ${props => (props.isOpen ? 'block' : 'none')};
  z-index: 1000;
`;

export const DropdownOptionItem = styled.li`
  padding: ${props => props.theme.getResponsiveWidth(5)};
  padding-left: ${props => props.theme.getResponsiveWidth(15)};

  cursor: pointer;

  &:hover {
    background-color: ${props => props.theme.hoover};

    span {
      color: ${props => props.theme.white};
    }
  }
`;

export const DropdownOptionItemLabel = styled.span`
  font-family: ${props => props.theme.fonts.AperturaRegular};
  font-size: ${props => props.theme.getResponsiveHeight(14)};
  line-height: ${props => props.theme.getResponsiveHeight(14)};
  font-weight: 400;
  color: ${props => props.theme.graySilver};
`;
