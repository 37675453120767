import styled from 'styled-components';

export const ProgressBarContainer = styled.div`
  &.dream-panel {
    height: calc((7 / 1080) * 100vh);
    background-color: ${({ theme }) => theme.backgroundDream};
    border: 1px solid
      linear-gradient(
        180deg,
        rgba(12, 5, 63, 0) -2.75%,
        rgba(12, 5, 63, 0.5525) 50.46%,
        rgba(12, 5, 63, 0.85) 99.89%
      );
    border-radius: 10px;
    overflow: hidden;
  }

  &.goals-panel {
    height: calc((7 / 1080) * 100vh);
    background-color: ${({ theme }) => theme.backgroundDream};
    border-radius: calc((50 / 1920) * 100vw);
    overflow: hidden;

    @media (max-width: 1023px) {
      height: 3.74px;
      border-radius: 26.67px;
    }
  }
  &.spending {
    height: calc((8 / 1080) * 100vh);
    background-color: ${({ theme }) => theme.grayProgress};
    border-radius: 10px;
    overflow: hidden;
  }
  &.selected {
    height: calc((8 / 1080) * 100vh);
    background-color: ${({ theme }) => theme.grayLight};
    border-radius: 10px;
    overflow: hidden;
  }

  &.table-spending {
    width: calc((230 / 1920) * 100vw);
    height: calc((7 / 1080) * 100vh);
    background-color: ${({ theme }) => theme.grayProgress};
    border-radius: 10px;
    overflow: hidden;
  }
`;

export const Progress = styled.div`
  &.dream-panel {
    width: ${props => props?.progress || 0}%;
    height: 100%;
    background-color: ${({ theme }) => theme.blueLight};
    border-radius: 0px 10px 10px 0px;
    transition: width 0.3s ease-in-out;
  }

  &.spending {
    width: calc((15 / 1920) * 100vw);
    height: 100%;
    background-color: ${({ theme }) => theme.blueSky};
    border-radius: 0px 10px 10px 0px;
    transition: width 0.3s ease-in-out;
  }
  &.selected {
    width: calc((15 / 1920) * 100vw);
    height: 100%;
    background-color: ${({ theme }) => theme.blueNavy};
    border-radius: 0px 10px 10px 0px;
    transition: width 0.3s ease-in-out;
  }
  &.table-spending {
    width: ${props => props.progress};
    height: 100%;
    background-color: ${({ theme }) => theme.blueSky};
    border-radius: 0px 10px 10px 0px;
    transition: width 0.3s ease-in-out;
  }
`;

export const Container = styled.div`
  &.dream-panel {
    width: 100%;
    margin-top: calc((5 / 1080) * 100vh);
  }
`;
