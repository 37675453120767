import styled from 'styled-components';
import { VerticalScrollbarStyles } from 'styles/components/VerticalScrollbarStyles';
import { GradientSkeletonAnimation } from 'styles/animations/GradientSkeletonAnimation';

export const Container = styled.div`
  width: calc((512 / 1920) * 100vw);
  height: calc((312 / 1080) * 100vh);
  padding: calc((20 / 1920) * 100vw);
  border-radius: calc((16 / 1920) * 100vw);
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.white};

  &.empty {
    justify-content: space-between;
    align-items: center;
  }

  &.default {
    border: none;
  }
`;

export const GroupEmptyCard = styled.div`
  width: 100%;
  height: 100%;
`;

export const SpendingCard = styled.div`
  margin-top: 2%;
  border-radius: calc((12 / 1920) * 100vw);
  background-color: ${({ theme }) => theme.white};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: auto;
  width: 100%;
  height: 100%;
  gap: 4%;

  &.empty {
    border: calc((2 / 1920) * 100vw) solid ${({ theme }) => theme.grayPale};
    border-radius: calc((12 / 1920) * 100vw);
    margin-top: 0;
    justify-content: start;
    height: 100%;
    border: calc((2 / 1920) * 100vw) solid ${({ theme }) => theme.grayPale};
  }

  &.loading {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: calc((10 / 1920) * 100vw);
    margin-bottom: calc((24 / 1080) * 100vh);
    overflow: auto;
    margin-top: calc((18 / 1080) * 100vh);
    height: 100%;
  }

  ${VerticalScrollbarStyles}
`;

export const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;
  margin-top: calc((17 / 1080) * 100vh);
  border-radius: calc((12 / 1920) * 100vw);
  background: linear-gradient(
    90deg,
    ${({ theme }) => theme.gradientSkeleton[0]} 25%,
    ${({ theme }) => theme.gradientSkeleton[1]} 50%,
    ${({ theme }) => theme.gradientSkeleton[0]} 75%
  );
  background-size: 200% 100%;
  animation: ${GradientSkeletonAnimation} 1.5s ease-in-out infinite;
  animation: ${GradientSkeletonAnimation} 4s infinite;
`;
