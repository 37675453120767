import styled from 'styled-components';

export const StyledBiggerLogo = styled.svg`
    &.sidebar {
        width:${props => props.theme.getResponsiveWidth(98)};
        height: ${props => props.theme.getResponsiveHeight(42)};
        transition: ease-in-out 0.5s ease;
        flex: 1;

        path {
            width: ${props => props.theme.getResponsiveWidth(42)};
            height: ${props => props.theme.getResponsiveHeight(42)};
        }
    }
`;