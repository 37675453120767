import styled, { keyframes } from 'styled-components';

const SmoothAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 999;
  display: none;
  overflow: auto;

  filter: blur(4px);
  backdrop-filter: blur(4px);
`;

export const ModalContainer = styled.div`
  &.simple-modal {
    display: ${props => (props.showModal ? 'flex' : 'none')};
    flex-direction: column;
    justify-content: space-between;
    background-color: ${props => props.theme.white};
    z-index: 1000;
    position: fixed;
    left: 50%;
    right: 50%;
    top: 50%;
    bottom: 50%;
    transform: translate(-50%, -50%);
    border-radius: ${props => props.theme.getResponsiveHeight(16)};
    padding: ${props => props.theme.getResponsiveHeight(24)}
      ${props => props.theme.getResponsiveWidth(18)};
    height: fit-content;

    &.delete {
      width: ${props => props.theme.getResponsiveWidth(396)};

      @media (max-width: 1023px) {
        width: 211.2px;
        padding: 16.4px 9.6px;
        border-radius: 10.9px;
      }
    }

    &.edit {
      width: ${props => props.theme.getResponsiveHeight(396)};
    }
  }

  &.generic-dream,
  &.gallery-modal {
    display: ${props => (props.showModal ? 'flex' : 'none')};
    flex-direction: column;
    justify-content: space-between;
    background-color: ${props => props.theme.white};
    z-index: 1000;
    position: fixed;
    left: 50%;
    right: 50%;
    top: 50%;
    bottom: 50%;
    transform: translate(-50%, -50%);
    border-radius: ${props => props.theme.getResponsiveWidth(16)};
    padding: ${props => props.theme.getResponsiveWidth(24)}
      ${props => props.theme.getResponsiveWidth(18)};
    height: fit-content;

    width: max-content;
    animation: ${SmoothAnimation} 0.5s ease-out;

    @media (max-width: 1024px) {
      border-radius: 8.53px;
      padding: 12.8px 9.6px;
    }
  }

  &.gallery-modal {
    scroll-behavior: smooth;
    overflow-y: scroll;
  }
`;
