import styled from 'styled-components';

export const FlexContainer = styled.div`
  &.home {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: end;
    align-items: center;
  }
`;
