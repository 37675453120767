import { RootModalContainer } from './Container';
import { ModalTitleContainer } from './Title';
import { SimpleModalTitleContent } from './SimpleModalTitleContent';
import { RootModalBody } from './ModalBody';
import { HighlightedBodyContent } from './HighlightedBodyContent';
import { SimpleModalFooter } from './SimpleModalFooter';
import { ModalInputText } from './ModalInputText';
import { ModalDropdown } from './ModalDropdown';
import { InputContainer } from './InputContainer';
import { ModalSwitch } from './ModalSwitch';
import { ModalInputMoney } from './ModalInputMoney';
import { ModalInputNumber } from './ModalInputNumber';
import { ModalInputImage } from './ModalInputImage';
import { ModalDatePicker } from './ModalDatePicker';
import { ModalSection } from './ModalSection';
import { ModalFooterWithOneButton } from './ModalFooterWithOneButton';
import { ModalInputPercentage } from './ModalInputPercentage';
import { ModalGalleryImage } from './ModalGalleryImage';
import { ModalFooterThreeButtons } from './ModalFooterThreeButtons';
import { ModalInputMoneyAdd } from './ModalInputMoneyAdd';
import { ModalInputTextAdd } from './ModalInputTextAdd';

export const Modal = {
  Container: RootModalContainer,
  TitleContainer: ModalTitleContainer,
  SimpleTitleContent: SimpleModalTitleContent,
  BodyContainer: RootModalBody,
  HighlightedBodyContent,
  SimpleFooter: SimpleModalFooter,
  InputText: ModalInputText,
  InputNumber: ModalInputNumber,
  InputMoney: ModalInputMoney,
  Dropdown: ModalDropdown,
  GroupInputs: InputContainer,
  Switch: ModalSwitch,
  InputImage: ModalInputImage,
  DatePicker: ModalDatePicker,
  Section: ModalSection,
  FooterWithOneButton: ModalFooterWithOneButton,
  InputPercentage: ModalInputPercentage,
  GalleryImage: ModalGalleryImage,
  FooterThreeButtons: ModalFooterThreeButtons,
  InputMoneyAdd: ModalInputMoneyAdd,
  InputTextAdd: ModalInputTextAdd,
};
