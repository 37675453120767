import React from 'react';
import { Container, TopRowCard } from './styles';
import { H3 } from 'components/atoms/Headings/H3';
import GoIcon from 'components/atoms/icons/GoIcon';
import { Link } from 'components/atoms/Link';
import TooltipIconWithOverlay from '../../atoms/IconInformation';

const TitleCard = ({
  url,
  title,
  tooltipText,
  showToolTip,
  loading,
  className,
  classGo,
}) => {
  return (
    <TopRowCard>
      <Container>
        <H3 className={className}>{title}</H3>
        {showToolTip && (
          <TooltipIconWithOverlay
            tooltipText={tooltipText}
            className={className}
            loading={loading}
          />
        )}
      </Container>
      <Link href={url}>
        <GoIcon className={`${className}`} loading={loading} />
      </Link>
    </TopRowCard>
  );
};

export default TitleCard;
