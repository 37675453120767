import { ReceiptTable } from 'components/templates/Table/ReceiptTable';
import React, { useState } from 'react';
import { ButtonCategory } from 'components/molecules/ButtonCategory';
import { EditRevenueItemModal } from '../../molecules/BudgetModal/EditRevenueItemModal';
import { GenericEditCategoryTableModal } from 'components/molecules/BudgetModal/GenericEditCategoryTableModal';
import { SimpleDeleteModal } from 'components/molecules/BudgetModal/SimpleDeleteModal';
import { SimpleAddModal } from 'components/molecules/BudgetModal/SimpleAddModal';
import { ButtonContainer } from './styles';

export const RecipeMapPage = ({ receipts }) => {
  const [showEditItemModal, setShowEditItemModal] = useState(false);
  const [showEditCategoryModal, setShowEditCategoryModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showSimpleAddModal, setShowSimpleAddModal] = useState(false);
  const [showAddItemModal, setShowAddItemModal] = useState(false);
  const [tableData, setTableData] = useState(receipts.body);

  const selectedCategoryKey = "selectedCategory";
  const selectedItemKey = "selectedReceiptItem";
  const deleteItemKey = "deletedReceiptItem";
  const addItemKey = "addedReceiptItem";
  const addNewCategoryKey = "addedCategory";

  const editCategory = () => {
    const editedCategory = JSON.parse(localStorage.getItem(selectedCategoryKey));

    const newArray = tableData.map(category => {
      if (category.categoryId === editedCategory.categoryId) {
        return {
          ...category,
          name: editedCategory.name,
          classification: editedCategory.classification
        };
      }
      return category;
    });

    setTableData(newArray);
    localStorage.removeItem(selectedCategoryKey);
  }

  const handleDelete = () => {
    const deletedItems = JSON.parse(localStorage.getItem(deleteItemKey));
    const currentBody = tableData;

    const filteredArray = currentBody.map(item => ({
      ...item,
      list: item.list.filter(subItem => !deletedItems.includes(subItem.recipeId))
    }));

    setTableData(filteredArray);
    setShowDeleteModal(false);
    localStorage.removeItem(deleteItemKey);
  }

  const addNewItem = () => {
    let createdItem = JSON.parse(localStorage.getItem(addItemKey));

    let newItem = {
      recipeId: new Date().getTime(),
      body: {
        name: createdItem.name,
        category: createdItem.classification,
        spendingDate: createdItem.maturity,
        value: createdItem.value,
        monthlyValue: createdItem.monthlyValue,
      }
    };

    const currentItensWithSameCateogry = tableData.filter(item => item.categoryId === createdItem.categoryId);
    const currentList = currentItensWithSameCateogry[0].list.filter(item => item.recipeId);
    const newList = [...currentList, newItem];

    const newTableData = tableData.map(item => {
      if (item.categoryId === createdItem.categoryId) {
        return { ...item, list: newList };
      }
      return item;
    });

    setTableData(newTableData);
    localStorage.removeItem(addItemKey);
  }

  const editItem = () => {
    const editedItem = JSON.parse(localStorage.getItem(selectedItemKey));

    const reworkedItem = {
      recipeId: editedItem.recipeId,
      body: {
        name: editedItem.name,
        category: editedItem.classification,
        spendingDate: editedItem.maturity,
        value: editedItem.value,
        monthlyValue: editedItem.monthlyValue,
        recurrent: editedItem.recurrent
      }
    }

    const currentBody = tableData;
    const filteredArray = currentBody.map(item => ({
      ...item,
      list: item.list.map(subItem => {
        if (subItem.recipeId === editedItem.recipeId) {
          return reworkedItem;
        }

        return subItem;
      })
    }));

    setTableData(filteredArray);

    localStorage.removeItem(selectedItemKey);
  }

  const deleteOneItem = () => {
    const editedItem = JSON.parse(localStorage.getItem(selectedItemKey));

    const currentBody = tableData;
    const filteredArray = currentBody.map(item => ({
      ...item,
      list: item.list.filter(subItem => subItem.recipeId !== editedItem.recipeId)
    }));

    setTableData(filteredArray);
    localStorage.removeItem(selectedItemKey);
  }

  const addNewCategory = () => {
    const createdCategory = JSON.parse(localStorage.getItem(addNewCategoryKey));
    let category = {
      categoryId: new Date().getTime(),
      name: createdCategory.name,
      total: 'R$ 0',
      percentage: '0%',
      classification: createdCategory.classification,
      list: []
    };

    setTableData([...tableData, category]);
  }

  const deleteCategory = () => {
    const editedCategory = JSON.parse(localStorage.getItem(selectedCategoryKey));
    const objWithoutSelectedCategory = tableData.filter(category => category.categoryId !== editedCategory.categoryId);
    setTableData(objWithoutSelectedCategory);
  }

  return (
    <>
      {showEditItemModal && (
        <EditRevenueItemModal
          showModal={showEditItemModal}
          setShowModal={setShowEditItemModal}
          selectedItem={JSON.parse(localStorage.getItem(selectedItemKey))}
          onCancel={editItem}
          onConfirm={deleteOneItem}
          selectedItemKey={selectedItemKey}
          theme='receipt'
          classificationOptions={receipts.itemClassification}
        />
      )}
      {showAddItemModal && (
        <EditRevenueItemModal
          showModal={showAddItemModal}
          setShowModal={setShowAddItemModal}
          title='Adicionar item'
          icon='add'
          addItemKey={addItemKey}
          onCancel={addNewItem}
          theme='receipt'
          classificationOptions={receipts.itemClassification}
        />
      )}
      {showEditCategoryModal && (
        <GenericEditCategoryTableModal
          showModal={showEditCategoryModal}
          setShowModal={setShowEditCategoryModal}
          selectedItem={JSON.parse(localStorage.getItem(selectedCategoryKey))}
          selectedCategoryKey={selectedCategoryKey}
          onCancel={editCategory}
          onConfirm={deleteCategory}
          theme='receipt'
          classificationOptions={receipts.categoryClassification}
        />
      )}
      {showDeleteModal && (
        <SimpleDeleteModal
          showModal={showDeleteModal}
          setShowModal={setShowDeleteModal}
          text='Excluir item?'
          highlightedText='Esta ação é permanente e não poderá ser desfeita'
          onConfirm={handleDelete}
          cancelButtonText='Cancelar'
          confirmButtonText='Excluir item'
          theme='receipt'
        />
      )}
      {showSimpleAddModal && (
        <SimpleAddModal
          showModal={showSimpleAddModal}
          setShowModal={setShowSimpleAddModal}
          classificationOptions={receipts.categoryClassification}
          cancelButtonText='Concluído'
          confirmButtonText='Adicionar categoria'
          dropdownLabel='Tipo'
          addNewCategoryKey={addNewCategoryKey}
          onConfirm={addNewCategory}
          theme='receipt'
          icon='add'
        />
      )}
      {tableData.map(
        (item, index) => (
          <ReceiptTable
            categoryId={item.categoryId}
            header={receipts.header}
            body={receipts.body}
            classification={item.classification}
            nameTable={item.name}
            receiptList={item.list.filter(item => item.recipeId)}
            totalPercentage={item.percentage}
            totalSpent={item.total}
            key={index}
            className="color-receipt"
            setShowEditItemModal={setShowEditItemModal}
            setShowEditCategoryModal={setShowEditCategoryModal}
            showDeleteModal={showDeleteModal}
            setShowDeleteModal={setShowDeleteModal}
            setShowSimpleAddModal={setShowAddItemModal}
            saveSelectedCategory={selectedCategoryKey}
            selectedItemKey={selectedItemKey}
            deleteItemKey={deleteItemKey}
            addItemKey={addItemKey}
          />
        )
      )}
      <ButtonContainer>
        <ButtonCategory setShowModal={setShowSimpleAddModal} />
      </ButtonContainer>
    </>
  );
};
