import styled from 'styled-components';
import { GradientSkeletonAnimation } from 'styles/animations/GradientSkeletonAnimation';

export const Container = styled.div`
  &.simple-modal {
    width: ${props => props.theme.getResponsiveHeight(23)};
    height: ${props => props.theme.getResponsiveHeight(23)};

    @media (max-width: 1023px){
      width: 15.7px;
      height: 15.7px;
    }
  }

  &.loading {
    width: ${props => props.theme.getResponsiveHeight(37)};
    height: ${props => props.theme.getResponsiveHeight(37)};
    padding: ${props => props.theme.getResponsiveHeight(6)};
    border-radius: ${props => props.theme.getResponsiveHeight(8)};
    background: linear-gradient(
      90deg,
      ${({ theme }) => theme.gradientSkeleton[0]} 25%,
      ${({ theme }) => theme.gradientSkeleton[1]} 50%,
      ${({ theme }) => theme.gradientSkeleton[0]} 75%
    );
    background-size: 200% 100%;
    animation: ${GradientSkeletonAnimation} 1.5s ease-in-out infinite;

    path {
      fill: none;
    }
  }

  &.delete-generic-dream {
      width: ${({ theme }) => theme.getResponsiveWidth(23)};
      height: ${({ theme }) => theme.getResponsiveWidth(24)};
      border-radius: ${({ theme }) => theme.getResponsiveWidth(4)};
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &:hover {
        background-color: ${({ theme }) => theme.sideBarHover};
      }

      @media (max-width: 1280px) {
        width: 12.27px;
        height: 12.8px;
        border-radius: 2.13px;
      }
    }
`;

export const StyledTrashIcon = styled.svg`
  &.receipt-table {
    width: ${props => props.theme.getResponsiveHeight(37)};
    height: ${props => props.theme.getResponsiveHeight(37)};
    border: 1px solid ${({ theme }) => theme.grayShadow};
    padding: ${props => props.theme.getResponsiveHeight(6)};
    border-radius: ${props => props.theme.getResponsiveHeight(8)};
    fill: ${({ theme }) => theme.grayShadow};
    cursor: pointer;

    path {
      width: ${props => props.theme.getResponsiveHeight(18.86)};
      height: ${props => props.theme.getResponsiveHeight(20.26)};
      fill: ${({ theme }) => theme.grayShadow};
    }

    &:hover {
      background-color: ${({ theme }) => theme.grayOpacity};
      border: none;
      path {
        fill: ${({ theme }) => theme.white};
      }
    }
  }

  &.simple-modal {
    width: ${props => props.theme.getResponsiveHeight(23)};
    height: ${props => props.theme.getResponsiveHeight(23)};
    cursor: pointer;

    path {
      width: ${props => props.theme.getResponsiveHeight(18)};
      height: ${props => props.theme.getResponsiveHeight(23)};
    }

    &.debts {
      path {
        fill: ${({ theme }) => theme.redMedium};
      }
    }

    &.spendings {
      path {
        fill: ${({ theme }) => theme.blueSky};
      }
    }

    &.receipt {
      fill: ${({ theme }) => theme.blueHeaderWithSlowOpacity};
      stroke: ${({ theme }) => theme.blueHeaderWithSlowOpacity};
    }

    @media (max-width: 1023px){
      width: 15.7px;
      height: 15.7px;
    }
  }

  &.loading {
    width: ${props => props.theme.getResponsiveHeight(37)};
    height: ${props => props.theme.getResponsiveHeight(37)};
    padding: ${props => props.theme.getResponsiveHeight(6)};
    border-radius: ${props => props.theme.getResponsiveHeight(8)};
    background: linear-gradient(
      90deg,
      ${({ theme }) => theme.gradientSkeleton[0]} 25%,
      ${({ theme }) => theme.gradientSkeleton[1]} 50%,
      ${({ theme }) => theme.gradientSkeleton[0]} 75%
    );
    background-size: 200% 100%;
    animation: ${GradientSkeletonAnimation} 1.5s ease-in-out infinite;

    path {
      fill: none;
    }
  }

  &.delete-generic-dream {
    width: ${props => props.theme.getResponsiveWidth(18)};
    height: ${props => props.theme.getResponsiveWidth(16)};

    path {
      fill: ${({ theme }) => theme.white};
    }

    @media (max-width: 1280px) {
      width: 9.6px;
      height: 8.53px;
    }
  }
`;
