import Checkbox from 'components/atoms/Checkbox';
import { Table } from 'components/atoms/Table';
import { Td } from 'components/atoms/Td';
import { Th } from 'components/atoms/Th';
import { Tr } from 'components/atoms/Tr';
import AddIcon from 'components/atoms/icons/AddIcon';
import TrashIcon from 'components/atoms/icons/TrashIcon';
import { BudgetTableTitle } from 'components/molecules/BudgetTableTitle';
import React, { useState, useEffect } from 'react';
import OptionOverlay from 'components/molecules/OptionOverlay';
import { CardContainer, Container, GroupIcons } from './styles';

export const ReceiptTable = ({
  header,
  classification,
  nameTable,
  totalPercentage,
  totalSpent,
  receiptList,
  categoryId,
  className,
  setShowEditItemModal,
  showDeleteModal,
  setShowEditCategoryModal,
  setShowDeleteModal,
  setShowSimpleAddModal,
  saveSelectedCategory,
  selectedItemKey,
  deleteItemKey,
  addItemKey,
}) => {
  const [selectedAll, setSelectedAll] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const order = ['name', 'category', 'spendingDate', 'value', 'monthlyValue'];

  useEffect(() => {
    if (!selectedAll) {
      setSelectedItems([]);
    } else {
      const auxArray = [];
      receiptList.forEach(item => {
        auxArray.push(item.recipeId);
      });
      setSelectedItems(auxArray);
    }
  }, [receiptList, selectedAll]);

  useEffect(() => {
    if (receiptList.length === 0) {
      setSelectedAll(false);
    }
  }, [receiptList.length, showDeleteModal]);

  return (
    <>
      <CardContainer>
        <BudgetTableTitle
          setShowModal={setShowEditCategoryModal}
          nameTable={nameTable}
          total={totalSpent}
          percentage={totalPercentage}
          getCategoryInformation={saveSelectedCategory}
          categoryId={categoryId}
          categoryClassification={classification}
        />
        <Container>
          <Table className="receipt-table">
            <thead>
              <Tr className="receipt-table-title receipts">
                <Th className="budget-table-title checkbox first-table-border">
                  <Checkbox
                    checked={selectedAll}
                    onClick={() => setSelectedAll(prev => !prev)}
                  />
                </Th>
                {header.map((item, index) => (
                  <Th className="budget-table-title" key={index}>
                    {item}
                  </Th>
                ))}
                <Th className="budget-table-title empty last-table-border" />
              </Tr>
            </thead>
            <tbody>
              {receiptList.map((income, index) => (
                <Tr className="receipt-table-body" key={index}>
                  <Td className="receipt-table-body checkbox">
                    <Checkbox
                      checked={selectedItems.includes(income.recipeId)}
                      onClick={() => {
                        const currentId = income.recipeId;
                        let newArray = [];

                        if (selectedItems.includes(currentId)) {
                          newArray = selectedItems.filter(
                            item => item !== currentId
                          );
                        } else {
                          newArray = [...selectedItems, currentId];
                        }

                        const sameItensQuantity =
                          newArray.length === receiptList.length;
                        const nonZeroItemsQuantity = newArray.length > 0;

                        setSelectedAll(
                          sameItensQuantity && nonZeroItemsQuantity
                        );
                        setSelectedItems(newArray);
                      }}
                    />
                  </Td>
                  {order.map(item => {
                    const currentItem = income.body[item];

                    return (
                      <Td className="receipt-table-body" key={item}>
                        {currentItem}
                      </Td>
                    );
                  })}
                  <Td className="receipt-table-body options">
                    <OptionOverlay
                      onClick={() => {
                        const selectedItem = {
                          recipeId: income.recipeId,
                          categoryId,
                          name: income.body.name,
                          classification: income.body.category,
                          value: income.body.value,
                          maturity: income.body.spendingDate,
                          monthlyValue: income.body.monthlyValue,
                          recurrent: income.body.recurrent,
                        };
                        localStorage.setItem(
                          selectedItemKey,
                          JSON.stringify(selectedItem)
                        );
                        setShowEditItemModal(prev => !prev);
                      }}
                      className={className}
                    />
                  </Td>
                </Tr>
              ))}
            </tbody>
          </Table>
        </Container>
        <GroupIcons>
          <AddIcon
            className="receipt-table"
            onClick={() => {
              localStorage.setItem(addItemKey, JSON.stringify({ categoryId }));
              setShowSimpleAddModal(prev => !prev);
            }}
          />
          <TrashIcon
            className="receipt-table"
            onClick={() => {
              localStorage.setItem(
                deleteItemKey,
                JSON.stringify(selectedItems)
              );
              setShowDeleteModal(prev => !prev);
            }}
          />
        </GroupIcons>
      </CardContainer>
    </>
  );
};
