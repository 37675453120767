import styled from "styled-components";

export const ModalFooter = styled.div`
  width: 100%;
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${props => props.theme.getResponsiveWidth(32)};

  @media (max-width: 1023px) {
    gap: 17.07px;
  }
`;