import styled from 'styled-components';

export const ModalDatePickerContainer = styled.div`
  &.generic-dream-modal-datepicker {
    margin-top: ${props => props.theme.getResponsiveWidth(32)};
    width: ${props => props.theme.getResponsiveWidth(163)};
    display: flex;
    flex-direction: column;
    text-align: left;
    border-bottom: ${props => props.theme.getResponsiveWidth(1)} solid
      ${props => props.theme.grayShadow};

    &.full-width {
      width: 100% !important;
    }

    &.right {
      margin-left: auto;
    }
    @media (max-width: 1023px) {
      margin-top: 17.07px;
      width: 86.94px;
      border-bottom: 0.53px solid ${props => props.theme.grayShadow};
    }
  }
`;

export const ModalDateContainer = styled.div`
  &.generic-dream-modal-datepicker {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: ${props => props.theme.getResponsiveWidth(33)};
    margin-top: ${props => props.theme.getResponsiveWidth(14)};

    @media (max-width: 1023px) {
      height: 17.6px;
      margin-top: 7.47px;
    }
  }
`;

export const ModalDatePickerContent = styled.div`
  &.generic-dream-modal-datepicker {
    display: flex;
    padding: ${props => props.theme.getResponsiveWidth(8.5)}
      ${props => props.theme.getResponsiveWidth(5)}
      ${props => props.theme.getResponsiveWidth(8.5)}
      ${props => props.theme.getResponsiveWidth(8)};
    width: ${props => props.theme.getResponsiveWidth(73)};
    height: ${props => props.theme.getResponsiveWidth(33)};
    font-family: ${props => props.theme.fonts.AperturaRegular};
    font-size: ${props => props.theme.getResponsiveWidth(16)};
    line-height: ${props => props.theme.getResponsiveWidth(16)};
    font-weight: 400;
    color: ${props => props.theme.graySilver};

    @media (max-width: 1023px) {
      padding: 4.53px 2.67px 4.53px 4.27px;
      width: 38.93px;
      height: 17.6px;
      font-size: 8.53px;
      line-height: 8.53px;
    }
  }
`;
