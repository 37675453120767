import styled from "styled-components";

export const ModalFooterWithOneButtonContainer = styled.div`
    &.generic-dream-modal {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: ${props => props.theme.getResponsiveWidth(38)};

        @media (max-width: 1023px) {
            margin-top: 20.27px;
        }
    }
`;