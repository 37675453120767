import styled from 'styled-components';
import { GradientSkeletonAnimation } from 'styles/animations/GradientSkeletonAnimation';

export const StyledSummaryCardRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-right: 18px;
`;

export const ContainerSummaryCardRow = styled(StyledSummaryCardRow)`
  background-color: ${({ theme }) => theme.grayLight};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  border-radius: 4px;
  height: calc((44 / 1080) * 100vh);
  gap: calc((24 / 1920) * 100vw);
  padding: calc((8.5 / 1080) * 100vh) calc((12 / 1920) * 100vw);

  &.first-part {
    width: 70%;
  }

  &.second-part {
    width: 28%;
  }

  &.loading {
    border-radius: 5px;
    display: flex;
    align-items: center;
    gap: 5rem;
    text-align: center;
    background: linear-gradient(
      90deg,
      ${({ theme }) => theme.gradientSkeleton[0]} 25%,
      ${({ theme }) => theme.gradientSkeleton[1]} 50%,
      ${({ theme }) => theme.gradientSkeleton[0]} 75%
    );
    background-size: 200% 100%;
    animation: ${GradientSkeletonAnimation} 1.5s ease-in-out infinite;
  }

  &.loading.first-part {
    width: 70%;

    @media (max-width: 1700px) {
      width: 68%;
    }

    @media (max-width: 1500px) {
      width: 65%;
    }

    @media (max-width: 1280px) {
      width: 60%;
    }
  }

  &.loading.second-part {
    width: 28%;

    @media (max-width: 1650px) {
      width: 30%;
    }

    @media (max-width: 1500px) {
      width: 32%;
    }

    @media (max-width: 1280px) {
      width: 36%;
    }
  }
`;

export const ContainerShoppingCartIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;

  &.summary-card {
    background-color: ${({ theme }) => theme.blueSky};
    border-radius: calc((4 / 1920) * 100vw);
    padding-top: calc((5.68 / 1080) * 100vh);
    padding-left: calc((3.55 / 1920) * 100vw);
    padding-right: calc((4.26 / 1920) * 100vw);
    padding-bottom: calc((5.68 / 1080) * 100vh);
  }
`;
