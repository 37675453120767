import React from 'react';
import { InvestmentsTable } from 'components/templates/Table/InvestmentsTable';

export const MapInvestmentsPage = ({ investments }) => {
  return (
    <>
      <InvestmentsTable
        header={investments.financialIndependence.header}
        body={investments.financialIndependence.list}
        nameTable="Independência Financeira"
        tooltipText={investments.financialIndependence.definition}
        showTooltip={true}
      />
      <InvestmentsTable
        header={investments.dreams.header}
        body={investments.dreams.list}
        nameTable="Sonhos"
        tooltipText={investments.financialIndependence.definition}
        showTooltip={true}
      />
    </>
  );
};
