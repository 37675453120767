import styled from 'styled-components';

export const StyledContainer = styled.div`
  &.category-title {
    width: min-content;
    height: min-content;
    display: inline-block;
  }

  &.financial-independence {
    width: ${({ theme }) => theme.getResponsiveWidth(23)};
    height: ${({ theme }) => theme.getResponsiveWidth(24)};
    border-radius: ${({ theme }) => theme.getResponsiveWidth(4)};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover {
      background-color: ${({ theme }) => theme.sideBarHover};
    }

    @media (max-width: 1280px) {
      width: 12.27px;
      height: 12.8px;
      border-radius: 2.13px;
    }
  }

  &.simple-modal,
  &.generic-dream {
    height: min-content;
    width: min-content;
    display: flex;
    align-items: center;
  }
`;

export const PencilIconContainer = styled.svg`

  &.th{
    width: ${props => props.theme.getResponsiveHeight(12)};
    height: ${props => props.theme.getResponsiveHeight(14)};
    margin-left: ${props => props.theme.getResponsiveWidth(12)};
    min-width: 10px;
    min-height: 10px;

    path{
      fill: ${({ theme }) => theme.white};
    }
  }
  &.category-title {
    width: ${props => props.theme.getResponsiveHeight(12)};
    height: ${props => props.theme.getResponsiveHeight(14)};
    margin-left: ${props => props.theme.getResponsiveWidth(12)};
    cursor: pointer;
  }

  &.simple-modal {
    width: ${props => props.theme.getResponsiveHeight(19)};
    height: ${props => props.theme.getResponsiveHeight(21)};
    cursor: pointer;

    fill: ${({ theme }) => theme.blueMidnight};

    &.debts {
      fill: ${({ theme }) => theme.redMedium};

      path {
        fill: ${({ theme }) => theme.redMedium};
      }
    }

    &.spendings {
      fill: ${({ theme }) => theme.blueSky};

      path {
        fill: ${({ theme }) => theme.blueSky};
      }
    }

    &.receipt {
      fill: ${({ theme }) => theme.blueHeaderWithSlowOpacity};

      path {
        fill: ${({ theme }) => theme.blueHeaderWithSlowOpacity};
      }
    }
    &.patrimony {
      fill: ${({ theme }) => theme.blueMidnight};

      path {
        fill: ${({ theme }) => theme.blueMidnight};
      }
    }
  }

  &.financial-independence {
    width: ${({ theme }) => theme.getResponsiveWidth(12)};
    height: ${({ theme }) => theme.getResponsiveWidth(14)};
    cursor: pointer;

    path {
      fill: ${({ theme }) => theme.white};
    }

    @media (max-width: 1024px) {
      width: 6.4px;
      height: 7.47px;
    }
  }

  &.generic-dream {
    width: ${({ theme }) => theme.getResponsiveWidth(19)};
    height: ${({ theme }) => theme.getResponsiveWidth(21)};
    cursor: pointer;

    @media (max-width: 1024px) {
      width: 10.13px;
      height: 11.2px;
    }

    &.edit {
      path {
        fill: ${({ theme }) => theme.blueMidnight};
      }
    }
  }
`;
