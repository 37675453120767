import React from 'react';
import { Modal } from 'components/templates/Modal';
import {
  getAllocationStrategyBPL,
  getAllocationStrategyPLF,
} from 'utils/dreams';
import { GalleryModal } from 'components/organisms/GalleryModal';

export const FinancialIndependenceModal = ({
  showModal = '',
  setShowModal = () => {},
  onConfirm = () => {},
  action = 'edit',
  dreamProps,
}) => {
  const [meaning, setMeaning] = React.useState(dreamProps?.meaning || '');
  const [imageDream, setImageDream] = React.useState(
    dreamProps?.backgroundImage || ''
  );
  const [deadline, setDeadline] = React.useState(dreamProps?.deadline || '');
  const [
    totalFinancialIndependenceValue,
    setTotalFinancialIndependenceValue,
  ] = React.useState(dreamProps?.totalValue || '');
  const [totalFullLiquidityBase, setTotalFullLiquidityBase] = React.useState(
    dreamProps?.finalBplValue || ''
  );
  const [
    currentFullLiquidityBase,
    setCurrentFullLiquidityBase,
  ] = React.useState(dreamProps?.currentBplValue || '');
  const [currentFixedAssets, setCurrentFixedAssets] = React.useState(
    dreamProps?.currentFixedAssets || ''
  );
  const [currentFinancialAssets, setCurrentFinancialAssets] = React.useState(
    dreamProps?.currentFinancialAssets || ''
  );
  const [profitability, setProfitability] = React.useState(
    dreamProps?.profitability || ''
  );
  const [allocationStrategyBPL, setAllocationStrategyBPL] = React.useState(
    dreamProps?.allocationStrategyBPL || ''
  );
  const [allocationStrategyPLF, setAllocationStrategyPLF] = React.useState(
    dreamProps?.allocationStrategyPLF || ''
  );
  const [showGaleryModal, setShowGaleryModal] = React.useState(false);

  const handleUpdateDream = () => {
    const updatedDream = {
      ...dreamProps,
      backgroundImage: imageDream,
      meaning,
      deadline,
      finalTotalFinancialIndependence: totalFinancialIndependenceValue,
      finalBplValue: totalFullLiquidityBase,
      currentBplValue: currentFullLiquidityBase,
      currentFixedAssets,
      currentFinancialAssets,
      profitability,
      allocationStrategyBPL,
      allocationStrategyPLF,
    };

    onConfirm(updatedDream);
    setShowModal(false);
  };

  return (
    <>
      {showGaleryModal ? (
        <GalleryModal
          showModal={showGaleryModal}
          setShowModal={setShowGaleryModal}
          getImageValue={setImageDream}
        />
      ) : (
        <Modal.Container
          showModal={showModal}
          setShowModal={setShowModal}
          className="generic-dream"
        >
          <Modal.TitleContainer>
            <Modal.SimpleTitleContent
              title="Editar sonho"
              onClose={() => setShowModal(false)}
              type="edit"
              className="generic-dream edit"
            />
          </Modal.TitleContainer>
          <Modal.BodyContainer className="generic-dream">
            <Modal.Section>
              <Modal.InputText
                label="Nome do sonho"
                getInputValue={() => {}}
                className="generic-dream-modal first"
                defaultValue={dreamProps?.name || ''}
                disabled
              />
              <Modal.InputText
                label="O que significa pra você?"
                getInputValue={setMeaning}
                className="generic-dream-modal after"
                defaultValue={meaning}
              />
              <Modal.InputImage
                className="generic-dream"
                backgroundImage={imageDream}
                getImageValue={setImageDream}
                onClick={() => setShowGaleryModal(true)}
              />
              <Modal.DatePicker
                className="generic-dream-modal-datepicker full-width after"
                label="Prazo"
                getInputValue={setDeadline}
                defaultValue={deadline}
              />
              <Modal.InputMoney
                label="Valor total da Independência Financeira"
                getInputValue={setTotalFinancialIndependenceValue}
                className="generic-dream-modal after"
                defaultValue={totalFinancialIndependenceValue}
              />
              <Modal.GroupInputs show className="generic-dream-modal">
                <Modal.InputMoney
                  label="BPL - Base de Plena Liquidez Total"
                  getInputValue={setTotalFullLiquidityBase}
                  className="generic-dream-modal first half second-section"
                  defaultValue={totalFullLiquidityBase}
                />
                <Modal.InputMoney
                  label="BPL - Base de Plena Liquidez Atual"
                  getInputValue={setCurrentFullLiquidityBase}
                  className="generic-dream-modal first half last second-section"
                  defaultValue={currentFullLiquidityBase}
                />
              </Modal.GroupInputs>
            </Modal.Section>
            <Modal.Section className="separate">
              <Modal.InputMoney
                label="Valor total do portfólio de liberdade financeira:"
                getInputValue={() => {}}
                className="generic-dream-modal after"
                defaultValue={
                  dreamProps?.totalFinancialFreedomPortfolioValue || ''
                }
                disabled
              />
              <Modal.GroupInputs show className="generic-dream-modal">
                <Modal.InputMoney
                  label="PLF - Patrimônio Imobilizado atual"
                  getInputValue={setCurrentFixedAssets}
                  className="generic-dream-modal first half second-section"
                  defaultValue={currentFixedAssets}
                />
                <Modal.InputMoney
                  label="PLF - Patrimônio Financeiro atual"
                  getInputValue={setCurrentFinancialAssets}
                  className="generic-dream-modal first half last second-section"
                  defaultValue={currentFinancialAssets}
                />
              </Modal.GroupInputs>
              <Modal.GroupInputs show className="generic-dream-modal">
                <Modal.InputMoney
                  label="PLF - Valor Atual"
                  getInputValue={() => {}}
                  className="generic-dream-modal first half second-section"
                  defaultValue={dreamProps?.currentPLFValue || ''}
                  disabled
                />
                <Modal.InputPercentage
                  label="Rentabilidade"
                  getInputValue={setProfitability}
                  className="generic-dream-modal first half last second-section"
                  defaultValue={profitability}
                />
              </Modal.GroupInputs>
              <Modal.GroupInputs show className="generic-dream-modal ">
                <Modal.Dropdown
                  label="BPL - Estratégia de alocação"
                  className="generic-dream-modal after half second-section"
                  selectOptions={getAllocationStrategyBPL()}
                  getDropdownValue={setAllocationStrategyBPL}
                  placeholderSelect={allocationStrategyBPL}
                />
                <Modal.Dropdown
                  label="PLF - Estratégia de alocação"
                  className="generic-dream-modal after half last second-section"
                  selectOptions={getAllocationStrategyPLF()}
                  getDropdownValue={setAllocationStrategyPLF}
                  placeholderSelect={allocationStrategyPLF}
                />
              </Modal.GroupInputs>
              <Modal.GroupInputs show className="generic-dream-modal">
                <Modal.InputMoney
                  label="Valor atual do sonho"
                  getInputValue={() => {}}
                  className="generic-dream-modal first half second-section"
                  defaultValue={dreamProps?.totalValue || ''}
                  disabled
                />
                <Modal.InputMoney
                  label="Investimento mensal"
                  getInputValue={() => {}}
                  className="generic-dream-modal first half last second-section"
                  defaultValue={dreamProps?.monthlyInvestment || ''}
                  disabled
                />
              </Modal.GroupInputs>
              <Modal.FooterWithOneButton
                text="Confirmar"
                className="generic-dream-modal"
                onClick={handleUpdateDream}
              />
            </Modal.Section>
          </Modal.BodyContainer>
        </Modal.Container>
      )}
    </>
  );
};
