import React, { useState, useEffect } from 'react';
import { HorizontalBar } from 'react-chartjs-2';
import { Container,
        ContainerColumn,
         PurpleBar,
         ContentWrapper,
         LogoAzul,
         Footer,
         Title } from './styles';

export default function BudgetDistribution({data}) {

  const { headerTitle, footerTitle, distribuicaoOrcamentaria } = data;

  const [ dataGR, setDataGR ] = useState({
    labels: [],
    datasets: [],
  });

  useEffect(() => {
    if(distribuicaoOrcamentaria.length > 0) {
      const dataOrcamentariaTitulo = distribuicaoOrcamentaria
        .map(orcamentaria => orcamentaria.nome);

      const dataOrcamentariaValor = distribuicaoOrcamentaria
        .map(orcamentaria => orcamentaria.valor);

      const dataSet = {
        backgroundColor: '#5960ff',
        borderColor: '#5960ff',
        data: dataOrcamentariaValor,
      }

      setDataGR({
        labels: dataOrcamentariaTitulo,
        datasets: [dataSet]
      })
    }
  }, [distribuicaoOrcamentaria])

  const options = {
    legend: {
      display: false,
    },
    tooltips: {
      enabled: true,
      callbacks: {
        label: function(tooltipItem, data) {
          var dataset = data.datasets[tooltipItem.datasetIndex];
          var currentValue = dataset.data[tooltipItem.index];
          return  " " + currentValue + " %";
        },
        title: function(tooltipItem, data) {
          return data.labels[tooltipItem[0].index];
        }
      }
    },
    plugins: {
      datalabels: {
          formatter: (value) => {
            let percentage = (value).toFixed(2)+"%";
            return percentage;
          },
          color: '#fff',
          backgroundColor: 'rgba(0,0,0,0.6)',
          borderRadius: 4,
          padding: 8,
          left: 50,
          font: {
            family: 'Roboto Condensed',
            weight: 'bold',
            size: 16,
          },
          align: 'end',
      }
    },
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: 'y',
    scales: {
      xAxes: [{
        ticks: {
          beginAtZero: true,
          autoSkip: true,
          fontSize: 20,
          fontStyle: 'bold',
          callback: function(value) {
            return value + "%"
          }
        }
      }],
      yAxes : [{
        ticks: {
          fontSize: 20,
          fontStyle: 'bold',
        },
        gridLines : {
            display : false
        }
      }]
    },
  };

  return (
      <ContentWrapper id="printable" minHeight={1682}>
        <Container padding="0 78px 80px 78px">
          <PurpleBar />
        </Container>
        <Container padding="0 78px">
          <ContainerColumn>
            <Title dangerouslySetInnerHTML={{__html: headerTitle}} />
            <p className="p-smaller">Como estão distribuídos os seus gastos por<br />categoria.</p>
          </ContainerColumn>
        </Container>
        <Container padding="100px 78px 19px 78px">
          <ContainerColumn className="full-content">
            <HorizontalBar data={dataGR} options={options} />
          </ContainerColumn>
        </Container>
        <Footer>
          <Container padding="0 78px">
              <p>{ footerTitle }</p>
              <LogoAzul />
          </Container>
        </Footer>
      </ContentWrapper>
  );
}
