import React from 'react';
import SpendingManagement from './Spending';
import Detail from './Detail';
import Report from './Report';

export default function Management() {
  const [, , choosenSubPath] = window.location.pathname.split('/');

  if (choosenSubPath === 'spending') {
    return <SpendingManagement />;
  }
  if (choosenSubPath === 'detail') {
    return <Detail />;
  }
  if (choosenSubPath === 'report') {
    return <Report />;
  }
}
