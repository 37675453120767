import React from 'react';
import { BodyContainer } from './styles';
import { H3 } from 'components/atoms/Headings/H3';
import { H5 } from 'components/atoms/Headings/H5';

const MapBody = ({ className, height }) => {
  return (
    <BodyContainer height={height}>
      <H5 className={className}>Total estimado</H5>
      <H3 className={className}>R$ 0</H3>
    </BodyContainer>
  );
};

export default MapBody;
