import FinancialIndependence from './fake-images/financialIndependence.jpeg';
import ImagemPraia from './fake-images/praia-img.jpeg';
import Car from './fake-images/car.avif';
import Carroca from './fake-images/carroçaComSOm.jpeg';
import House from './fake-images/house.webp';

export const getGalleryImages = () => {
    let images = [];

    const defaultImages = [
        ImagemPraia,
        Car,
        Carroca,
        ImagemPraia,
        House,
    ]

    for (let i = 0; i < 20; i++) {
        images.push(defaultImages[Math.floor(Math.random() * 5)]);
    }

    return images;
}

export const getGoals = () => {
    return [
        {
            id: 1,
            type: "financial-independence",
            backgroundImage: FinancialIndependence,
            name: "Independência Financeira",
            meaning: "Atingir liberdade financeira",
            noDefinedValue: true,
            deadline: new Date(2030, 11, 31),
            currentFixedAssets: "R$ 500.000,00",
            currentPLFValue: "R$ 100.000,00",
            currentFinancialAssets: "R$ 250.000,00",
            profitability: "7,00% a.m",
            monthlyInvestment: "R$ 5.000,00",
            timeToAchieve: "120 meses",
            currentBplValue: "R$ 0,00",
            finalBplValue: "R$ 1.000.000,00",
            completeBplPercentage: "0%",
            initialTotalFinancialIndependence: "R$ 0,00",
            finalTotalFinancialIndependence: "R$ 1.000.000,00",
            totalFinancialIndependencePercentagem: "0%",
            financialIndependenceTotalInvested: "R$ 0,00",
            realized: false,
            allocationStrategyBPL: "Estrategia 1",
            allocationStrategyPLF: "Estrategia 2",
            totalFinancialFreedomPortfolioValue: "R$ 0,00",
        },
        {
            id: 2,
            type: "generic-dream",
            backgroundImage: ImagemPraia,
            name: "Férias em Miami Julho - 2026",
            meaning: "Viajar com a família",
            noDefinedValue: false,
            deadline: new Date(2026, 6, 1),
            allocationStrategy: "Poupança e renda fixa",
            liquidity: "Média",
            currentFixedAssets: "R$ 10.000",
            currentFinancialAssets: "R$ 5.000",
            currentTotalValue: "R$ 15.000",
            profitability: "2% a.m",
            monthlyInvestment: "R$ 500",
            mimValue: "R$ 0",
            initialValueInvested: "R$ 0",
            goalValue: "R$ 30.000",
            percentageCompleted: "10%",
            totalInvestedValue: "R$ 3.000",
            realized: false,
        },
        {
            id: 3,
            type: "generic-dream",
            backgroundImage: Car,
            name: "Comprar um carro",
            meaning: "Adquirir um carro novo",
            noDefinedValue: false,
            deadline: new Date(2024, 11, 31),
            allocationStrategy: "Financiamento e poupança",
            liquidity: "Baixa",
            currentFixedAssets: "R$ 10.000",
            currentFinancialAssets: "R$ 5.000",
            currentTotalValue: "R$ 15.000",
            profitability: "3% a.m",
            monthlyInvestment: "R$ 1.000",
            mimValue: "R$ 0",
            initialValueInvested: "R$ 0",
            goalValue: "R$ 50.000",
            percentageCompleted: "10%",
            totalInvestedValue: "R$ 5.000",
            realized: false,
        },
        {
            id: 4,
            type: "generic-dream",
            backgroundImage: House,
            name: "Casa própria",
            meaning: "Adquirir uma casa",
            noDefinedValue: false,
            deadline: new Date(2030, 11, 31),
            allocationStrategy: "Financiamento e poupança",
            liquidity: "Baixa",
            currentFixedAssets: "R$ 100.000",
            currentFinancialAssets: "R$ 50.000",
            currentTotalValue: "R$ 150.000",
            profitability: "4% a.m",
            monthlyInvestment: "R$ 2.000",
            mimValue: "R$ 0",
            initialValueInvested: "R$ 0",
            goalValue: "R$ 300.000",
            percentageCompleted: "10,00%",
            totalInvestedValue: "R$ 30.000",
            realized: false,
        },
        {
            id: 5,
            type: "generic-dream",
            backgroundImage: Carroca,
            name: "Comprar carroça chavosa pra dar grau",
            meaning: "Ter uma carroça estilosa",
            noDefinedValue: false,
            deadline: new Date(2025, 5, 15),
            allocationStrategy: "Poupança",
            liquidity: "Alta",
            currentFixedAssets: "R$ 1.000",
            currentFinancialAssets: "R$ 500",
            currentTotalValue: "R$ 1.500",
            profitability: "1% a.m",
            monthlyInvestment: "R$ 200",
            mimValue: "R$ 0",
            initialValueInvested: "R$ 0",
            goalValue: "R$ 5.000",
            percentageCompleted: "10%",
            totalInvestedValue: "R$ 500",
            realized: false,
        },
    ]
}