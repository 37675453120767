import styled from 'styled-components';

export const Container = styled.div`
  width: calc((1056 / 1920) * 100vw);
  height: calc((312 / 1080) * 100vh);
  padding: calc((20 / 1920) * 100vw);
  border-radius: 16px;
  background-color: ${({ theme }) => theme.white};
  display: flex;
  align-items: center;
  box-shadow: 4px 4px 15px 0px ${({ theme }) => theme.boxShadowColor};
`;
