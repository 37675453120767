import React from "react";
import { Modal } from "components/templates/Modal";
import { getAllocationStrategy, getLiquidity } from "utils/dreams";
import { GalleryModal } from "components/organisms/GalleryModal";

export const GenericDreamModal = ({
    showModal = '',
    setShowModal = () => { },
    title = '',
    text = '',
    onConfirm = () => { },
    action = 'edit',
    dreamProps,
}) => {
    const [name, setName] = React.useState(dreamProps?.name || '');
    const [meaning, setMeaning] = React.useState(dreamProps?.meaning || '');
    const [imageDream, setImageDream] = React.useState(dreamProps?.backgroundImage || '');
    const [noDefinedValue, setNoDefinedValue] = React.useState(dreamProps?.noDefinedValue || '');
    const [goalValue, setGoalValue] = React.useState(dreamProps?.goalValue || '');
    const [deadline, setDeadline] = React.useState(dreamProps?.deadline || '');
    const [allocationStrategy, setAllocationStrategy] = React.useState(dreamProps?.allocationStrategy || '');
    const [liquidity, setLiquidity] = React.useState(dreamProps?.liquidity || '');
    const [currentFixedAssets, setCurrentFixedAssets] = React.useState(dreamProps?.currentFixedAssets || '');
    const [currentFinancialAssets, setCurrentFinancialAssets] = React.useState(dreamProps?.currentFinancialAssets || '');
    const [profitability, setProfitability] = React.useState(dreamProps?.profitability || '');
    const [showGaleryModal, setShowGaleryModal] = React.useState(false);

    const handleUpdateDream = () => {

        const updatedDream = {
            ...dreamProps,
            backgroundImage: imageDream,
            name: name,
            noDefinedValue,
            goalValue,
            deadline: deadline,
            allocationStrategy: allocationStrategy,
            liquidity: liquidity,
            currentFixedAssets: currentFixedAssets,
            currentFinancialAssets: currentFinancialAssets,
            profitability: profitability,
            meaning,
        }

        onConfirm(updatedDream);
        setShowModal(false);
    }

    const modalHeader = {
        'edit': (
            <Modal.SimpleTitleContent
                title='Editar sonho'
                onClose={() => setShowModal(false)}
                type='edit'
                className="generic-dream edit"
            />
        ),
        'add': (
            <Modal.SimpleTitleContent
                title='Adicionar sonho'
                onClose={() => setShowModal(false)}
                type='add'
                className="generic-dream add"
            />
        )
    }

    return (
        <>

            {showGaleryModal ? (
                <GalleryModal showModal={showGaleryModal} setShowModal={setShowGaleryModal} getImageValue={setImageDream} />
            ) : (
                <Modal.Container
                    showModal={showModal}
                    setShowModal={setShowModal}
                    className="generic-dream"
                    extended={!noDefinedValue
                    }
                >
                    <Modal.TitleContainer>
                        {modalHeader[action]}
                    </Modal.TitleContainer>
                    <Modal.BodyContainer className="generic-dream">
                        <Modal.Section>
                            <Modal.InputText
                                label="Nome do sonho"
                                getInputValue={setName}
                                className="generic-dream-modal first"
                                defaultValue={name}
                            />
                            <Modal.InputText
                                label="O que significa pra você?"
                                getInputValue={setMeaning}
                                className="generic-dream-modal after"
                                defaultValue={meaning}
                            />
                            <Modal.InputImage
                                className="generic-dream"
                                backgroundImage={imageDream}
                                getImageValue={setImageDream}
                                onClick={() => setShowGaleryModal(true)}
                            />
                            <Modal.GroupInputs show={true} className="not-separate">
                                <Modal.DatePicker
                                    className="generic-dream-modal-datepicker after"
                                    label="Prazo"
                                    getInputValue={setDeadline}
                                    defaultValue={deadline}
                                />
                                <Modal.Switch
                                    label="Sem valor definido"
                                    className="generic-dream-modal after term"
                                    getSwitchValue={setNoDefinedValue}
                                    defaultValue={noDefinedValue}
                                />
                            </Modal.GroupInputs>
                            {!noDefinedValue && (
                                <Modal.InputMoney
                                    label="Valor total"
                                    getInputValue={setGoalValue}
                                    className="generic-dream-modal after"
                                    defaultValue={goalValue}
                                />
                            )}
                        </Modal.Section>
                        {!noDefinedValue && (
                            <Modal.Section className="separate">
                                <Modal.GroupInputs show={true} className="generic-dream-modal">
                                    <Modal.InputMoney
                                        label="Patrimônio Imobilizado atual"
                                        getInputValue={setCurrentFixedAssets}
                                        className="generic-dream-modal first half second-section"
                                        defaultValue={currentFixedAssets}
                                    />
                                    <Modal.InputMoney
                                        label="Patrimônio Financeiro atual"
                                        getInputValue={setCurrentFinancialAssets}
                                        className="generic-dream-modal first half last second-section"
                                        defaultValue={currentFinancialAssets}
                                    />
                                </Modal.GroupInputs>
                                <Modal.InputMoney
                                    label="Valor total atual"
                                    getInputValue={() => { }}
                                    className="generic-dream-modal after second-section"
                                    defaultValue={dreamProps?.currentTotalValue || ''}
                                    disabled
                                />
                                <Modal.GroupInputs show={true} className="generic-dream-modal ">
                                    <Modal.Dropdown
                                        label="Estratégia de alocação"
                                        className="generic-dream-modal after half second-section"
                                        selectOptions={getAllocationStrategy()}
                                        getDropdownValue={setAllocationStrategy}
                                        placeholderSelect={allocationStrategy}
                                    />
                                    <Modal.Dropdown
                                        label="Liquidez"
                                        className="generic-dream-modal after half last second-section"
                                        selectOptions={getLiquidity()}
                                        getDropdownValue={setLiquidity}
                                        placeholderSelect={liquidity}
                                    />
                                </Modal.GroupInputs>
                                <Modal.InputPercentage
                                    label="Rentabilidade"
                                    getInputValue={setProfitability}
                                    className="generic-dream-modal after second-section"
                                    defaultValue={profitability}
                                />
                                <Modal.InputMoney
                                    label="Investimento mensal"
                                    getInputValue={() => { }}
                                    className="generic-dream-modal after second-section"
                                    defaultValue={dreamProps?.monthlyInvestment || ''}
                                    disabled
                                />
                            </Modal.Section>
                        )}
                    </Modal.BodyContainer>
                    <Modal.FooterWithOneButton
                        text="Confirmar"
                        className="generic-dream-modal"
                        onClick={handleUpdateDream}
                    />
                </Modal.Container >
            )}
        </>
    );
}