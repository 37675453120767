import styled from "styled-components";


export const StyledCloudIcon = styled.svg`
         &.sidebar {
          width:${props => props.theme.getResponsiveWidth(42)};
          height: ${props => props.theme.getResponsiveHeight(42)};
           transition: ease-in-out 0.5s ease;
           flex: 1;
           margin-left: ${props => props.theme.getResponsiveWidth(16)};
           cursor: pointer;
           border-radius: ${props => props.theme.getResponsiveWidth(8)};
           margin-top: ${props => props.theme.getResponsiveHeight(20)};
           padding: 0 10%;

           path {
             width: ${props => props.theme.getResponsiveWidth(42)};
             height: ${props => props.theme.getResponsiveHeight(42)};
           }

           &.in-page {
             background-color: ${({ theme }) => theme.sideBarHover};
           }

           &:hover {
             background-color: ${({ theme }) => theme.sideBarHover};
           }
         }

         &.empty-dream {
           width: ${props => props.theme.getResponsiveWidth(95)};
           height: ${props => props.theme.getResponsiveHeight(68)};
         }
       `;