import styled from 'styled-components';

export const StyledSearchIcon = styled.svg`
  &.header {
    width: ${props => props.theme.getResponsiveWidth(22)};
    height: ${props => props.theme.getResponsiveHeight(22)};

    path {
      stroke: ${({ theme }) => theme.inputText};
    }
  }
`;
