import styled from 'styled-components';

export const TopRowCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: calc((31 / 1080) * 100vh);
`;

export const Container = styled.div`
  display: flex;
  height: 100%;
`;
