import styled from 'styled-components';

export const StyledLegend = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (min-width: 2161px) {
    width: 350px;
  }

  @media (min-width: 1921px) and (max-width: 2160px) {
    width: 280px;
  }

  @media (min-width: 1651px) and (max-width: 1920px) {
    width: 250px;
  }

  @media (min-width: 1501px) and (max-width: 1650px) {
    width: 220px;
  }

  @media (min-width: 1281px) and (max-width: 1500px) {
    width: 200px;
  }

  @media (max-width: 1280px) {
    width: 100px;
  }
  &.detail {
    width: calc((500 / 1920) * 100vw);
  }
`;

export const LegendValue = styled.span`
  font-family: ${({ theme }) => theme.fonts.AperturaMedium};
  font-size: 1rem;
  height: ${props => props.loading && '15px'};
  width: ${props => props.loading && '30px'};

  background: ${props =>
    props.loading
      ? `linear-gradient(90deg, ${props.theme.gradientSkeleton[0]} 25%,
    ${props.theme.gradientSkeleton[1]} 50%,
    ${props.theme.gradientSkeleton[0]} 75%)`
      : 'transparent'};
  background-size: ${props => props.loading && '100% auto'};
  border-radius: ${props => props.loading && '5px'};
  animation: ${props =>
    props.loading ? 'GradientSkeletonAnimation 4s infinite' : 'none'};

  &.color-receipt {
    color: ${props =>
      props.loading ? 'transparent' : ({ theme }) => theme.blueMidnight};
  }
  &.color-spending {
    color: ${props =>
      props.loading ? 'transparent' : ({ theme }) => theme.blueSky};
  }
  &.color-investments {
    color: ${props =>
      props.loading ? 'transparent' : ({ theme }) => theme.greenDark};
  }
  &.color-debts {
    color: ${props =>
      props.loading ? 'transparent' : ({ theme }) => theme.redLight};
  }

  @media (min-width: 2161px) {
    font-size: 2rem;
  }

  @media (min-width: 1921px) and (max-width: 2160px) {
    font-size: 1.5rem;
  }

  @media (min-width: 1651px) and (max-width: 1920px) {
    font-size: 1.4rem;
  }
  @media (min-width: 1501px) and (max-width: 1650px) {
    font-size: 1.2rem;
  }

  @media (min-width: 1281px) and (max-width: 1500px) {
    font-size: 1rem;
  }

  @media (max-width: 1280px) {
    font-size: 0.6rem;
  }

  &.detail {
    font-family: ${({ theme }) => theme.fonts.AperturaMedium};
    font-size: calc((32 / 1080) * 100vh);
    color: ${({ theme }) => theme.blueRoyal};
  }
`;
export const LegendText = styled.span`
  font-family: ${({ theme }) => theme.fonts.AperturaRegular};
  color: ${props =>
    props.loading ? 'transparent' : `${({ theme }) => theme.grayMedium}`};
  font-size: 0.8rem;
  height: ${props => props.loading && '15px'};
  width: ${props => props.loading && '50%'};
  background: ${props =>
    props.loading
      ? `linear-gradient(90deg, ${props.theme.gradientSkeleton[0]} 25%,
    ${props.theme.gradientSkeleton[1]} 50%,
    ${props.theme.gradientSkeleton[0]} 75%)`
      : 'transparent'};
  background-size: ${props => props.loading && '200% auto'};
  border-radius: ${props => props.loading && '5px'};
  animation: ${props =>
    props.loading
      ? 'GradientSkeletonAnimation 1.5s ease-in-out infinite'
      : 'none'};

  @media (min-width: 2161px) {
    font-size: 1.5rem;
  }

  @media (min-width: 1921px) and (max-width: 2160px) {
    font-size: 1.5rem;
  }

  @media (min-width: 1651px) and (max-width: 1920px) {
    font-size: 1rem;
  }
  @media (min-width: 1501px) and (max-width: 1650px) {
    font-size: 0.8rem;
  }

  @media (min-width: 1281px) and (max-width: 1500px) {
    font-size: 0.8rem;
  }

  @media (max-width: 1280px) {
    font-size: 0.6rem;
  }
  &.detail {
    font-family: ${({ theme }) => theme.fonts.AperturaRegular};
    font-size: calc((18 / 1080) * 100vh);
    color: ${({ theme }) => theme.grayMedium};
  }
`;
