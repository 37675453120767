import styled, { css } from 'styled-components';
import LogoAzulImage from 'assets/logo-azul.png'

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 1170px;
  height: 845px;
  background: #FFF;
  -webkit-print-color-adjust: exact;
  margin: 0 auto;

  ${({ minHeight }) =>
      minHeight !== undefined &&
      css`
        min-height: ${ minHeight + 'px'} ;
      `
  }

  ${({ minHeight }) =>
        minHeight === undefined &&
        css`
          min-height: auto;
        `
    }

  h1, h2, h3, h4, h5, h6, p, span, b {
    font-family: 'Poppins', sans-serif !important;
  }

`

export const ContainerColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  padding: ${props => props.padding !== undefined ? props.padding: 0};
  height: ${props => props.height !== undefined ? props.height : 'auto'};

  > div.half {
    width: 50%;
  }

  > div.d-40w {
    width: 38%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  > div.d-60w {
    width: 62%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .header-title {
    height: auto;
    min-height: 60px;

    small {
      font-size: 7px !important;
      font-style: italic;
    }
  }

  .p-smaller {
    font-size: 20px;
    line-height: 37px;
    font-weight: 500;
  }

  .p-smaller-no-mg {
    font-size: 10px;
    line-height: 12px;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 0;
  }

  .half-content {
    display: flex;
    flex-direction: column;
    height: auto;
    min-height: 410px;
    margin-top: 62px;

    > div {
      margin-bottom: 120px;
    }

    &:not(.normal) {
      justify-content: space-between;
    }
    /* border: solid 1px #EEE; */

  }

  .full-content {
    height: 460px;
    width: 100%;
    /* border: solid 1px #EEE; */
  }

  &.split {
    gap: 6px;
  }

  &.split-2 {
    margin-top: auto;
    gap: 4px;
  }

  .d-purple {
    display: inline-block;
    font-size: 10px;
    padding: 2px 8px;
    font-weight: bold;
    text-align: center;
    background: #5560ff;
    color: #FFFFFF;
    width: fit-content;
  }
  .d-gray {
    font-size: 10px;
    padding: 6px 20px;
    font-weight: bold;
    text-align: center;
    background: #f1f1f1;
    border-left: solid 3px #5560ff;
    color: #FFFFFF;
    color: #2d2d2d;
  }
`;

export const PurpleBar = styled.div`
  width: 378px;
  height: 40px;
  background: #5560ff;

  &.full-height {
    background: #6b87ff;
    height: 100%;
    padding: 394px 38px 0 38px;
  }

  color: #FFFFFF;

  h1 {
    font-size: 40px;
    font-weight: 800;
    letter-spacing: 0.5px;
  }

  h2 {
    font-size: 27px;
    margin-bottom: 0;
    font-weight: 400;
    letter-spacing: 0.5px;
  }

  p {
    font-size: 17px;
    font-weight: 400;
  }
`

export const Title = styled.h1`
  text-transform: uppercase;
  font-size: 58px;
  font-weight: 800;
  line-height: 66px;
  margin-bottom: 0;
  padding: 0;
  margin-top: -2px;
`

export const SubTitle = styled.h2`
  font-size: 50px;
  font-weight: 600;
  margin-bottom: 20px;
  margin-top: 10px;
  padding: 0;
  color: #5259ff;

  &.bigger {
    font-size: 40px;
    font-weight: 800;
    margin-top: 40px;
  }

  &.uppercase {
    text-transform: uppercase;
  }

  sup {
    font-size: 20px;
  }
`

export const GrayBar = styled.div`
  position: relative;
  width: 540px;
  height: 34px;
  background: #dbdbdb;
  border-radius: 38px;

  div {
    position: absolute;
    color: #dbdbdb;
    font-family: 'Poppins', sans-serif !important;
    font-weight: 600;
    font-size: 24px;
    line-height: 70px;
    top: 30px;
    width: 80px;
    text-align: center;
    &:not(.valor):after {
      position: absolute;
      content: '';
      width: 4px;
      height: 50px;
      background: #dbdbdb;
      top: -38px;
      left: 50%;
    }
  }

  div.valor {
    font-size: 40px;
    width: auto;
    right: 0;
  }

  div.step-10 {
    left: calc(8% - 40px);
  }

  div.step-50 {
    left: calc(48% - 40px);
  }

  &:after {
    content: '';
    text-align: center;
    position: absolute;
    width: inherit;
    height: 34px;
    color: #ffffff;
    font-weight: bold;
    line-height: 22px;
    border-radius: 20px;

    ${({ progress }) =>
      progress !== undefined &&
      css`
        width: ${progress > 100 ? 100 : progress }%;
        background: ${progress > 100 ? "#c60938" : "#5960ff" };
      `}
  }

  &.indiceCompras:after {
    font-size: 24px;
    line-height: 32px;
    ${({ progress }) =>
      progress !== undefined &&
      css`
        content: '${progress > 100 ? progress + "%": '' }';
      `}
  }
`;


export const LogoAzul = styled.div`
  width: 60px;
  height: 54px;
  background: url(${LogoAzulImage});
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 15px;
`

export const Footer = styled.div`
  width: 100%;
  height: 130px;
  margin-top: auto;
  border-top: solid 6px #2d2d2d;

  > div {
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  p {
    margin: 0;
    padding: 0;
    font-size: 23px;
    font-weight: 600;
  }
`



