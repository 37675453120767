import React from 'react';
import { HomeHeader } from './Home';
import { GoalsPanelHeader } from './GoalsPanelHeader';
import { AssetManagementHeader } from './AssetManagementHeader';
import { MapHeaderBasic } from './MapHeaderBasic';
import { RetirementHeader } from './RetirementHeader';

export const Header = () => {
  const { pathname } = window.location;

  const headersGroups = {
    '/home': <HomeHeader />,
    '/goals/goalPanel': <GoalsPanelHeader />,
    '/finances/patrimony/planning': <AssetManagementHeader />,
    '/finances/patrimony/map': <AssetManagementHeader />,
    '/finances/patrimony/act': <AssetManagementHeader />,
    '/finances/budget': <MapHeaderBasic />,
    '/management/spending': <MapHeaderBasic />,
    '/management/detail': <MapHeaderBasic />,
    '/management/report': <MapHeaderBasic />,
    '/retirement/map': <RetirementHeader />,
    '/retirement/planning': <RetirementHeader />,
  };

  return headersGroups[pathname] || <></>;
};
