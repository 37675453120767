import styled from 'styled-components';
import React from 'react';
import { GradientSkeletonAnimation } from 'styles/animations/GradientSkeletonAnimation';

const StyledH3 = ({
  color,
  fontSize,
  loading,
  text,
  marginTop,
  textAlign,
  width,
  height,
  margin,
}) => {
  if (loading) {
    return <></>;
  }

  const config = {
    color,
    fontSize,
    loading,
    textAlign,
    width,
    height,
    margin,
  };

  return <h3 {...config}>{text}</h3>;
};
export default StyledH3;

export const H3 = styled.h3`
  &.home {
    color: ${({ theme }) => theme.blueMidnight};
    font-weight: 450;
    font-size: 1.25rem;

    @media (max-width: 1650px) {
      font-size: 1.2rem;
    }
    @media (max-width: 1500px) {
      font-size: 1rem;
    }
    @media (max-width: 1280px) {
      font-size: 0.8rem;
    }
  }

  &.home-loading {
    width: 217px;
    height: 17px;
    background: linear-gradient(
      90deg,
      ${({ theme }) => theme.gradientSkeleton[0]} 25%,
      ${({ theme }) => theme.gradientSkeleton[1]} 50%,
      ${({ theme }) => theme.gradientSkeleton[0]} 75%
    );
    background-size: 200% 100%;
    animation: ${GradientSkeletonAnimation} 1.5s ease-in-out infinite;
    border-radius: 50px;
    color: transparent;
  }

  &.selected {
    color: ${({ theme }) => theme.white};
    font-size: ${({ theme }) => theme.getResponsiveHeight(40)};
    font-family: ${({ theme }) => theme.fonts.AperturaBold};
  }

  &.receipts {
    color: ${({ theme }) => theme.blueNavy};
    font-size: ${({ theme }) => theme.getResponsiveHeight(40)};
    font-family: ${({ theme }) => theme.fonts.AperturaBold};
  }
  &.spending {
    color: ${({ theme }) => theme.blueSky};
    font-size: ${({ theme }) => theme.getResponsiveHeight(40)};
    font-family: ${({ theme }) => theme.fonts.AperturaBold};
  }
  &.investments {
    color: ${({ theme }) => theme.greenDark};
    font-size: ${({ theme }) => theme.getResponsiveHeight(40)};
    font-family: ${({ theme }) => theme.fonts.AperturaBold};
  }

  &.debts {
    color: ${({ theme }) => theme.redMedium};
    font-size: ${({ theme }) => theme.getResponsiveHeight(40)};
    font-family: ${({ theme }) => theme.fonts.AperturaBold};
  }

  &.passive {
    margin-top: ${({ theme }) => theme.getResponsiveWidth(6)};
    font-size: ${({ theme }) => theme.getResponsiveWidth(40)};
    line-height: ${({ theme }) => theme.getResponsiveWidth(40)};
    color: ${({ theme }) => theme.redMedium};
    font-weight: 700;
    font-family: ${({ theme }) => theme.fonts.AperturaMedium};

    &.blue {
      color: ${({ theme }) => theme.blueRoyal};
    }

    @media (max-width: 1023px) {
      margin-top: 2px;
      font-size: 21px;
      line-height: 21px;
    }
  }
  &.selected-patrimony {
    margin-top: ${({ theme }) => theme.getResponsiveWidth(6)};
    font-size: ${({ theme }) => theme.getResponsiveWidth(40)};
    line-height: ${({ theme }) => theme.getResponsiveWidth(40)};
    color: ${({ theme }) => theme.white};
    font-weight: 700;
    font-family: ${({ theme }) => theme.fonts.AperturaMedium};

    @media (max-width: 1023px) {
      margin-top: 2px;
      font-size: 21px;
      line-height: 21px;
    }
  }

  &.goal-panel {
    margin-top: ${({ theme }) => theme.getResponsiveWidth(6)};
    font-size: ${({ theme }) => theme.getResponsiveWidth(40)};
    line-height: ${({ theme }) => theme.getResponsiveWidth(40)};
    color: ${({ theme }) => theme.blueRoyal};
    font-weight: 700;
    font-family: ${({ theme }) => theme.fonts.AperturaMedium};

    &.hovered {
      color: ${({ theme }) => theme.white};
    }

    @media (max-width: 1023px) {
      margin-top: 2px;
      font-size: 21px;
      line-height: 21px;
    }
  }
`;
