import styled from 'styled-components';

export const Container = styled.div`
  padding: calc((20 / 1920) * 100vw);
  border-radius: 16px;
  background-color: ${({ theme }) => theme.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 4px 4px 15px 0px ${({ theme }) => theme.boxShadowColor};
  width: calc((512 / 1920) * 100vw);
  height: calc((182 / 1080) * 100vh);
`;

export const ContainerGrafic = styled.div`
  &.home {
    width: 100%;
    display: flex;
    height: 80%;
  }
`;
