import React, { useState } from 'react';
import { Button, Container } from './styles';
import ArrowLeftIcon from 'components/atoms/icons/ArrowLeftIcon';
import ArrowRightIcon from 'components/atoms/icons/ArrowRightIcon';
import { getMonthsWithYears, getYears } from 'utils/date';

export const ButtonDate = ({ type, className }) => {
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();

  const months = getMonthsWithYears(currentYear - 10, currentYear + 10);
  const years = getYears(currentYear - 10, currentYear + 10);

  const currentMonthWithYear = `${
    months[currentMonth].split(',')[0]
  }, ${currentYear}`;
  const initialMonthIndex = months.indexOf(currentMonthWithYear);
  const initialYearIndex = years.indexOf(currentYear.toString());

  const data = type === 'month' ? months : years;
  const initialIndex = type === 'month' ? initialMonthIndex : initialYearIndex;

  const [currentIndex, setCurrentIndex] = useState(initialIndex);

  const handleNext = () => {
    setCurrentIndex(prevIndex => (prevIndex + 1) % data.length);
  };

  const handlePrevious = () => {
    setCurrentIndex(prevIndex => (prevIndex - 1 + data.length) % data.length);
  };

  return (
    <Container>
      <Button className={className}>
        <ArrowLeftIcon onClick={handlePrevious} className="blue-midnight" />
        {data[currentIndex]}
        <ArrowRightIcon onClick={handleNext} />
      </Button>
    </Container>
  );
};
