import React, { useState, useEffect } from 'react';

import { useDispatch } from 'react-redux';

import { externalAcess } from 'store/modules/auth/actions';

import Loading from 'components/Loading';

import history from 'services/history';

import oldApi from 'services/api';

import api from 'services/newApi';
import { Container } from './styles';

export default function ExternalAcess() {
  const [err, setErr] = useState(false);
  const dispatch = useDispatch();
  const getRedirect = profile => {
    if (profile.type === 'assistant-planner') return '/finances/budget';
    return '/home';
  };
  useEffect(() => {
    const url = new URLSearchParams(window.location.search);
    const token = url.get('token');
    const user_id = url.get('user_id');
    const external_user_id = url.get('external_user_id');

    async function fetchData() {
      try {
        const { data } = await oldApi.post('/external_acess', {
          token,
          user_id,
          external_user_id,
        });

        dispatch(
          externalAcess({
            user: data.user,
            external_user: data.external_user,
          })
        );

        oldApi.defaults.headers.Authorization = `Bearer ${token}`;
        api.defaults.headers.Authorization = `Bearer ${token}`;
        const path = getRedirect(data.external_user.profile);
        history.push(path);
      } catch (err) {
        setErr(true);
      }
    }
    fetchData();
  }, [dispatch]);

  return <Container>{err ? <h2>Acesso negado</h2> : <Loading />}</Container>;
}
