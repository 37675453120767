import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { FaSpinner } from 'react-icons/fa';
import { Content, TotalContainer } from '../styles';
import { DivButtons, Body, DivSwitch } from './styles';
import { RiAddLine } from 'react-icons/ri';
import Switch from 'react-switch';
import UpdateModal from './UpdateModal';
import Button from 'components/Button';
import TotalTable from 'components/Tables/TotalTable';
import Modal from 'components/Modal';
import Input from 'components/Input';
import Dropdown from 'components/Input/Dropdown';
import DropdownDiv from 'components/Button/Dropdown';
import Can from 'components/Can';
import { CurrencyInput } from 'components/masks';
import api from 'services/api';
import 'tippy.js/dist/tippy.css'
import { AiOutlineDelete } from 'react-icons/ai';
import { ThemeContext } from 'styled-components';
import Loading from 'components/Loading';

export default function ActivePatrimony({ dataActive, setDataActive }) {
  // Dados do formulário
  const [name, setName] = useState('');
  const [classification, setClassification] = useState('');
  const [categorization, setCategorization] = useState('');
  const [liquidity, setLiquidity] = useState('');
  const [privatePension, setPrivatePension] = useState(false);
  const [value, setValue] = useState(0);

  const [toggleButton, setToggleButton] = useState('');

  // Toggle para mostrar modal
  const [showModal, setShowModal] = useState(false);

  // Toggle para modal de update
  const [showModalUpdate, setModalUdpate] = useState(false);

  // Toggle para modal de delete
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [selectedActive, setSelectedActive] = useState({});

  const [loading, setLoading] = useState(false);

  const theme = useContext(ThemeContext);

  const [selectedItemId, setSelectedItemId] = useState(0);

  const [loadingComponent, setLoadingComponent] = useState('');


  function headTable(headType) {
    if (headType === 'financial-active') {
      return [
        <div style={{ textAlign: 'start' }}>Nome</div>,
        'Categoria',
        'Selável',
        'Previdência Privada',
        'Liquidez',
        'Valor',
        ''];
    }

    return [
      <div style={{ textAlign: 'start' }}>Nome</div>,
      'Tipo',
      'Selável',
      'Valor',
      '',
    ];
  }

  const optionsDropdown = [
    { value: 'moradia', label: 'Moradia' },
    { value: 'imóvel de Investimento', label: 'Imóvel de Investimento' },
    { value: 'transporte', label: 'Transporte' },
    { value: 'outros', label: 'Outros' },
  ];

  const optionsliq = [
    { value: 'DO', label: 'DO' },
    { value: 'Até 30 dias', label: 'Até 30 dias' },
    { value: 'De 1 à 6 meses', label: 'De 1 à 6 meses' },
    { value: 'De 6 à 12 meses', label: 'De 6 à 12 meses' },
    { value: 'De 12 à 24 meses', label: 'De 12 à 24 meses' },
    { value: 'Acima de 24 meses', label: 'Acima de 24 meses' },
  ];

  const optionsCategories = [
    { value: 'R. Fixa Pós', label: 'R. Fixa Pós' },
    { value: 'R. Fixa Pré', label: 'R. Fixa Pré' },
    { value: 'R. Fixa IPCA', label: 'R. Fixa IPCA' },
    { value: 'Multimercado', label: 'Multimercado' },
    { value: 'FIIs', label: 'FIIs' },
    { value: 'Ações BR', label: 'Ações BR' },
    { value: 'R.V. Exterior', label: 'R.V. Exterior' },
    { value: 'Alternativo', label: 'Alternativo' },
  ];

  const privatePensionOptions = [
    { value: true, label: 'Sim' },
    { value: false, label: 'Não' },
  ];

  const { id } = useSelector(state => state.user.profile);

  async function handleDeleteActive(active_id) {
    try {
      setLoading(true);
      await api.delete(`/active_patrimonies/${active_id}`);

      setDataActive(dataActive.filter(active => active.id !== active_id));
      setLoading(false);
      setSelectedItemId(0)
      toast.success('Ativo excluido com sucesso');
    } catch (err) {
      setSelectedItemId(0)

      setLoading(false);
      toast.error('Falha ao excluir ativo');
    }
  }
  async function handleUpdate(active_id, properties) {
    try {

      const component = properties.hasOwnProperty('sealable') ? 'sealable' : 'privatePension';
      setLoadingComponent(component);

      const { data } = await api.put(`/active_patrimonies/${active_id}`, { ...properties });

      setDataActive(dataActive.map(active => {
        if (active_id === active.id) {
          return data;
        } return active;
      }));
      setLoadingComponent('');
      toast.success('Ativo editado com sucesso');
    } catch (err) {
      setLoadingComponent('');
      toast.error('Falha ao editar ativo');
    }
  }
  const dataBodyFinancial = dataActive
    .filter(active => active.type === 'financial-active')
    .map(active => [

      <div
        className={active.name.includes('Investimento') ? "investment" : ""}
        style={{
          textAlign: 'start',
          marginLeft: '20px',
          color: active.name.includes('Investimento') ? theme?.investments.darker : '',

        }}
      >
        {active?.name}
      </div>,
      active.categorization,
      <DivSwitch>
        {
          loadingComponent === 'sealable' && selectedItemId === active.id ? (<Loading />) :

            <Switch
              onChange={async () => {
                setSelectedItemId(active.id);
                await handleUpdate(active.id, { sealable: !active.sealable })
              }}
              checked={active.sealable}
              offColor={theme.grafit.default}
              onColor={theme.info.default}
              onHandleColor={theme.info.hover}
              handleDiameter={25}
              uncheckedIcon={false}
              checkedIcon={false}
              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
              height={16}
              width={45}
            />}
      </DivSwitch>,
      <DivSwitch>
        {
          loadingComponent === 'privatePension' && selectedItemId === active.id ? (<Loading />) :

            <Switch
              onChange={async () => {
                setSelectedItemId(active.id);
                await handleUpdate(active.id, { privatePension: !active.private_pension })
              }}
              checked={active.private_pension}
              offColor={theme.grafit.default}
              onColor={theme.info.default}
              onHandleColor={theme.info.hover}
              handleDiameter={25}
              uncheckedIcon={false}
              checkedIcon={false}
              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
              height={16}
              width={45}
            />}
      </DivSwitch>,

      active.liquidity,
      {
        value: active?.value.toLocaleString('pt-br', {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }),
        currency: 'R$',
      },

      <Can canRoles={['planner', 'adm', 'cs', 'clientPfg']}>
        <DropdownDiv color="grafit" variant="hover">
          <button
            onMouseDown={() => {
              setModalUdpate(true);
              setSelectedActive(active);
            }}
          >
            Editar
          </button>
          <button
            onMouseDown={() => {
              setShowDeleteModal(!showDeleteModal);
              setSelectedActive(active?.id);
            }}
          >
            Excluir
          </button>
        </DropdownDiv>
      </Can>,
    ])


  const dataBody = dataActive
    .filter(active => active.type === 'patrimonial-active')
    .map(active => [

      <div
        style={{
          textAlign: 'start',
          marginLeft: '20px',
        }}
      >
        {active?.name}
      </div>,
      active.classification,

      <DivSwitch>
        {
          loadingComponent === 'sealable' && selectedItemId === active.id ? (<Loading />) :

            <Switch
              onChange={async () => {
                setSelectedItemId(active.id);
                await handleUpdate(active.id, { sealable: !active.sealable })
              }}
              checked={active.sealable}
              offColor={theme.grafit.default}
              onColor={theme.info.default}
              onHandleColor={theme.info.hover}
              handleDiameter={25}
              uncheckedIcon={false}
              checkedIcon={false}
              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
              height={16}
              width={45}
            />}
      </DivSwitch>,
      {
        value: active?.value.toLocaleString('pt-br', {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }),
        currency: 'R$',
      },



      <Can canRoles={['planner', 'adm', 'cs', 'clientPfg']}>
        <DropdownDiv>
          <button
            onMouseDown={() => {
              setModalUdpate(true);
              setSelectedActive(active);
            }}
          >
            Editar
          </button>
          <button
            onMouseDown={() => {
              setShowDeleteModal(!showDeleteModal);
              setSelectedActive(active?.id);
            }}
          >
            Excluir
          </button>
        </DropdownDiv>
      </Can>,
    ]);

  async function handleSubmit(e) {
    try {
      e.preventDefault();
      setLoading(true);

      const isPatrimonialActive = toggleButton === 'patrimonial-active';

      if (!name || !value || (isPatrimonialActive && !classification)) {
        setLoading(false);
        return toast.error(isPatrimonialActive
          ? `Os campos nome, valor e tipo de ativo são obrigatórios!`
          : `Os campos nome e valor são obrigatórios!`);
      }

      let activeToSave = {};
      if (toggleButton !== 'patrimonial-active') {
        if (!liquidity || !categorization)
          return toast.error('Os campos liquidez e categoria são obrigatórios');
        activeToSave = {
          name,
          type: toggleButton,
          classification,//tipo
          categorization,
          sealable: false,
          liquidity,
          value,
          privatePension
        }
      } else {
        activeToSave = {
          name,
          type: toggleButton,
          classification,
          sealable: false,
          liquidity: null,
          categorization: null,
          value,
          privatePension
        }
      }


      const { data } = await api.post(`/users/${id}/active_patrimonies`, activeToSave);

      setName('');
      setClassification('');
      setValue('');
      setPrivatePension(false);

      setDataActive([...dataActive, data]);
      setShowModal(!showModal);
      setLoading(false);
      return toast.success('Ativo criado com sucesso');
    } catch (err) {
      setLoading(false);
      return toast.error('Falha ao criar ativo');
    }
  }

  return (
    <Content column>
      <>
        <TotalContainer className="patrimonial-active">
          <TotalTable
            total={dataActive
              .filter(active => active.type === 'patrimonial-active')
              .reduce((total, currentValue) => total + currentValue.value, 0)}
            title="Ativos Materiais"
            color="investments"
            head={headTable('patrimonial-active')}
            body={dataBody}
            buttonIcons={[
              <Can
                key={Math.random()}
                canRoles={['planner', 'adm', 'cs', 'clientPfg']}
              >
                <Button
                  color="investments"
                  onClick={() => {
                    setShowModal(!showModal);
                    setToggleButton('patrimonial-active');
                  }}
                >
                  Novo
                </Button>
              </Can>,
            ]}
          />
        </TotalContainer>
        <div id="ativos-financeiros"></div>
        <TotalContainer className="financial-active">
          <TotalTable

            total={dataActive
              .filter(active => active.type === 'financial-active')
              .reduce((total, currentValue) => total + currentValue.value, 0)}
            title="Ativos Financeiros"
            color="investments"
            head={headTable('financial-active')}
            body={dataBodyFinancial}
            buttonIcons={[
              <Can
                key={Math.random()}
                canRoles={['planner', 'adm', 'cs', 'clientPfg']}
              >
                <Button
                  color="investments"
                  onClick={() => {
                    setShowModal(!showModal);
                    setToggleButton('financial-active');
                  }}
                >
                  Novo
                </Button>
              </Can>,
            ]}
          />
        </TotalContainer>
        {showModalUpdate && (
          <UpdateModal
            selectedPatrimony={selectedActive}
            setActivePatrimony={setDataActive}
            setModalUdpate={setModalUdpate}
          />
        )}
        {showDeleteModal && (
          <Modal
            color="danger"
            setShowModal={setShowDeleteModal}
            title="Confirmar Exclusão?"
            icon={<AiOutlineDelete />}
          >
            <DivButtons>
              <Button
                color="danger"
                onClick={() => {
                  handleDeleteActive(selectedActive);
                  setShowDeleteModal(!showDeleteModal);
                }}
              >
                {loading ? <FaSpinner className="spin" /> : 'Confirmar'}
              </Button>
            </DivButtons>
          </Modal>
        )}
        {showModal && (
          <Modal
            title={
              toggleButton === 'patrimonial-active'
                ? 'Novo Ativo Não Financeiro'
                : 'Novo Ativo Financeiro'
            }
            setShowModal={setShowModal}
            color="investments"
            icon={<RiAddLine />}
          >
            <Body>
              <form onSubmit={handleSubmit}>
                <Input
                  label="Nome Completo:"
                  value={name}
                  onChange={e => setName(e.target.value)}
                />
                {toggleButton === 'patrimonial-active' && (
                  <Dropdown
                    label="Tipo de ativo:"
                    options={optionsDropdown}
                    onChange={e => setClassification(e.value)}
                  />
                )}
                {toggleButton !== 'patrimonial-active' && (
                  <>
                    <Dropdown
                      label="Categoria:"
                      options={optionsCategories}
                      onChange={e => setCategorization(e.value)}
                    />
                    <Dropdown
                      label="Liquidez:"
                      options={optionsliq}
                      onChange={e => setLiquidity(e.value)}
                    />
                    <Dropdown
                      label="Previdência privada:"
                      options={privatePensionOptions}
                      onChange={e => setPrivatePension(e.value)}
                    />
                  </>
                )}
                <CurrencyInput
                  label="Valor:"
                  prefix="R$ "
                  value={value}
                  onChange={cleanValue => setValue(cleanValue)}
                />

                <div className="buttons">
                  <Button type="submit">
                    {loading ? <FaSpinner className="spin" /> : 'Confirmar'}
                  </Button>
                </div>
              </form>
            </Body>
          </Modal>
        )}
      </>
    </Content>
  );
}

ActivePatrimony.propTypes = {
  dataActive: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      classification: PropTypes.string,
      type: PropTypes.string,
      value: PropTypes.number,
    })
  ).isRequired,
  setDataActive: PropTypes.func.isRequired,
};
