import React from "react";
import { DreamCard } from "components/templates/DreamCard";
import { GenericDreamModal } from "components/molecules/GenericDreamModal";
import { SimpleDeleteModal } from "components/molecules/BudgetModal/SimpleDeleteModal";

export const GenericDream = ({
    updateDreamStatus = () => { },
    dreamProps = {},
    onConfirm = () => { },
    onDelete = () => { },
}) => {

    const [showModal, setShowModal] = React.useState(false);
    const [showDeleteModal, setShowDeleteModal] = React.useState(false);

    const editModal = () => {
        setShowModal(prev => !prev);
    }

    const deleteItem = () => {
        setShowDeleteModal(prev => !prev);
        onDelete(dreamProps?.id);
    }

    const dreamBody = {
        realized: (
            <>
                <DreamCard.RootContainer
                    className="goal-panel realized-generic-dream"
                    backgroundImage={dreamProps?.backgroundImage}
                >
                    <DreamCard.Icon icon="round-verified" className="realized-generic-dream" />
                    <DreamCard.Text
                        text={dreamProps?.name}
                        className="goals-panel generic-dream realized"
                    />
                    <DreamCard.GroupItens className="on-center">
                        <DreamCard.Icon
                            icon="trash"
                            className="delete-generic-dream"
                            onClick={() => setShowDeleteModal(true)}
                        />
                        <DreamCard.Icon
                            icon="pencil"
                            className="financial-independence"
                            onClick={editModal}
                        />
                        <DreamCard.Icon
                            icon="verified"
                            className="realized-dream"
                            onClick={() => updateDreamStatus(dreamProps?.id, !dreamProps?.realized)}
                        />
                    </DreamCard.GroupItens>
                </DreamCard.RootContainer>
            </>
        ),
        unrealized: (
            <DreamCard.RootContainer
                className="goal-panel generic-dream"
                backgroundImage={dreamProps?.backgroundImage}
            >
                <DreamCard.GroupItens className="spaced">
                    <DreamCard.Text
                        text={dreamProps.name}
                        className="goals-panel generic-dream title"
                    />
                    <DreamCard.GroupItens>
                        <DreamCard.Icon
                            icon="trash"
                            className="delete-generic-dream"
                            onClick={() => setShowDeleteModal(true)}
                        />
                        <DreamCard.Icon
                            icon="pencil"
                            className="financial-independence"
                            onClick={editModal}
                        />
                        <DreamCard.Icon
                            icon="verified"
                            className="financial-independence"
                            onClick={() => updateDreamStatus(dreamProps?.id, !dreamProps?.realized)}
                        />
                    </DreamCard.GroupItens>
                </DreamCard.GroupItens>
                <DreamCard.GroupItens className="spaced">
                    <DreamCard.Text
                        text="M.I.M"
                        className="goals-panel generic-dream mim"
                    />
                    <DreamCard.Text
                        text={dreamProps.mimValue}
                        className="goals-panel generic-dream mim"
                    />
                </DreamCard.GroupItens>

                <DreamCard.GroupItens className="spaced">
                    <DreamCard.Text
                        text={dreamProps.initialValueInvested}
                        className="goals-panel generic-dream progress-information"
                    />
                    <DreamCard.Text
                        text={dreamProps.goalValue}
                        className="goals-panel generic-dream progress-information"
                    />
                </DreamCard.GroupItens>
                <DreamCard.ProgressBar
                    className="goals-panel"
                    progress={dreamProps.percentageCompleted}
                />
                <DreamCard.GroupItens className="spaced">
                    <DreamCard.Text
                        text="Total investido"
                        className="goals-panel generic-dream progress-information percentage"
                    />
                    <DreamCard.Text
                        text={dreamProps.totalInvestedValue}
                        className="goals-panel generic-dream progress-information percentage"
                    />
                </DreamCard.GroupItens>
            </DreamCard.RootContainer>
        )
    }

    return (
        <>
            {showModal && (
                <GenericDreamModal
                    showModal={showModal}
                    setShowModal={setShowModal}
                    action="edit"
                    dreamProps={dreamProps}
                    onConfirm={onConfirm}
                />
            )}
            {showDeleteModal && (
                <SimpleDeleteModal
                    showModal={showDeleteModal}
                    setShowModal={setShowDeleteModal}
                    title='Excluir sonho'
                    text='Excluir sonho?'
                    highlightedText='Esta ação é permanente e não poderá ser desfeita'
                    onConfirm={deleteItem}
                    cancelButtonText='Cancelar'
                    confirmButtonText='Excluir sonho'
                    theme='debts'
                />
            )}
            {dreamBody[dreamProps.realized ? 'realized' : 'unrealized']}
        </>
    )
}