import styled from 'styled-components';

export const Button = styled.button`
  &.simple-modal {
    font-size: ${props => props.theme.getResponsiveHeight(18)};
    border-radius: ${props => props.theme.getResponsiveHeight(4)};
    padding: 0 ${props => props.theme.getResponsiveHeight(8)};
    height: ${props => props.theme.getResponsiveHeight(34)};
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    &.receipt {
      &.cancel {
        background-color: ${props => props.theme.blueHeaderWithSlowOpacity};
        color: ${props => props.theme.white};
        border: none;

        &:hover {
          opacity: 0.5;
        }
      }

      &.confirm {
        background: transparent;
        border: 1px solid ${props => props.theme.graySilver};
        color: ${props => props.theme.graySilver};

        &:hover {
          background: ${props => props.theme.graySilver};
          color: ${props => props.theme.white};
        }
      }
    }

    &.debts {
      &.cancel {
        background-color: ${props => props.theme.redMedium};
        color: ${props => props.theme.white};
        border: none;

        &:hover {
          opacity: 0.5;
        }
      }

      &.confirm {
        background: transparent;
        border: 1px solid ${props => props.theme.graySilver};
        color: ${props => props.theme.graySilver};

        &:hover {
          background: ${props => props.theme.graySilver};
          color: ${props => props.theme.white};
        }
      }
    }

    &.edit {
      &.cancel {
        background-color: ${props => props.theme.modalButton};
        color: ${props => props.theme.white};
        border: none;

        &:hover {
          opacity: 0.5;
        }
      }

      &.confirm {
        background: transparent;
        border: 1px solid ${props => props.theme.graySilver};
        color: ${props => props.theme.graySilver};

        &:hover {
          background: ${props => props.theme.graySilver};
          color: ${props => props.theme.white};
        }
      }
    }

    &.spendings {
      &.cancel {
        background-color: ${props => props.theme.blueSky};
        color: ${props => props.theme.white};
        border: none;

        &:hover {
          opacity: 0.5;
        }
      }

      &.confirm {
        background: transparent;
        border: 1px solid ${props => props.theme.graySilver};
        color: ${props => props.theme.graySilver};

        &:hover {
          background: ${props => props.theme.graySilver};
          color: ${props => props.theme.white};
        }
      }
    }

    &:focus {
      outline: 0;
    }

    @media (max-width: 1023px) {
      font-size: 12.3px;
      border-radius: 2.7px;
      padding: 0 5.5px;
      height: 23.2px;
    }
  }

  &.calendar-button {
    background-color: ${props => props.theme.blueRoyal};
    padding: ${props => props.theme.getResponsiveHeight(4)}
      ${props => props.theme.getResponsiveHeight(10)};
    border-radius: ${props => props.theme.getResponsiveHeight(14)};
    font-family: ${props => props.theme.fonts.AperturaRegular};
    font-size: ${props => props.theme.getResponsiveHeight(8)};
    line-height: ${props => props.theme.getResponsiveHeight(8)};
    font-weight: 400;
    border: none;
    color: ${props => props.theme.whiteWithSlowOpacity};
    height: min-content;
    cursor: pointer;

    &:focus {
      outline: 0;
    }

    @media (max-width: 1023px) {
      padding: 2.73px 6.83px;
      border-radius: 9.57px;
      font-size: 5.47px;
      line-height: 5.47px;
    }
  }

  &.generic-dream-modal {
    width: ${props => props.theme.getResponsiveWidth(163)};
    min-width: 100px;
    background-color: ${props => props.theme.blueRoyalWithSlowOpacity};
    padding: ${props => props.theme.getResponsiveWidth(5)};
    border-radius: ${props => props.theme.getResponsiveWidth(4)};
    font-family: ${props => props.theme.fonts.AperturaRegular};
    font-size: ${props => props.theme.getResponsiveWidth(18)};
    font-weight: 400;
    border: none;
    color: ${props => props.theme.white};
    height: min-content;
    cursor: pointer;

    &:focus {
      outline: 0;
    }

    &:hover {
      opacity: 0.5;
    }

    @media (max-width: 1023px) {
      padding: 5.87px 8.27px;
      border-radius: 2.13px;
      font-size: 10.67px;
      line-height: 10.67px;
    }
  }

  &.empty-suitability-questionnaire,
  &.filled-suitability-questionnaire {
    height: ${props => props.theme.getResponsiveWidth(50)};
    border-radius: ${props => props.theme.getResponsiveWidth(8)};
    background-color: ${props => props.theme.blueRoyal};
    color: ${props => props.theme.white};
    align-self: center;
    font-size: ${props => props.theme.getResponsiveWidth(18)};
    line-height: ${props => props.theme.getResponsiveWidth(18)};
    font-weight: 700;
    margin-top: ${props => props.theme.getResponsiveWidth(32)};
    margin-bottom: ${props => props.theme.getResponsiveWidth(45)};
    padding: ${props => props.theme.getResponsiveWidth(16)};
    border: none;
    cursor: pointer;

    &:focus {
      outline: 0;
    }

    &:hover {
      opacity: 0.5;
    }

    &.suitability-form {
      margin-left: ${props => props.theme.getResponsiveWidth(32)};
    }

    @media (max-width: 1023px) {
      height: 26.7px;
      border-radius: 4.3px;
      font-size: 9.6px;
      margin-top: 17.1px;
      margin-bottom: 24px;
      line-height: 9.6px;
      padding: 8.5px;

      &.suitability-form {
        margin-left: 17.1px;
      }
    }
  }
  &.retirement-table {
    width: ${props => props.theme.getResponsiveWidth(280)};
    height: ${props => props.theme.getResponsiveHeight(68)};
    font-size: ${props => props.theme.getResponsiveHeight(20)};
    background-color: ${props => props.theme.grayOpacity};
    color: ${props => props.theme.white};
    border: none;
    border-radius: ${props => props.theme.getResponsiveWidth(6)};

    @media (max-width: 1023px) {
      height: 26.7px;
      border-radius: 4.3px;
      font-size: 9.6px;
      margin-top: 17.1px;
      margin-bottom: 24px;
      line-height: 9.6px;
      padding: 8.5px;
    }
  }
  &.grafic-table {
    width: ${props => props.theme.getResponsiveWidth(60)};
    height: ${props => props.theme.getResponsiveHeight(24)};
    font-size: ${props => props.theme.getResponsiveHeight(14)};
    min-width: 50px;
    background-color: ${props => props.theme.blueRoyal};
    color: ${props => props.theme.white};
    border: none;
    border-radius: ${props => props.theme.getResponsiveWidth(4)};

    &:focus {
      outline: 0;
    }
  }
  &.report-spending {
    width: ${props => props.theme.getResponsiveWidth(40)};
    height: ${props => props.theme.getResponsiveHeight(40)};
    min-width: 40px;
    min-height: 40px;
    border: 1px solid ${props => props.theme.blueSky};
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: ${props => props.theme.getResponsiveWidth(8)};

    &.selected {
      background-color: ${props => props.theme.blueSky};
    }

    &:hover {
      background-color: ${props => props.theme.blueSky};
    }
    &:focus {
      outline: 0;
    }
  }
  &.report-receipt {
    width: ${props => props.theme.getResponsiveWidth(40)};
    height: ${props => props.theme.getResponsiveHeight(40)};
    min-width: 40px;
    min-height: 40px;
    border: 1px solid ${props => props.theme.blueNavy};
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: ${props => props.theme.getResponsiveWidth(8)};

    &.selected {
      background-color: ${props => props.theme.blueNavy};
    }

    &:hover {
      background-color: ${props => props.theme.blueNavy};
    }
    &:focus {
      outline: 0;
    }
  }
  &.report-investment {
    width: ${props => props.theme.getResponsiveWidth(40)};
    height: ${props => props.theme.getResponsiveHeight(40)};
    min-width: 40px;
    min-height: 40px;
    border: 1px solid ${props => props.theme.greenDark};
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: ${props => props.theme.getResponsiveWidth(8)};

    &.selected {
      background-color: ${props => props.theme.greenDark};
    }

    &:hover {
      background-color: ${props => props.theme.greenDark};
    }
    &:focus {
      outline: 0;
    }
  }
  &.report-debts {
    width: ${props => props.theme.getResponsiveWidth(40)};
    height: ${props => props.theme.getResponsiveHeight(40)};
    min-width: 40px;
    min-height: 40px;
    border: 1px solid ${props => props.theme.redMedium};
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: ${props => props.theme.getResponsiveWidth(8)};

    &.selected {
      background-color: ${props => props.theme.redMedium};
    }

    &:hover {
      background-color: ${props => props.theme.redMedium};
    }
    &:focus {
      outline: 0;
    }
  }
`;
