import React from 'react';
import { Switch } from 'react-router-dom';

import { SignIn, ForgotPassword, ResetPassoword } from 'pages/auth';
import {
  PotDetail,
  Acting,
  ActingR1,
  Planning,
  Investments,
  SelfKnowledge,
} from 'pages/dashboard';

import ExternalAccess from 'pages/External_acess';
import {
  Home,
  Finances,
  Goals,
  Management,
  Retirement,
} from '../components/pages/index';

import Route from './Route';

export default function Routes() {
  return (
    <Switch>
      {/* AUTH ROUTES */}

      <Route path="/" exact component={SignIn} />
      <Route path="/forgot_password" component={ForgotPassword} />
      <Route path="/external_acess" component={ExternalAccess} />
      <Route path="/sign_out" component={() => <h2>Sign Out</h2>} isPrivate />

      {/* DASHBOARD */}

      <Route path="/reset_password" component={ResetPassoword} />
      <Route path="/home" exact component={Home} isPrivate dashboard />
      <Route path="/home/:subpage" component={PotDetail} isPrivate dashboard />
      <Route path="/acting" exact component={Acting} isPrivate dashboard />
      <Route
        path="/finances/:subpage"
        component={Finances}
        isPrivate
        dashboard
      />
      <Route path="/goals/:subpage" component={Goals} isPrivate dashboard />
      <Route
        path="/management/:subpage"
        component={Management}
        isPrivate
        dashboard
      />

      <Route
        path="/planning/:subpage"
        component={Planning}
        isPrivate
        dashboard
      />

      <Route
        path="/investments/:subpage"
        component={Investments}
        isPrivate
        dashboard
      />

      <Route
        path="/selfKnowledge/:subpage"
        component={SelfKnowledge}
        isPrivate
        dashboard
      />
      <Route
        path="/retirement/:subpage"
        component={Retirement}
        isPrivate
        dashboard
      />

      <Route path="/acting/r1" component={ActingR1} isPrivate dashboard />

      {/* 404 ROUTES */}

      <Route component={() => <h1>Page not found</h1>} isPrivate />
      <Route component={() => <h1>Page not found</h1>} />
    </Switch>
  );
}
