import styled from 'styled-components';

export const Overlay = styled.div`
  opacity: 0;
  transition: opacity 0.2s ease-in-out;

  &.visible {
    opacity: 0.8;
  }

  &.color-debts {
    background-color: ${({ theme }) => theme.graySilver};
  }
  &.color-receipt {
    background-color: ${({ theme }) => theme.graySilver};
  }
  &.color-spending {
    background-color: ${({ theme }) => theme.graySilver};
  }

  &.budget {
    position: absolute;
    width: ${props => props.theme.getResponsiveHeight(90)};
    height: ${props => props.theme.getResponsiveHeight(25)};
    left: 90%;
    color: ${({ theme }) => theme.white};
    padding: ${props => props.theme.getResponsiveHeight(4)};
    border-radius: ${props => props.theme.getResponsiveHeight(4)};
    font-family: ${({ theme }) => theme.fonts.AperturaRegular};

    :after {
      display: block;
    }
  }
  &.report {
    position: absolute;
    width: ${props => props.theme.getResponsiveHeight(195)};
    height: ${props => props.theme.getResponsiveHeight(28)};
    color: ${({ theme }) => theme.white};
    padding: ${props => props.theme.getResponsiveHeight(4)};
    border-radius: ${props => props.theme.getResponsiveHeight(4)};
    font-size: ${props => props.theme.getResponsiveHeight(12)};
    font-family: ${({ theme }) => theme.fonts.AperturaObliq};
    background-color: ${({ theme }) => theme.backgroundOverlay};

    &.visible {
      opacity: 0.8;
    }
  }
  &.title {
    position: absolute;
    width: ${props => props.theme.getResponsiveWidth(170)};
    height: ${props => props.theme.getResponsiveHeight(28)};
    color: ${({ theme }) => theme.white};
    padding: ${props => props.theme.getResponsiveHeight(4)};
    border-radius: ${props => props.theme.getResponsiveHeight(4)};
    font-size: ${props => props.theme.getResponsiveHeight(12)};
    font-family: ${({ theme }) => theme.fonts.AperturaObliq};
    background-color: ${({ theme }) => theme.backgroundOverlay};

    &.visible {
      opacity: 0.8;
    }
  }

  &.home {
    position: absolute;
    bottom: 80%;
    left: 150%;
    background-color: ${({ theme }) => theme.graySilver};
    color: ${({ theme }) => theme.white};
    padding: 14px;
    border-radius: 10px;
    pointer-events: none;
    white-space: wrap;
    height: 9.5em;
    font-family: ${({ theme }) => theme.fonts.AperturaObliq};

    &.visible {
      opacity: 0.8;
    }

    :after {
      display: block;
    }

    @media (min-width: 2161px) {
      font-size: 1rem;
      width: 20em;
      height: 9.5em;
    }

    @media (min-width: 1921px) and (max-width: 2160px) {
      font-size: 0.9rem;
      width: 20em;
      height: 9.5em;
    }

    @media (min-width: 1651px) and (max-width: 1920px) {
      font-size: 0.8rem;
      width: 20em;
      height: 9.5em;
    }

    @media (max-width: 1650px) {
      font-size: 0.7rem;
      width: 20em;
      height: 9.5em;
    }

    @media (max-width: 1280px) {
      font-size: 0.6rem;
      width: 21em;
      height: 10em;
    }
  }
`;
