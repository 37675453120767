import styled from 'styled-components';

export const Label = styled.label`
  &.simple-modal {
    display: flex;
    flex-direction: column;
    text-align: left;
    padding-left: 0.5%;
    margin: 0;

    &.min-size {
      width: min-content;
      height: min-content;
    }

    &.after {
      margin-top: ${props => props.theme.getResponsiveHeight(38)};
    }

    &.at-end {
      align-self: end;
    }

    &.only-it {
      width: 100%;
    }

    &.min-width {
      width: min-content;
      white-space: nowrap;
    }
  }

  &.generic-dream-modal {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin: 0;
    padding: 0;
    height: min-content;

    &.term {
      margin-left: ${props => props.theme.getResponsiveWidth(32)};

      @media (max-width: 1023px) {
        margin-left: 17.1px;
        margin-top: 17.1px;
      }
    }

    &.first {
      margin-top: ${props => props.theme.getResponsiveWidth(38)};

      @media (max-width: 1023px) {
        margin-top: 20.27px;
      }
    }

    &.after {
      margin-top: ${props => props.theme.getResponsiveWidth(32)};

      @media (max-width: 1023px) {
        margin-top: 27.817px;
      }
    }

    &.half {
      width: ${props => props.theme.getResponsiveWidth(163)};

      &.last {
        margin-left: ${props => props.theme.getResponsiveWidth(32)};
      }

      @media (max-width: 1023px) {
        width: 86.93px;

        &.last {
          margin-left: 17.07px;
        }
      }
    }
  }
`;
