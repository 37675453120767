import { store } from 'store';

export function checkSuitabilityUserAuth() {
  const idAuth = store.getState().auth.user.id;
  const idUser = store.getState().user.profile.id;

  const type = store.getState().auth.user?.type;

  return idAuth === idUser && type === 'client';
}
