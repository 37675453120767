import styled from 'styled-components';

export const SwitchContainer = styled.label`
  position: relative;
  display: inline-block;
  width: ${({ theme }) => theme.getResponsiveWidth(40)};
  height: ${({ theme }) => theme.getResponsiveWidth(17)};
  margin-top: ${({ theme }) => theme.getResponsiveWidth(5)};

  &.generic-dream {
    width: ${({ theme }) => theme.getResponsiveWidth(40)};
    height: ${({ theme }) => theme.getResponsiveWidth(17)};
    margin-top: ${({ theme }) => theme.getResponsiveWidth(17)};
  }

  &.generic-dream-modal {
    width: ${({ theme }) => theme.getResponsiveWidth(40)};
    height: ${({ theme }) => theme.getResponsiveWidth(17)};
    margin-top: ${({ theme }) => theme.getResponsiveWidth(17)};

    @media (max-width: 1023px) {
      width: 21.33px;
      height: 9.07px;
      margin-top: 9.07px;
    }
  }
`;

export const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.grayPale};
  transition: 0.4s;
  border-radius: ${({ theme }) => theme.getResponsiveWidth(17)};

  &:before {
    position: absolute;
    content: '';
    height: ${({ theme }) => theme.getResponsiveWidth(13)};
    width: ${({ theme }) => theme.getResponsiveWidth(13)};
    left: ${({ theme }) => theme.getResponsiveWidth(2)};
    bottom: ${({ theme }) => theme.getResponsiveWidth(2)};
    background-color: ${({ theme }) => theme.graySilver};
    transition: 0.4s;
    border-radius: 50%;
  }

  @media (max-width: 1023px) {
    &:before {
      height: 6.93px;
      width: 6.93px;
      left: 1.07px;
      bottom: 1.07px;
    }
  }
`;

export const Input = styled.input`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + ${Slider} {
    background-color: ${({ theme }) => theme.blueSkyWithSlowOpacity};
    opacity: 0.45;

    &:before {
      background-color: ${({ theme }) => theme.blueSky};
    }
  }

  &:checked + ${Slider}:before {
    transform: translateX(180%);
  }
`;
