import React, { useState, useEffect } from 'react';
import { toCurrencyNoCents as formatReal } from 'deprecated/utils/toCurrency';
import {
  Container,
  ContainerColumn,
  SubTitle,
  PurpleBar,
  ContentWrapper,
  LogoAzul,
  Footer,
  GrayBar,
  Title
} from './styles';

export default function OAPIndicators({ data }) {

  const { headerTitle, footerTitle, indicadoresOAP } = data;

  const [valores, setValores] = useState({
    indicePoupanca: 0,
    indiceComprometimentoMaximo: 0,
    porcentagem: 0,
    porcentagemPoupanca: 0,
    porcentagemIndiceComprometimento: 0
  });

  useEffect(() => {
    if (indicadoresOAP.length > 0) {
      let valorIndiceCompras = indicadoresOAP[2].valor;
      let valorIndiceMaximoParcela = indicadoresOAP[2].total;
      let porcentagem = (valorIndiceCompras / valorIndiceMaximoParcela) * 100
      if (isNaN(porcentagem)) porcentagem = 0;

      // if(porcentagem > 100) porcentagem = 100;

      setValores({
        indiceCompras: valorIndiceCompras,
        indiceMaximo: valorIndiceMaximoParcela,
        porcentagem: porcentagem,
        porcentagemPoupanca: indicadoresOAP[0].valor,
        porcentagemIndiceComprometimento: indicadoresOAP[1].valor
      })

    }

  }, [indicadoresOAP])

  return (
    <ContentWrapper id="printable" minHeight={1680}>
      <Container style={{ height: '100%' }} padding="0 78px 0px 78px">
        <div className="d-40w">
          <PurpleBar />
          <PurpleBar className="full-height">
            <h1 style={{ marginBottom: '110px' }}>Indicadores</h1>
            <div className="half-content normal">
              <div style={{ marginBottom: '100px' }}>
                <h2 style={{ marginBottom: '19px' }}>Índice de Poupança:</h2>
                <p>Qual o percentual da minha<br />receita eu consigo poupar</p>
              </div>
              <div style={{ marginBottom: '95px' }}>
                <h2 style={{ marginBottom: '19px' }}>Índice de<br />Comprometimento: </h2>
                <p>Qual o percentual da minha<br />
                  receita já está comprometido<br />
                  com pagamentos mensalmente<br />
                  recorrentes</p>
              </div>
              <div>
                <h2 style={{ marginBottom: '19px' }}>Índice de Compras<br />Parceladas:</h2>
                <p>Esse é o limite mensal saudável<br />
                  de compras parcelas que eu<br />
                  posso ter por fatura</p>
              </div>
            </div>

          </PurpleBar>
        </div>
        <div className="d-60w">
          <ContainerColumn style={{ padding: "120px 19px 0 78px" }}>
            <Title dangerouslySetInnerHTML={{ __html: headerTitle }}></Title>
            <p style={{ marginTop: "58px" }} className="p-smaller">Essas são as métricas para saber se cheguei em um<br />
              Orçamento de Alta Performance.</p>

            <SubTitle className="bigger">Valor Referencial</SubTitle>
            <div className="half-content">
              <div>
                <SubTitle>{Math.floor(valores.porcentagemPoupanca)}%</SubTitle>
                <GrayBar progress={Math.floor(valores.porcentagemPoupanca)}>
                  <div className="step-10">10%</div>
                  <div className="step-50">50%</div>
                </GrayBar>
              </div>
              <div>
                <SubTitle>{Math.floor(valores.porcentagemIndiceComprometimento)}%</SubTitle>
                <GrayBar progress={Math.floor(valores.porcentagemIndiceComprometimento)}>
                  <div className="step-50">50%</div>
                </GrayBar>
              </div>
              <div style={{ paddingTop: '38px' }}>
                <SubTitle>{valores !== null ? formatReal(valores.indiceCompras) : formatReal(0)}</SubTitle>
                <GrayBar className="indiceCompras" progress={valores !== null ? Math.floor(valores.porcentagem) : 0}>
                  <div className="valor">{valores !== null ? formatReal(valores.indiceMaximo) : formatReal(0)}</div>
                </GrayBar>
              </div>
            </div>
          </ContainerColumn>
        </div>
      </Container>
      <Footer>
        <Container padding="0 78px">
          <p>{footerTitle}</p>
          <LogoAzul />
        </Container>
      </Footer>
    </ContentWrapper>
  );
}
